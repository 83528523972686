export const translationKeys = {
    all: 'all',
    account: 'account',
    account_already_exist_email: 'account_already_exist_email',
    account_blocked: 'account_blocked',
    account_blocked_alert: 'account_blocked_alert',
    active: 'active',
    ago: 'ago',
    add_column: 'add_column',
    add_favourite: 'add_favourite',
    add_filter: 'add_filter',
    add_image: 'add_image',
    add_question: 'add_question',
    add_question_alert: 'add_question_alert',
    add_question_alert_quiz: 'add_question_alert_quiz',
    allow_more_quiz_attempt_alert: 'allow_more_quiz_attempt_alert',
    allow_more_response_alert: 'allow_more_response_alert',
    allow_multiple_attempts: 'allow_multiple_attempts',
    allow_multiple_responses: 'allow_multiple_responses',
    add_option: 'add_option',
    add_picture: 'add_picture',
    add_other_option: 'add_other_option',
    add_row: 'add_row',
    user_info_for_file_upload: 'user_info_for_file_upload',
    user_info_for_file_upload_desc1: 'user_info_for_file_upload_desc1',
    user_info_for_file_upload_desc2: 'user_info_for_file_upload_desc2',
    allow_responders_view_summary_alert: 'allow_responders_view_summary_alert',
    allow_result_view_quiz_alert: 'allow_result_view_quiz_alert',
    alphabet: 'alphabet',
    already_have_an_account: 'already_have_an_account',
    and: 'and',
    answer_data_not_found: 'answer_data_not_found',
    answer_link: 'answer_link',
    embed_code: 'embed_code',
    copy_code: 'copy_code',
    code_copied: 'code_copied',
    answer_not_available: 'answer_not_available',
    answer_response_alert: 'answer_response_alert',
    answer_s: 'answer_s',
    answer_with_colan: 'answer_with_colan',
    answered: 'answered',
    answers: 'answers',
    app_name: 'app_name',
    app_not_found: 'app_not_found',
    apply: 'apply',
    back: 'back',
    select_all_2: 'select_all_2',
    bar_chart: "bar_chart",
    browse: 'browse',
    builder: 'builder',
    cancel: 'cancel',
    center_label_optional: 'center_label_optional',
    change: 'change',
    change_image: 'change_image',
    change_question_type_alert: 'change_question_type_alert',
    change_theme: 'change_theme',
    check_back_later_for_new_notifications: 'check_back_later_for_new_notifications',
    check_connection: 'check_connection',
    check_box_grid: 'check_box_grid',
    check_your_inbox_to_activate_your_account: 'check_your_inbox_to_activate_your_account',
    choice_questions_only: 'choice_questions_only',
    choice_type_validation_note: 'choice_type_validation_note',
    choose: 'choose',
    add_account: 'add_account',
    choose_account: 'choose_account',
    choose_your_language: 'choose_your_language',
    clear_all: 'clear_all',
    close: 'close',
    correct: "correct",
    collaborate: 'collaborate',
    templateCategory: 'template_category',
    collaboration: 'collaboration',
    collaborator: 'collaborator',
    collaborators: 'collaborators',
    collapse: 'collapse',
    column: 'column',
    columns: 'columns',
    columns_all: 'columns_all',
    completing_this_quiz: 'completing_this_quiz',
    connected_successfully: 'connected_successfully',
    contains: 'contains',
    copy_link: 'copy_link',
    correct_answer_required: 'correct_answer_required',
    correction_status: 'correction_status',
    create_form: 'create_form',
    create_blank_form: 'create_blank_form',
    create_blank_quiz: 'create_blank_quiz',
    create_form_quiz_on_the_go: 'create_form_quiz_on_the_go',
    create_quiz: 'create_quiz',
    created: 'created',
    custom_date: 'custom_date',
    dark_mode: 'dark_mode',
    date_format: 'date_format',
    date_text: 'date_text',
    default_text: 'default_text',
    delete: 'delete',
    delete_all: 'delete_all',
    delete_answer_alert: 'delete_answer_alert',
    delete_answers_alert: 'delete_answers_alert',
    delete_form: 'delete_form',
    delete_form_alert: 'delete_form_alert',
    delete_forms_alert: 'delete_forms_alert',
    delete_image: 'delete_image',
    delete_image_alert: 'delete_image_alert',
    delete_theme_image_desc: 'delete_theme_image_desc',
    delete_option_alert: 'delete_option_alert',
    delete_question: 'delete_question',
    delete_question_alert: 'delete_question_alert',
    delete_quiz_alert: 'delete_quiz_alert',
    delete_quizzes_alert: 'delete_quizzes_alert',
    delete_response: 'delete_response',
    delete_response_alert: 'delete_response_alert',
    delete_response_collected_question_alert: 'delete_response_collected_question_alert',
    delete_responses_alert: 'delete_responses_alert',
    deleted: 'deleted',
    description: 'description',
    disconnect: 'disconnect',
    do_you_want_to_set_date_to_publish_results: 'do_you_want_to_set_date_to_publish_results',
    do_you_want_to_show_all_correct_answers_to_users: 'do_you_want_to_show_all_correct_answers_to_users',
    done: 'done',
    dont_have_account: 'dont_have_account',
    download_as: 'download_as',
    do_search: 'do_search',
    draft: 'draft',
    drafts: 'drafts',
    drag_drop: 'drag_drop',
    dropdown_answer_type_validation_note: 'dropdown_answer_type_validation_note',
    duplicate_form: 'duplicate_form',
    duplicate_quiz: 'duplicate_quiz',
    duration: 'duration',
    easy_login_via_app: 'easy_login_via_app',
    edit: 'edit',
    edit_form: 'edit_form',
    email: 'email',
    enter_all_required_information: 'enter_all_required_information',
    enter_answer: 'enter_answer',
    enter_valid_mark: 'enter_valid_mark',
    enter_your_details: 'enter_your_details',
    equal_to: 'equal_to',
    evaluated: 'evaluated',
    exit: 'exit',
    exit_screen: 'exit_screen',
    export_success: 'export_success',
    favourites: 'favourites',
    feature_spotlight: 'feature_spotlight',
    feedback: 'feedback',
    file: 'file',
    files: 'files',
    faq_videos: 'faq_videos',
    file_upload: 'file_upload',
    signature: 'signature',
    fileupload_answer_type_validation_note: 'fileupload_answer_type_validation_note',
    filter: 'filter',
    filter_by_date: 'filter_by_date',
    filter_by_question: 'filter_by_question',
    filter_text: 'filter_text',
    finish_incomplete_question: 'finish_incomplete_question',
    forget_your_password: 'forget_your_password',
    form: 'form',
    form_builder: 'form_builder',
    form_builder_promo: 'form_builder_promo',
    form_created_successfully: 'form_created_successfully',
    form_edit_alert: 'form_edit_alert',
    quiz_edit_alert: 'quiz_edit_alert',
    form_link: 'form_link',
    form_name_alert: 'form_name_alert',
    form_status: 'form_status',
    forms: 'forms',
    free_App_to_create_forms: 'free_App_to_create_forms',
    from_date: 'from_date',
    from_date_required: 'from_date_required',
    full_screen: 'full_screen',
    fup_desc: 'fup_desc',
    general: 'general',
    get_notification_instantly: 'get_notification_instantly',
    general_form_type_description: 'general_form_type_description',
    grouped_bar_chart: 'grouped_bar_chart',
    quiz_type_description: 'quiz_type_description',
    sheet_disconnected: 'sheet_disconnected',
    sheet_not_connected: 'sheet_not_connected',
    sheet_not_connected_desc: 'sheet_not_connected_desc',
    here: 'here',
    hide: 'hide',
    hide_answer: 'hide_answer',
    hour: 'hour',
    hours: 'hours',
    image: 'image',
    import_questions: 'import_questions',
    search_by_question_title_from_forms: 'search_by_question_title_from_forms',
    search_by_question_title_from_quizzes: 'search_by_question_title_from_quizzes',
    inactive: 'inactive',
    individual: 'individual',
    invalidated_form_cannot_be_selected: "invalidated_form_cannot_be_selected",
    invalidated_quiz_cannot_be_selected: "invalidated_quiz_cannot_be_selected",
    install_surveyheart_app: 'install_surveyheart_app',
    invalid_date: 'invalid_date',
    invalid_email_address: 'invalid_email_address',
    invalid_file_type: 'invalid_file_type',
    invalid_url: 'invalid_url',
    invalid_url_description: 'invalid_url_description',
    last_24_hours: 'last_24_hours',
    last_30_days: 'last_30_days',
    last_7_days: 'last_7_days',
    last_year: 'last_year',
    leave_collaboration: 'leave_collaboration',
    left_label_optional: 'left_label_optional',
    life_time: 'life_time',
    limit_reached: 'limit_reached',
    limit_reached_description: 'limit_reached_description',
    link_copied: 'link_copied',
    live_preview: 'live_preview',
    long_answer: 'long_answer',
    long_answer_type_validation_note: 'long_answer_type_validation_note',
    manage: 'manage',
    manage_storage: 'manage_storage',
    shuffle_options: 'shuffle_options',
    shuffle_options_enabled: 'shuffle_options_enabled',
    shuffle_options_disabled: 'shuffle_options_disabled',
    add_options_to_enable_this_feature: 'add_options_to_enable_this_feature',
    select_all: 'select_all',
    attach: 'attach',
    shuffle: 'shuffle',
    mark: 'mark',
    marks: 'marks',
    min: 'min',
    max: 'max',
    scale_range_error: "scale_range_error",
    scale_range_error2: "scale_range_error2",
    max_min_error: "max_min_error",
    step_error: "step_error",
    step_value: 'step_value',
    marks_is_required: 'marks_is_required',
    maximum_allowed_file_size: 'maximum_allowed_file_size',
    menu: 'menu',
    minute: 'minute',
    minutes: 'minutes',
    more: "more",
    modified: 'modified',
    multiple_choice: 'multiple_choice',
    ranking_choice: 'ranking_choice',
    ranking: 'ranking',
    picture_choice: 'picture_choice',
    agreement: 'agreement',
    multiple_choice_grid: 'multiple_choice_grid',
    multiple_responses_summary: 'multiple_responses_summary',
    my_themes: 'my_themes',
    name_s: 'name_s',
    new_fup_feature_title: 'new_fup_feature_title',
    next: 'next',
    no: 'no',
    now: 'now',
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: 'note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side',
    no_account_found_email_address: 'no_account_found_email_address',
    no_answer: 'no_answer',
    no_response: 'no_response',
    no_connection: 'no_connection',
    no_form_available: 'no_form_available',
    no_marks: 'no_marks',
    no_quiz_available: 'no_quiz_available',
    no_results_found: 'no_results_found',
    not_equal_to: 'not_equal_to',
    not_evaluated: 'not_evaluated',
    not_specified: 'not_specified',
    notification: 'notification',
    notifications: 'notifications',
    number: 'number',
    ok: 'ok',
    on: 'on',
    off: 'off',
    on_signing_up_agree_to_surveyhearts: 'on_signing_up_agree_to_surveyhearts',
    open: 'open',
    open_individual_responses: 'open_individual_responses',
    options: 'options',
    option: 'option',
    options_limit_reached: 'options_limit_reached',
    options_limit_reached_description: 'options_limit_reached_description',
    options_are_required: 'options_are_required',
    options_required: 'options_required',
    or: 'or',
    others: 'others',
    overview: 'overview',
    owner: 'owner',
    pending: 'pending',
    pending_evaluation: 'pending_evaluation',
    pending_evaluation_alert_message: 'pending_evaluation_alert_message',
    percentage: 'percentage',
    percentage_ascending: 'percentage_ascending',
    percentage_descending: 'percentage_descending',
    pie_chart: 'pie_chart',
    powered_by_surveyheart: 'powered_by_surveyheart',
    preview: 'preview',
    preview_not_available: 'preview_not_available',
    print: 'print',
    privacy_policy: 'privacy_policy',
    provide_explanation: 'provide_explanation',
    publish: 'publish',
    publish_description: 'publish_description',
    publish_description_note: 'publish_description_note',
    publish_success_alert_message: 'publish_success_alert_message',
    published: 'published',
    published_successfully: 'published_successfully',
    publishing: 'publishing',
    question: 'question',
    question_error: 'question_error',
    question_is_required: 'question_is_required',
    question_limit_reached: 'question_limit_reached',
    question_number_shuffle: 'question_number_shuffle',
    question_title: 'question_title',
    question_type: 'question_type',
    questions: 'questions',
    quick_settings: 'quick_settings',
    quiz: 'quiz',
    quiz_control: 'quiz_control',
    quiz_has_answer_alert_message: 'quiz_has_answer_alert_message',
    quiz_has_answers: 'quiz_has_answers',
    quiz_link: 'quiz_link',
    quiz_name_alert: 'quiz_name_alert',
    quiz_published: 'quiz_published',
    quiz_published_alert_before_edit_message: 'quiz_published_alert_before_edit_message',
    quiz_result: 'quiz_result',
    quiz_status: 'quiz_status',
    quizzes: 'quizzes',
    quizzory: 'quizzory',  // no need to translate
    reached_limit_for_number_of_questions: 'reached_limit_for_number_of_questions',
    remove_favourite: 'remove_favourite',
    required: 'required',
    requires_answer: 'requires_answer',
    response: 'response',
    response_collection_exit_alert: 'response_collection_exit_alert',  // recheck
    response_limit_reached: 'response_limit_reached', // recheck
    response_limit_reached_alert: 'response_limit_reached_alert', // recheck
    responses: 'responses',
    result_feature_promo: 'result_feature_promo',
    results: 'results',
    results_publish: 'results_publish',
    results_publish_date: 'results_publish_date',
    results_published_soon: 'results_published_soon',
    right_label_optional: 'right_label_optional',
    review_button: 'review_button',
    row: 'row',
    rows: 'rows',
    previous: 'previous',
    save: 'save',
    save_link_for_future_reference: 'save_link_for_future_reference',
    save_to_draft_alert: 'save_to_draft_alert',
    save_to_draft_quiz_alert: 'save_to_draft_quiz_alert',
    saved: 'saved',
    scan_qr_code: 'scan_qr_code',
    score: 'score',
    score_is_not_final: 'score_is_not_final',
    search: 'search',
    seconds: 'seconds',
    section: 'section',
    section_description: 'section_description',
    section_title: 'section_title',
    select_image: 'select_image',
    select_one_response: 'select_one_response',
    selected: 'selected',
    select_questions: 'select_questions',
    serial_number: 'serial_number',
    server_error_alert: 'server_error_alert',
    set_duration: 'set_duration',
    set_duration_quiz_alert: 'set_duration_quiz_alert',
    setting_updated: 'setting_updated',
    settings: 'settings',
    share: 'share',
    share_collaboration_form: 'share_collaboration_form',
    share_collaboration_quiz: 'share_collaboration_quiz',
    share_QR_code: 'share_QR_code',
    share_via: 'share_via',
    shared: 'shared',
    short_answer: 'short_answer',
    short_answer_type_validation_note: 'short_answer_type_validation_note',
    show_answer: 'show_answer',
    show_correct_answer: 'show_correct_answer',
    show_logo: 'show_logo',
    show_logo_alert: 'show_logo_alert',
    show_more: 'show_more',
    show_scale: 'show_scale',
    show_options: 'show_options',
    show_question_number: 'show_question_number',
    show_question_number_in_form: 'show_question_number_in_form',
    show_question_number_in_quiz: 'show_question_number_in_quiz',
    show_results: 'show_results',
    show_rows_and_columns: 'show_rows_and_columns',
    show_summary: 'show_summary',
    show_unevaluated: 'show_unevaluated',
    show_welcome_page_alert: 'show_welcome_page_alert',
    shuffle_questions: 'shuffle_questions',
    shuffle_questions_alert: 'shuffle_questions_alert',
    shuffle_quiz_questions_alert: 'shuffle_quiz_questions_alert',
    sign_in: 'sign_in',
    sign_in_google_to_continue: 'sign_in_google_to_continue',
    sign_out: 'sign_out',
    sign_up: 'sign_up',
    signed_in_as: 'signed_in_as',
    signed_in_previously_as_a_google_user: 'signed_in_previously_as_a_google_user',
    signing_out: 'signing_out',
    skipped: 'skipped',
    smile: 'smile',
    something_went_wrong: 'something_went_wrong',
    stacked_bar_chart: 'stacked_bar_chart',
    start: 'start',
    start_button: 'start_button',
    storage: 'storage',
    submit: 'submit',
    unable_to_submit: 'unable_to_submit',
    unable_to_submit_alert_line1: 'unable_to_submit_alert_line1',
    unable_to_submit_alert_line2: 'unable_to_submit_alert_line2',
    action_blocked: 'action_blocked',
    action_blocked_alert: 'action_blocked_alert',
    quiz_edit_submit_alert: 'quiz_edit_submit_alert',
    continue: 'continue',
    submitted: 'submitted',
    submitting: 'submitting',
    submit_time: 'submit_time',
    submitted_time: 'submitted_time',
    summary: 'summary',
    tabular: 'tabular',
    tabular_view: 'tabular_view',
    tabular_chart: 'tabular_chart',
    tap_here_to_choose_theme: 'tap_here_to_choose_theme',
    click_or_change_current_theme: "click_or_change_current_theme",
    tap_to_set_theme: 'tap_to_set_theme',
    templates: 'templates',
    terms_and_conditions: 'terms_and_conditions',
    terms_and_conditions_required: 'terms_and_conditions_required',
    terms_of_use: 'terms_of_use',
    text: 'text',
    themes: 'themes',
    time_text: 'time_text',
    title: 'title',
    title_dashboard: 'title_dashboard',
    title_notifications: 'title_notifications',
    title_required: 'title_required',
    today: 'today',
    to_date: 'to_date',
    to_date_required: 'to_date_required',
    unavailable_features_description: 'unavailable_features_description',
    unavailable_quiz_features_description: 'unavailable_quiz_features_description',
    unavailable: 'unavailable',
    unpublished: 'unpublished',
    unapproved_form: 'unapproved_form',
    unapproved_form_alert: 'unapproved_form_alert',
    unapproved_quiz: 'unapproved_quiz',
    unapproved_quiz_alert: 'unapproved_quiz_alert',
    upload: 'upload',
    upload_image: 'upload_image',
    uploading: 'uploading',
    use_templates: 'use_templates',
    user_info: 'user_info',
    user_information_description: 'user_information_description',
    using_email: 'using_email',
    status_validated: 'status_validated',
    validated_automatically: 'validated_automatically',
    video: 'video',
    view: 'view',
    view_all: 'view_all',
    view_answers: 'view_answers',
    view_form: 'view_form',
    view_quiz: 'view_quiz',
    view_responses: 'view_responses',
    warning: 'warning',
    we_cant_find_the_page_that_you_are_looking_for: 'we_cant_find_the_page_that_you_are_looking_for',
    webapp_promo_text: 'webapp_promo_text',
    website: 'website',
    welcome_page: 'welcome_page',
    dislike: 'dislike',
    like: 'like',
    best: 'best',
    worst: 'worst',
    wrong: 'wrong',
    yes: 'yes',
    you_are_all_caught_up: 'you_are_all_caught_up',
    your_account: 'your_account',
    // new strings
    enter_member_id: "enter_member_id",
    viewers_can_read_form_responses: "viewers_can_read_form_responses",
    editors_edit_form_view_responses: "editors_edit_form_view_responses",
    admin_edit_form_add_collaborator: "admin_edit_form_add_collaborator",
    invalid_member_id: "invalid_member_id",
    already_member_added: "already_member_added",
    add_collaborator: "add_collaborator",
    add_category: "add_category",
    scale_down: "scale_down",
    scale_up: "scale_up",
    admin: "admin",
    editor: "editor",
    viewer: "viewer",
    searched_form_not_found: 'searched_form_not_found',
    searched_quiz_not_found: 'searched_quiz_not_found',
    no_shared_data_available: 'no_shared_data_available',
    forms_server_is_not_available: 'forms_server_is_not_available',
    quiz_server_is_not_available: 'quiz_server_is_not_available',
    leave: 'leave',
    password: 'password',
    new_password: 'new_password',
    retype_password: 'retype_password',
    create_password: 'create_password',
    view_results: 'view_results',
    control: 'control',
    q_control: 'q_control',
    q_result: 'q_result',
    no_matches_found: 'no_matches_found',
    dropdown: 'dropdown',
    checkboxes: 'checkboxes',
    checkboxes_grid: 'checkboxes_grid',
    star_rating: 'star_rating',
    linear_scale: 'linear_scale',
    slider: 'slider',
    info_1: 'info_1',
    info_2: 'info_2',
    info_3: 'info_3',
    new: 'new',
    download: 'download',
    download_all: 'download_all',
    sorting: 'sorting',
    sort_by: 'sort_by',
    refresh: 'refresh',
    refresh_preview_description: 'refresh_preview_description',
    add: 'add',
    enter_users_email_address: 'enter_users_email_address',
    do_you_want_to_delete_these: 'do_you_want_to_delete_these',
    do_you_want_to_delete_this_form: 'do_you_want_to_delete_this_form',
    do_you_want_to_delete_this_quiz: 'do_you_want_to_delete_this_quiz',
    all_responses_will_also_be_deleted: 'all_responses_will_also_be_deleted',
    this_action_cannot_be_undone: 'this_action_cannot_be_undone',
    all_answer_sheets_will_also_be_unavailable: 'all_answer_sheets_will_also_be_unavailable',
    roles_rights: 'roles_rights',
    learn_more: 'learn_more',
    viewers_can_read_quiz_and_view_answers: 'viewers_can_read_quiz_and_view_answers',
    editors_can_edit_quiz_view_answers: 'editors_can_edit_quiz_view_answers',
    admin_can_edit_quiz_add_collaborator: 'admin_can_edit_quiz_add_collaborator',
    change_roles: 'change_roles',
    remove: 'remove',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting',
    select_language: 'select_language',
    are_you_sure_you_want_delete_this_collaborator: 'are_you_sure_you_want_delete_this_collaborator',
    are_you_sure_you_want_to_delete_selected_files: 'are_you_sure_you_want_to_delete_selected_files',
    please_download_before_deleting: 'please_download_before_deleting',
    role_has_been_changed_successfully: 'role_has_been_changed_successfully',
    do_you_want_to_leave_collaboration: 'do_you_want_to_leave_collaboration',
    form_builder_access_denied: 'form_builder_access_denied',
    quiz_builder_access_denied: 'quiz_builder_access_denied',
    you_only_have_viewer_rights_for_quiz: 'you_only_have_viewer_rights_for_quiz',
    you_only_have_viewer_rights_for_form: 'you_only_have_viewer_rights_for_form',
    kindly_contact_the_form_owner_for_more_info: 'kindly_contact_the_form_owner_for_more_info',
    kindly_contact_the_quiz_owner_for_more_info: 'kindly_contact_the_quiz_owner_for_more_info',
    incomplete_questions: 'incomplete_questions',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'you_have_unsaved_changes_you_sure_you_wish_leave_this_page',
    languages: 'languages',
    copy: 'copy',
    duplicate: 'duplicate',
    not_good: 'not_good',
    not_allowed: 'not_allowed',
    not_allowed_description: 'not_allowed_description',
    neutral: 'neutral',
    good: 'good',
    very_good: 'very_good',
    edit_rights_removed: 'edit_rights_removed',
    your_rights_to_edit_this_form_have_been_removed: "your_rights_to_edit_this_form_have_been_removed",
    your_rights_to_edit_this_quiz_have_been_removed: "your_rights_to_edit_this_quiz_have_been_removed",
    please_contact_form_owner_for_more_info: "please_contact_form_owner_for_more_info",
    please_contact_quiz_owner_for_more_info: "please_contact_quiz_owner_for_more_info",
    last_edited_on: 'last_edited_on',
    last_edited: 'last_edited',
    by: "by",
    you: 'you',
    estimated_time: "estimated_time",
    do_you_want_to_show_estimated_time_taking_quiz: "do_you_want_to_show_estimated_time_taking_quiz",
    do_you_want_to_show_estimated_time_taking_form: "do_you_want_to_show_estimated_time_taking_form",
    show_question_count: "show_question_count",
    do_you_want_to_show_total_number_questions_quiz: "do_you_want_to_show_total_number_questions_quiz",
    do_you_want_to_show_total_number_questions_form: "do_you_want_to_show_total_number_questions_form",
    show_question_marks: "show_question_marks",
    show_question_marks_quiz: "show_question_marks_quiz",
    you_dont_have_add_collaborator_permission: "you_dont_have_add_collaborator_permission",
    modified_date: "modified_date",
    created_date: "created_date",
    question_cant_be_added: "question_cant_be_added",
    another_file_upload_question_cant_added: "another_file_upload_question_cant_added",
    you_can_have_only: "you_can_have_only",
    file_upload_questions_in_form: "file_upload_questions_in_form",
    file_upload_questions_in_quiz: "file_upload_questions_in_quiz",
    storage_capacity_exceeded: "storage_capacity_exceeded",
    kindly_inform_form_builder_that: "kindly_inform_form_builder_that",
    kindly_inform_quiz_builder_that: "kindly_inform_quiz_builder_thatt",
    their_storage_capacity_file_uploads: "their_storage_capacity_file_uploads",
    has_been_exceeded: "has_been_exceeded",
    view_details: "view_details",
    paste_your_video_link_here: "paste_your_video_link_here",
    paste_your_website_link_here: "paste_your_website_link_here",
    start_scale_from_zero: "start_scale_from_zero",
    view_answer_sheet: "view_answer_sheet",
    response_count: "response_count",
    count: "count",
    password_least_8_characters: "password_least_8_characters",
    password_contain_least_1_number: "password_contain_least_1_number",
    password_contain_least_1_capital_letter: "password_contain_least_1_capital_letter",
    password_contain_least_1_special_character: "password_contain_least_1_special_character",
    please_fill_all_required_fields: "please_fill_all_required_fields",
    passwords_dont_match: "passwords_dont_match",
    incorrect_password: "incorrect_password",
    select: "select",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using",
    url_does_not_seem_to_valid: "url_does_not_seem_to_valid",
    you_have_reached_image_uploaded_limit: "you_have_reached_image_uploaded_limit",
    clear: "clear",
    undo: "Undo",
    response_deleted: "response_deleted",
    question_deleted: "question_deleted",
    answer_deleted: "answer_deleted",
    responses_deleted: "responses_deleted",
    questions_deleted: "questions_deleted",
    answers_deleted: "answers_deleted",
    collect_email_alert_message: "collect_email_alert_message",
    collect_email_alert_note: "collect_email_alert_note",
    collect_email_header: "collect_email_header",
    allow_response_edit: "allow_response_edit",
    allow_response_edit_description: "allow_response_edit_description",
    enter_desc_here: "enter_desc_here",
    passcode_protection: "passcode_protection",
    passcode_protection_description: "passcode_protection_description",
    passcode_protection_description_quiz: "passcode_protection_description_quiz",
    crop_image: "crop_image",
    crop: "crop",
    original: "original",
    collect_email: "collect_email",
    collect_email_description: "collect_email_description",
    collect_email_description_quiz: "collect_email_description_quiz",
    collect_email_description_form: "collect_email_description_form",
    opening_date: 'opening_date',
    closing_date: 'closing_date',
    schedule_form_status: 'schedule_form_status',
    schedule_quiz_status: 'schedule_quiz_status',
    schedule_opening_date_and_time: "schedule_opening_date_and_time",
    schedule_closing_date_and_time: "schedule_closing_date_and_time",
    schedule_opening_date_and_time_description: "schedule_opening_date_and_time_description",
    schedule_opening_date_and_time_description_quiz: "schedule_opening_date_and_time_description_quiz",
    schedule_closing_date_and_time_description: "schedule_closing_date_and_time_description",
    schedule_closing_date_and_time_description_quiz: "schedule_closing_date_and_time_description_quiz",
    upgrade: "upgrade",
    buy_responses: "buy_responses",
    gold_plan: "gold_plan",
    view_plans: "view_plans",
    premium_plans: "premium_plans",
    plan_duration: "plan_duration",
    plan_logic_jump: "plan_logic_jump",
    plan_remove_ads: "plan_remove_ads",
    cancel_subscription: "cancel_subscription",
    quiz_has_answer_edit_message: "quiz_has_answer_edit_message",
    quiz_published_alert_edit_message: "quiz_published_alert_edit_message",
    quiz_submit_after_edit_message: "quiz_submit_after_edit_message",
    title_activity_privacy_policy: "title_activity_privacy_policy",
    action_blocked_message: "action_blocked_message",
    email_addresses: "email_addresses",
    confirmation_message: "confirmation_message",
    confirmation_message_description: "confirmation_message_description",
    confirmation_message_description_quiz: "confirmation_message_description_quiz",
    default_custom_thank_message: "default_custom_thank_message",
    default_custom_thank_message_quiz: "default_custom_thank_message_quiz",
    upgrade_your_plan: "upgrade_your_plan",
    upgrade_your_plan_1: "upgrade_your_plan_1",
    upgrade_your_plan_content: "upgrade_your_plan_content",
    alert_icon: "alert_icon",
    usage_details: "usage_details",
    current_plan: "current_plan",
    total_submission: "total_submission",
    image_attachments: "image_attachments",
    custom_themes: "custom_themes",
    free_limit_reached: "free_limit_reached",
    payment_done_but_server_error: "payment_done_but_server_error",
    response_quota_reached: "response_quota_reached",
    answers_quota_reached: "answers_quota_reached",
    logic_jump_enabled: "logic_jump_enabled",
    logic_jump_disabled: "logic_jump_disabled",
    cancel_subscription_message: "cancel_subscription_message",
    plan_expired: "plan_expired",
    plan_expired_edit_form: "plan_expired_edit_form",
    plan_expired_edit_quiz: "plan_expired_edit_quiz",
    plan_expired_duplicate_form: "plan_expired_duplicate_form",
    plan_expired_duplicate_quiz: "plan_expired_duplicate_quiz",
    premium_features: "premium_features",
    plan_file_upload: "plan_file_upload",
    plan_custom_theme: "plan_custom_theme",
    plan_call_support: "plan_call_support",
    plan_email_support: "plan_email_support",
    plan_custom_domain: "plan_custom_domain",
    subscribe: "subscribe",
    plan_remove_branding: "plan_remove_branding",
    plan_on_call_support: "plan_on_call_support",
    plan_image_attachment: "plan_image_attachment",
    plan_custom_thank_you: "plan_custom_thank_you",
    plan_allowed_responses: "plan_allowed_responses",
    trusted_by_number_of_user: "trusted_by_number_of_user",
    email_support: "email_support",
    call_support: "call_support",
    keyboard_shortcuts: "keyboard_shortcuts",
    premium_limit_reached: "premium_limit_reached",
    premium_limit_reached_mobile: "premium_limit_reached_mobile",
    unlimited: "unlimited",
    expired_on_next_renewal: "expired_on_next_renewal",
    upgrade_plan: "upgrade_plan",
    downgrade_plan: "downgrade_plan",
    file_upload_questions: "file_upload_questions",
    plan_response_edit: "plan_response_edit",
    cant_process: "cant_process",
    you_have_subscribed_through_android: "you_have_subscribed_through_android",
    pay_now: "pay_now",
    update_payment_details: "update_payment_details",
    limit_reached_alert_message: "limit_reached_alert_message",
    limit_reached_alert_message_quiz: "limit_reached_alert_message_quiz",
    limit_reached_UI_message: "limit_reached_UI_message",
    limit_reached_UI_message_quiz: "limit_reached_UI_message_quiz",
    owner_is_not_premium_user: "owner_is_not_premium_user",
    cancel_subscription_description: "cancel_subscription_description",
    bronze_storage_description: "bronze_storage_description",
    silver_storage_description: "silver_storage_description",
    gold_storage_description: "gold_storage_description",
    bronze_responses_description: "bronze_responses_description",
    silver_responses_description: "silver_responses_description",
    gold_responses_description: "gold_responses_description",
    bronze_image_description: "bronze_image_description",
    silver_image_description: "silver_image_description",
    gold_image_description: "gold_image_description",
    bronze_theme_description: "bronze_theme_description",
    silver_theme_description: "silver_theme_description",
    gold_theme_description: "gold_theme_description",
    custom_theme_image: "custom_theme_image",
    file_upload_description: "file_upload_description",
    logic_jump_description: "logic_jump_description",
    thank_you_message_description: "thank_you_message_description",
    response_edit_description: "response_edit_description",
    remove_branding_description: "remove_branding_description",
    bronze_remove_ads_description: "bronze_remove_ads_description",
    silver_remove_ads_description: "silver_remove_ads_description",
    gold_remove_ads_description: "gold_remove_ads_description",
    bronze_email_support_description: "bronze_remove_ads_description",
    silver_email_support_description: "silver_remove_ads_description",
    gold_email_support_description: "gold_remove_ads_description",
    bronze_email_support_title: "bronze_email_support_title",
    silver_email_support_title: "silver_email_support_title",
    gold_email_support_title: "gold_email_support_title",
    update_your_payment_details_mobile: "update_your_payment_details_mobile",
    update_your_payment_details_pc: "update_your_payment_details_pc",
    card_details_updated_successfully: "card_details_updated_successfully",
    card_details_updated_successfully_description: "card_details_updated_successfully_description",
    payment_successful: "payment_successful",
    payment_successful_description: "payment_successful_description",
    payment_failed: "payment_failed",
    payment_failed_description: "payment_failed_description",
    note_for_five_rupees: "note_for_five_rupees",
    logic: "logic",
    re_generate_code: "re_generate_code",
    plan_change_restrictions: "plan_change_restrictions",
    plan_change_restriction_desc_1: "plan_change_restriction_desc_1",
    plan_change_restriction_desc_2: "plan_change_restriction_desc_2",
    update_card_details: "update_card_details",
    features_and_plans: "features_and_plans",
    cancel_plan: "cancel_plan",
    cancel_plan_desc: "cancel_plan_desc",
    explore_text: "explore_text",
    cant_edit_this_form: "cant_edit_this_form",
    cant_edit_this_quiz: "cant_edit_this_quiz",
    cant_duplicate_this_form: "cant_duplicate_this_form",
    cant_duplicate_this_quiz: "cant_duplicate_this_quiz",
    kindly_upgrade_the_premium: "kindly_upgrade_the_premium",
    check_box: "check_box",
    continue_to_next_question: "continue_to_next_question",
    add_logic: "add_logic",
    submit_form: "submit_form",
    save_logic: "save_logic",
    remove_logic: "remove_logic",
    responses_add_ons: "responses_add_ons",
    additional_submissions: "additional_submissions",
    validity: "validity",
    up_to_your_current_plan: "up_to_your_current_plan",
    responses_add_ons_description: "responses_add_ons_description",
    redirect_url_title: "redirect_url_title",
    redirect_url_description: "redirect_url_description",
    enter_weblink: "enter_weblink",
    get: "get",
    jump_to: "jump_to",
    action_cannot_be_performed: "action_cannot_be_performed",
    upgrade_plan_desc1: "upgrade_plan_desc1",
    downgrade_plan_desc1: "downgrade_plan_desc1",
    downgrade_plan_desc2: "downgrade_plan_desc2",
    upgraded_plan: "upgraded_plan",
    downgraded_plan: "downgraded_plan",
    upgraded_plan_desc: "upgraded_plan_desc",
    downgraded_plan_desc: "downgraded_plan_desc",
    passcode: "passcode",
    share_link_with_passcode: "share_link_with_passcode"
}