import React from "react"
import premiumCrown from "../../../../public/images/premium_crown.svg";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {useDispatch, useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import {PremiumJSON} from "../../../shared/utils/helper";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";

const LimitReachedUI = (props) => {
    const {t} = useTranslation()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    return (
        <div style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            margin: isMobileOnly ? "10%" : 0,
            background: props.background ? props.background : "#FFFFFF",
            width: props.width ? props.width : isMobileOnly ? null : "80%",
            marginTop: isMobileOnly ? null : "5%",
            height: props.height ? props.height : isMobileOnly ? "90vh" : "80vh",
            borderRadius: isMobileOnly ? null : "10px"
        }}>
            <img src={premiumCrown} alt={"premium"} style={{width: "75px"}}/>
            <h2>{PremiumJSON[subscriptionData?.subscription_plan]?.rank === PremiumJSON["gold-monthly"].rank ? t(translationKeys.response_limit_reached).toUpperCase() : t(translationKeys.upgrade_your_plan)}!</h2>
            <p style={{
                marginTop: "0",
                width: props.width ? props.width : "37%"
            }}>{props.isQuiz ? PremiumJSON[subscriptionData?.subscription_plan]?.rank === PremiumJSON["gold-monthly"].rank ? t(translationKeys.limit_reached_UI_message_quiz) : t(translationKeys.limit_reached_alert_message) : PremiumJSON[subscriptionData?.subscription_plan]?.rank === PremiumJSON["gold-monthly"].rank ? t(translationKeys.limit_reached_UI_message) : t(translationKeys.limit_reached_alert_message)}</p>
            <div style={{display: "flex"}}>
                {PremiumJSON[subscriptionData?.subscription_plan]?.rank ? <MaterialButton data={{
                    title: t(translationKeys.buy_responses).toUpperCase(),
                    buttonType: PremiumJSON[subscriptionData?.subscription_plan]?.rank === PremiumJSON["gold-monthly"].rank ? ButtonTypes.CONTAINED : ButtonTypes.OUTLINED,
                    bg_color: "#004BA0",
                    customButtonContainerStyle: {
                        borderRadius: '50px',
                        width: '200px',
                        margin: "10px"
                    },
                    customButtonTextStyle: {
                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                        fontWeight: "bold",
                        fontSize: '16px',
                    }
                }} handleClick={() => {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_RESPONSE_ADD_ON, payload: true})
                }}
                /> : null}
                {PremiumJSON[subscriptionData.subscription_plan]?.rank === PremiumJSON["gold-monthly"].rank ? null :
                    <MaterialButton data={{
                        title: t(translationKeys.upgrade_plan).toUpperCase(),
                        customButtonContainerStyle: {
                            borderRadius: '50px',
                            backgroundColor: '#004BA0',
                            width: '200px',
                            margin: "10px"
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                            fontWeight: "bold",
                            fontSize: '16px',
                        }
                    }} handleClick={() => {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                        dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                    }}
                    />}
            </div>
        </div>
    )
}

export default LimitReachedUI
