export const translationsIndonesia = {
    all: 'Semua',
    ago: "yang lalu",
    now: "Sekarang",
    start: "Mulai",
    account_blocked: "Akun Diblokir",
    account_blocked_alert: "Akun ini telah diblokir karena melanggar Syarat dan Ketentuan kami",
    sign_out: "Keluar",
    short_answer: "Jawaban Singkat",
    long_answer: "Jawaban Panjang",
    email: "Surel",
    number: "Nomor",
    file: "Berkas",
    files: "Berkas",
    faq_videos: "Video FAQ",
    file_upload: "Unggah Berkas",
    question_error: "Pertanyaan tidak dapat ditambahkan",
    storage: "Penyimpanan",
    form: "Bentuk",
    quiz: "Kuis",
    fup_desc: "Sekarang Anda dapat mengizinkan responden ke Formulir dan Kuis Anda untuk mengunggah file!\n\nPilih Jenis Pertanyaan Unggah File untuk memulai.",
    new_fup_feature_title: "Fitur Unggah File Baru!",
    multiple_choice: "Pilihan Ganda",
    date_text: "Tanggal",
    time_text: "Waktu",
    uploading: "Mengunggah...",
    add_question: "Tambahkan Pertanyaan",
    add_question_alert: "Harap tambahkan minimal satu pertanyaan dengan semua item yang wajib diisi.",
    add_question_alert_quiz: "Mohon tambahkan setidaknya satu pertanyaan sebelum mengirimkan kuis",
    ok: "Oke",
    title: "Judul",
    title_required: "Judul wajib diisi",
    form_name_alert: "Harap beri nama formulir sebelum menerbitkannya.",
    quiz_name_alert: "Mohon beri nama kuis sebelum menerbitkannya",
    no_connection: "Tidak Ada Sambungan",
    check_connection: "Harap periksa sambungan jaringan Anda.",
    finish_incomplete_question: "Harap selesaikan pertanyaan yang belum lengkap dalam formulir",
    draft: "Konsep",
    save_to_draft_alert: "Apakah Anda ingin menyimpan formulir ini sebagai konsep?",
    save_to_draft_quiz_alert: "Apakah Anda ingin menyimpan kuis ini sebagai Draf untuk menghindari kehilangan perubahan Anda?",
    yes: "Ya",
    no: "Tidak",
    cancel: "Batal",
    live_preview: "Pratinjau Langsung",
    themes: "Tema",
    tap_to_set_theme: "Ketuk di sini untuk menetapkan tema untuk formulir.",
    select_image: "Pilih Gambar",
    created: "Dibuat",
    form_created_successfully: "Formulir berhasil dibuat",
    warning: "Peringatan",
    form_edit_alert: "Formulir ini telah diterbitkan dan tanggapan mungkin telah dikumpulkan. Mengedit formulir dapat memengaruhi tanggapan yang sudah dikumpulkan.",
    quiz_edit_alert: 'Kuis ini telah dikirimkan dan jawaban telah dikumpulkan. Mengedit kuis dapat mempengaruhi jawaban sebelumnya',
    edit: "Sunting",
    download_as: "Unduh",
    delete_form: "Hapus Formulir",
    delete_form_alert: "Ingin menghapus formulir ini? Tindakan ini tidak dapat dibatalkan.",
    delete_quizzes_alert: "Apakah Anda ingin menghapus kuis ini? Tindakan ini tidak bisa dibatalkan.",
    delete_forms_alert: "Apakah Anda ingin menghapus formulir ini? Tindakan ini tidak bisa dibatalkan.",
    delete_quiz_alert: "Apakah Anda ingin menghapus kuis ini? Tindakan ini tidak bisa dibatalkan.",
    delete_answers_alert: "Apakah Anda ingin menghapus jawaban ini?",
    delete_answer_alert: "Apakah Anda ingin menghapus jawaban ini?",
    delete: "Hapus",
    delete_all: "Hapus semua",
    section: "Bagian",
    worst: "Sangat Buruk",
    server_error_alert: "Server tidak dapat ditemukan. Silakan coba lagi setelah beberapa saat!!",
    signed_in_as: "Masuk sebagai",
    signing_out: "Sedang keluar...",
    create_form: "Buat Formulir",
    create_blank_form: "Buat Formulir Kosong",
    create_blank_quiz: "Buat Kuis Kosong",
    close: "Tutup",
    response_collection_exit_alert: "Ingin keluar dari mengumpulkan tanggapan?",
    form_builder: "Pembuat Formulir",
    form_builder_promo: "Pratinjau, Templat, dan Tema Instan untuk Survei Anda",
    results: "Hasil",
    result_feature_promo: "Analisis dan Ekspor hasil survei Anda",
    notification: "Notifikasi",
    delete_option_alert: "Ingin menghapus opsi ini?  [Catatan: Tindakan ini tidak dapat dibatalkan]",
    question_type: "Jenis Pertanyaan",
    change_question_type_alert: "Ketuk di sini untuk mengubah jenis pertanyaan",
    preview_not_available: "Pratinjau Tidak Tersedia",
    delete_question: "Hapus Pertanyaan",
    delete_response_collected_question_alert: "Menghapus pertanyaan ini akan memengaruhi tanggapan yang dikumpulkan.\n\nYakin ingin menghapus pertanyaan ini?",
    delete_question_alert: "Yakin ingin menghapus pertanyaan ini?",
    options: "Opsi",
    option: 'Opsi',
    no_answer: "Tidak Ada Jawaban",
    no_response: "Tidak ada respon",
    responses: "tanggapan",
    response: "tanggapan",
    serial_number: "S.NO",
    submit_time: "Waktu Pengiriman",
    general_form_type_description: "Buat Formulir / Survei baru",
    quiz_type_description: "Buat Kuis baru",
    sheet_disconnected: 'Google Spreadsheet Terputus',
    sheet_not_connected: 'Lembar Tidak Terhubung',
    sheet_not_connected_desc: 'Silakan hubungi pemilik untuk menghubungkan lembar google.',
    export_success: "Berhasil Diekspor",
    answer: "Jawaban",
    answer_with_colan: "Jawaban:",
    others: "Lainnya",
    delete_response: "Hapus Tanggapan",
    deleted: "Dihapus",
    my_themes: "Tema Saya",
    limit_reached: "Batas Tercapai",
    limit_reached_description: "Anda telah mencapai batas upload gambar.",
    modified: "Diubah",
    delete_image: "Hapus Gambar",
    delete_image_alert: "Yakin ingin menghapus gambar ini?",
    delete_theme_image_desc: "Apakah Anda ingin menghapus gambar ini? Jika Anda melanjutkan, gambar ini tidak akan tersedia untuk Formulir atau Kuis apa pun yang menggunakannya. Tindakan ini tidak bisa dibatalkan.",
    section_title: "Judul bagian",
    section_description: "Keterangan bagian",
    show_scale: 'Tampilkan Skala',
    show_options: "Tampilkan Opsi",
    options_required: "Opsi wajib diisi",
    link_copied: "Tautan Disalin",
    app_not_found: "Aplikasi tidak ditemukan",
    default_text: "bawaan",
    dark_mode: "Mode Gelap",
    done: "Selesai",
    upload_image: "Unggah Gambar",
    question: "Pertanyaan",
    required: "Wajib Diisi",
    share: "Bagikan",
    form_link: "Tautan Formulir",
    copy_link: "Salin tautan",
    view_form: "Lihat Formulir",
    menu: "Menu",
    view_all: "semua",
    settings: "Pengaturan",
    submit: "Kirim",
    change_theme: "Ubah Tema",
    tap_here_to_choose_theme: "Ketuk di sini untuk memilih tema",
    show_logo: "Tampilkan Logo",
    show_logo_alert: "Ingin menampilkan logo dalam formulir?",
    welcome_page: "Halaman Sambutan",
    show_welcome_page_alert: "Ingin menampilkan halaman sambutan?",
    start_button: "Tombol Mulai",
    allow_multiple_responses: "Izinkan Banyak Tanggapan",
    allow_more_response_alert: "Ingin mengizinkan pengguna tersebut menanggapi lebih dari sekali?",
    allow_more_quiz_attempt_alert: "Apakah Anda ingin mengizinkan pengguna mencoba lebih dari sekali?",
    show_summary: "Tampilkan Ringkasan",
    show_results: "Tampilkan Hasil",
    allow_responders_view_summary_alert: "Ingin mengizinkan responden tersebut untuk melihat ringkasan semua tanggapan setelah mengirim?",
    allow_result_view_quiz_alert: "Apakah Anda ingin mengizinkan pengguna untuk melihat hasil?",
    show_question_number: "Tampilkan Nomor Pertanyaan",
    show_question_number_in_form: "Apakah Anda ingin menunjukkan nomor pertanyaan pada formulir?",
    show_question_number_in_quiz: "Apakah Anda ingin menunjukkan nomor pertanyaan pada kuis?",
    shuffle_questions: "Acak Pertanyaan",
    shuffle_questions_alert: "Ingin mengacak pertanyaan formulir?",
    shuffle_quiz_questions_alert: "Apakah Anda ingin mengacak pertanyaan kuis?",
    description: "Keterangan",
    builder: "Pembangun",
    preview: "Pratinjau",
    form_status: "Status Formulir",
    active: "Aktif",
    inactive: "Tidak aktif",
    import_questions: 'Impor Pertanyaan',
    search_by_question_title_from_forms: 'Cari berdasarkan judul pertanyaan dari formulir yang ada',
    search_by_question_title_from_quizzes: 'Cari berdasarkan judul pertanyaan dari kuis yang ada',
    view_responses: "Lihat Tanggapan",
    edit_form: "Edit Formulir",
    duplicate_form: "Gandakan Formulir",
    drafts: "Konsep",
    no_form_available: "Tidak Ada Formulir yang Tersedia!",
    notifications: "Notifikasi",
    feedback: "Umpan Balik",
    privacy_policy: "Kebijakan Privasi",
    open: 'Buka',
    open_individual_responses: "Buka Tanggapan Individu",
    bar_chart: "DIAGRAM BATANG",
    pie_chart: "DIAGRAM PAI",
    summary: "Ringkasan",
    invalidated_form_cannot_be_selected: "Formulir yang Dibatalkan tidak dapat dipilih.",
    invalidated_quiz_cannot_be_selected: "Kuis yang Dibatalkan tidak dapat dipilih.",
    individual: "Individu",
    search: "Cari…",
    image: "Gambar",
    video: "Video",
    website: "Situs Web",
    exit: "Keluar",
    response_limit_reached: "Batas Respon Tercapai",
    response_limit_reached_alert: "Formulir ini telah melampaui ambang yang diizinkan untuk data respons; oleh karena itu, kami mencegah formulir ini dari menerima tanggapan lagi.\n\nJika Anda benar-benar perlu melanjutkan dengan Survei yang ada, kami sarankan Anda menduplikatnya dan membagikan tautannya.",
    unapproved_form: "Formulir yang tidak disetujui",
    unapproved_form_alert: "Formulir ini menggunakan kata-kata yang dapat melanggar kebijakan kami.\n\nJika Anda yakin bahwa formulir ini telah diblokir karena kesalahan, harap buka tautan formulir di bawah ini dan berikan informasi yang diminta dalam formulir.",
    choose_your_language: "Pilih bahasamu",
    provide_explanation: "Berikan Penjelasan",
    selected: "terpilih",
    review_button: "Ulasan",
    not_specified: "Tidak ditentukan",
    quiz_control: "Kontrol Kuis",
    allow_multiple_attempts: "Izinkan Banyak Upaya",
    user_info: "Info Pengguna",
    name_s: "Nama",
    duration: "Durasi",
    set_duration_quiz_alert: "Apakah Anda ingin menetapkan durasi untuk kuis?",
    user_information_description: "Apakah Anda ingin mendapatkan informasi peserta?",
    set_duration: "Setel Durasi (Jam: Menit)",
    minutes: "Menit",
    hours: "Jam",
    minute: "Menit",
    hour: "Jam",
    correct: "Benar",
    wrong: "Salah",
    skipped: "Dilewati",
    score: "Skor",
    forms: "Formulir",
    quizzes: "Kuis",
    more: "Lebih",
    quiz_status: "Status Kuis",
    view_answers: "Lihat Jawaban",
    view_quiz: "Lihat Kuis",
    duplicate_quiz: "Kuis Gandakan",
    no_quiz_available: "Tidak Ada Kuis!",
    quiz_link: "Tautan Kuis",
    unapproved_quiz: "Kuis yang tidak disetujui",
    unapproved_quiz_alert: "Kuis ini menggunakan kata-kata yang mungkin melanggar kebijakan kami. Jika Anda yakin bahwa kuis ini diblokir karena kesalahan, silakan buka tautan formulir di bawah dan berikan informasi yang diminta dalam formulir.",
    do_you_want_to_show_all_correct_answers_to_users: "Apakah Anda ingin menunjukkan jawaban yang benar kepada pengguna?",
    disconnect: 'MEMUTUSKAN',
    results_publish_date: "Hasil Tanggal Penerbitan",
    do_you_want_to_set_date_to_publish_results: "Apakah Anda ingin mengatur tanggal untuk mempublikasikan hasil",
    quiz_result: "Hasil Kuis",
    enter_answer: "Menjawab",
    manage: "Mengelola",
    manage_storage: 'Kelola Penyimpanan',
    select_all: 'Pilih Semua',
    shuffle_options: 'Opsi Acak',
    shuffle_options_enabled: 'Opsi Acak Diaktifkan',
    shuffle_options_disabled: 'Opsi Acak Dinonaktifkan',
    add_options_to_enable_this_feature: 'Tambahkan Opsi untuk Mengaktifkan Fitur ini',
    attach: 'Menempel',
    shuffle: 'Acak',
    marks: "Tanda",
    mark: "Menandai",
    correction_status: "Status:",
    status_validated: "Divalidasi",
    pending: "Menunggu keputusan",
    results_publish: "Menerbitkan",
    publish: "Menerbitkan",
    publish_description: "Apakah Anda ingin mempublikasikan hasil kuis?",
    publish_description_note: "(Tanda akan Terbitkan di URL yang sama)",
    published_successfully: "Berhasil Diterbitkan",
    choice_type_validation_note: "*Pilihan ganda divalidasi secara otomatis",
    short_answer_type_validation_note: "*Jawaban singkat divalidasi secara otomatis",
    long_answer_type_validation_note: "*Jawaban panjang TIDAK divalidasi secara otomatis",
    fileupload_answer_type_validation_note: "*Unggahan File TIDAK divalidasi secara otomatis",
    dropdown_answer_type_validation_note: "*Pilihan dropdown divalidasi secara otomatis",
    save: "menyimpan",
    questions: "Pertanyaan",
    show_correct_answer: "Tunjukkan Jawaban yang Benar",
    show_answer: "Tunjukkan jawaban",
    hide_answer: "Sembunyikan jawaban",
    no_marks: "Tanpa Tanda",
    question_is_required: "Judul pertanyaan wajib diisi",
    marks_is_required: "Tanda wajib diisi",
    options_are_required: "Opsi diperlukan",
    options_limit_reached: 'batas pilihan tercapai',
    options_limit_reached_description: 'Anda telah mencapai batas jumlah opsi yang diperbolehkan pada sebuah pertanyaan.',
    correct_answer_required: "Jawaban yang benar diperlukan",
    enter_valid_mark: "Masukkan tanda yang valid",
    evaluated: "Dievaluasi",
    saved: "Diselamatkan",
    pending_evaluation: "Evaluasi Tertunda",
    alphabet: "Alfabet",
    not_evaluated: "Tidak dievaluasi",
    percentage_ascending: "Persentase - Menaik",
    percentage_descending: "Persentase - Menurun",
    pending_evaluation_alert_message: "Anda harus menyelesaikan evaluasi dari semua jawaban sebelum menerbitkan.",
    show_unevaluated: "Tampilkan Tidak Dievaluasi",
    publish_success_alert_message: "Responden dapat melihat hasilnya di lembar jawaban yang sesuai.",
    publishing: "Penerbitan",
    quiz_published: "Kuis Diterbitkan",
    quiz_published_alert_before_edit_message: "Kuis ini telah diterbitkan, Jika Anda perlu melakukan perubahan, Anda harus menggandakan Kuis dan membagikannya lagi.",
    quiz_has_answers: "Quiz memiliki Jawaban",
    quiz_has_answer_alert_message: "Untuk mengedit kuis ini, Anda harus menghapus semua jawaban yang ada.",
    unavailable_features_description: 'Formulir ini memiliki fitur yang belum didukung di Aplikasi Web',
    unavailable_quiz_features_description: 'Kuis ini memiliki fitur yang belum didukung di Aplikasi Web',
    unavailable: 'Tidak tersedia',
    unpublished: "Tidak diterbitkan",
    published: "Diterbitkan",
    setting_updated: "Pengaturan Diperbarui",
    account: "Akun",
    on: "DI",
    off: "MATI",
    scan_qr_code: "Pindai kode QR",
    answers: "jawaban",
    answer_s: "menjawab",
    question_limit_reached: "Batas Pertanyaan Tercapai",
    reached_limit_for_number_of_questions: "Anda telah mencapai batas jumlah pertanyaan yang diperbolehkan di formulir. Oleh karena itu, tidak ada pertanyaan baru yang dapat ditambahkan",
    life_time: "Seumur hidup",
    last_year: "Tahun lalu",
    last_30_days: "30 hari terakhir",
    last_7_days: "7 Hari Terakhir",
    last_24_hours: "24 Jam Terakhir",
    custom_date: "Tanggal Kustom",
    filter_by_date: "Filter menurut tanggal",
    filter_by_question: "Filter berdasarkan Pertanyaan",
    filter_text: "Filter teks",
    add_filter: "Tambahkan Filter",
    apply: "Berlaku",
    from_date: "Dari tanggal",
    today: 'Hari ini',
    to_date: "Hingga saat ini",
    contains: "Mengandung",
    equal_to: "Sama dengan",
    not_equal_to: "Tidak sebanding dengan",
    from_date_required: "Dari Tanggal Diperlukan",
    to_date_required: "Sampai Tanggal Diperlukan",
    webapp_promo_text: "Akses Formulir, Survei, Jajak Pendapat & Kuis Online Anda!",
    question_title: "Pertanyaan",
    invalid_date: "Tanggal tidak berlaku",
    invalid_url: "URL tidak valid",
    invalid_url_description: "URL tampaknya tidak valid.",
    delete_response_alert: "Yakin ingin menghapus tanggapan ini?",
    delete_responses_alert: "Apakah Anda ingin menghapus tanggapan ini?",
    overview: "Gambaran",
    no_results_found: "Tidak ada hasil yang ditemukan",
    date_format: "dd-mm-yyyy",
    title_dashboard: "Dasbor",
    title_notifications: "Notifikasi",
    select_one_response: "Silakan pilih setidaknya satu tanggapan untuk dihapus",

//Untranslated strings

    your_account: "Akun Anda",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    feature_spotlight: 'Sorotan Fitur',
    favourites: "Favorit",
    shared: "Bersama",
    hide: "Bersembunyi",
    use_templates: "Gunakan Template",
    quick_settings: "Pengaturan Cepat",
    leave_collaboration: "Tinggalkan Kolaborasi",
    templates: "Templat",
    collapse: "Jatuh",
    start_scale_from_zero: 'Mulai skala dari 0',
    center_label_optional: 'Label Tengah (Opsional)',
    left_label_optional: 'Label Kiri (Opsional)',
    right_label_optional: 'Label Kanan (Opsional)',
    duplicate: "Duplikat",
    row: "Baris",
    column: "Kolom",
    rows: "Baris",
    add_column: 'Tambahkan Kolom',
    add_row: 'Menambahkan baris',
    user_info_for_file_upload: "Info Pengguna Untuk Unggah File",
    user_info_for_file_upload_desc1: "Karena Anda telah menambahkan Pertanyaan Unggah File, opsi untuk mengumpulkan Info Pengguna telah diaktifkan. Ini akan memudahkan untuk mengidentifikasi file yang diunduh. Responden akan melihat dialog untuk memasukkan informasi mereka.",
    user_info_for_file_upload_desc2: "Anda dapat mengubah opsi ini di Pengaturan jika Anda tidak ingin dialog ditampilkan.",
    show_rows_and_columns: "Tampilkan Baris & Kolom",
    scale_down: 'Menurunkan',
    scale_up: 'Meningkatkan',
    dislike: 'Benci',
    like: 'Menyukai',
    best: 'Terbaik',
    remove_favourite: "Hapus Favorit",
    add_favourite: "Tambahkan Favorit",
    collaborator: "Kolaborator",
    collaborators: "Kolaborator",
    show_more: "Menampilkan lebih banyak",
    create_quiz: "Buat Kuis",
    collaborate: "Berkolaborasi",
    share_QR_code: "Bagikan Kode QR",
    add_image: "Menambahkan gambar",
    invalid_file_type: "Jenis File Tidak Valid",
    drag_drop: "Seret dan lepas",
    here: "Di Sini",
    choose: "Memilih",
    add_account: 'Menambahkan akun',
    choose_account: 'Pilih Akun',
    maximum_allowed_file_size: "Ukuran file maksimum yang diizinkan adalah ",
    or: "atau",
    next: "Lanjut",
    change_image: "Ganti gambar",
    upload: "Mengunggah",
    view: "Melihat",
    collaboration: "Kolaborasi",
    share_collaboration_form: "Apakah Anda ingin Berbagi formulir ini dengan orang lain?",
    share_collaboration_quiz: "Apakah Anda ingin Berbagi kuis ini dengan orang lain?",
    multiple_responses_summary: "Beberapa Tanggapan, Ringkasan",
    question_number_shuffle: "Nomor pertanyaan, Tanda tanya, Acak",
    general: "Umum",
    browse: "Jelajahi",
    owner: "Pemilik",
    something_went_wrong: "Ada yang salah!",
    forget_your_password: "Lupa kata sandi Anda?",
    sign_in: "Masuk", //
    sign_up: "Daftar",  //
    using_email: "menggunakan Email",
    dont_have_account: "Tidak punya akun?",
    on_signing_up_agree_to_surveyhearts: "Dengan mendaftar, Anda setuju untuk SurveyHeart's",
    and: "dan",
    terms_of_use: "Syarat Penggunaan",
    already_have_an_account: "Sudah memiliki akun?",
    signed_in_previously_as_a_google_user: "Anda telah masuk sebelumnya sebagai pengguna Google.",
    sign_in_google_to_continue: "Harap masuk melalui Google untuk melanjutkan.",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "Catatan: Untuk melihat info ini lagi, klik 'Lainnya' di Menu di Sisi Kiri",
    no_account_found_email_address: "Tidak ditemukan akun dengan alamat email ini",
    account_already_exist_email: "Akun sudah ada untuk email ini.",
    install_surveyheart_app: "Instal Aplikasi SurveyHeart",
    easy_login_via_app: "Login Mudah melalui Aplikasi",
    get_notification_instantly: "Dapatkan Pemberitahuan Seketika",
    create_form_quiz_on_the_go: "Buat Formulir/Kuis Saat Dalam Perjalanan",
    check_your_inbox_to_activate_your_account: "Silakan periksa kotak masuk Anda untuk mengaktifkan akun Anda",
    select_questions: "Pilih Pertanyaan",
    percentage: "Persentase",
    free_App_to_create_forms: "Dapatkan Aplikasi gratis untuk membuat formulir",
    we_cant_find_the_page_that_you_are_looking_for: "Kami tidak dapat menemukan halaman yang Anda cari",
    answered: "Dijawab",
    completing_this_quiz: "Terima kasih telah menyelesaikan kuis ini",
    connected_successfully: 'Berhasil Terhubung',
    seconds: "Detik",
    enter_your_details: "Masukkan Detail Anda",
    enter_all_required_information: "Mohon isikan semua informasi yang dibutuhkan",
    answer_data_not_found: "Data Jawaban Tidak Ditemukan",
    results_published_soon: "Hasil akan segera dipublikasikan",
    previous: 'sebelumnya',
    save_link_for_future_reference: "Simpan tautan ini untuk referensi di masa mendatang",
    choice_questions_only: "Pertanyaan Pilihan Saja",
    score_is_not_final: "Catatan: Skor ini belum final",
    tabular: "Datar",
    text: "teks",
    answer_response_alert: "Pertanyaan ini membutuhkan jawaban",
    invalid_email_address: "alamat email salah",
    answer_not_available: "Jawaban Tidak Tersedia",
    validated_automatically: "* divalidasi secara otomatis",
    answer_link: "Tautan Jawaban",
    embed_code: 'Sematkan kode',
    copy_code: 'Salin kode',
    code_copied: 'Kode disalin',
    share_via: "Bagikan melalui",
    print: "Mencetak",
    columns: 'Kolom',
    columns_all: "Kolom (Semua)",
    submitted_time: "Waktu yang Dikirim",
    unable_to_submit: 'Tidak Dapat Mengirim',
    unable_to_submit_alert_line1: 'Karena Kuis ini telah menerima Jawaban, Anda memiliki akses terbatas untuk melakukan perubahan.',
    unable_to_submit_alert_line2: 'Untuk mendapatkan akses penuh untuk mengedit kuis ini, Anda harus menghapus semua jawaban yang ada.',
    action_blocked: 'Tindakan Diblokir',
    action_blocked_alert: 'Tindakan diblokir karena jawaban yang ada untuk kuis ini. Mengizinkan hal ini mungkin memengaruhi jawaban sebelumnya.',
    quiz_edit_submit_alert: 'Mengedit Kuis dapat mempengaruhi Nilai, Persentase dan memerlukan evaluasi ulang atas pertanyaan-pertanyaan tertentu.',
    continue: 'Melanjutkan',
    submitted: "Dikirim",
    submitting: "Mengirimkan",
    filter: "Saring",
    exit_screen: "Keluar Layar",
    full_screen: "Layar penuh",
    tabular_view: "Tampilan Tabular",
    clear_all: "Bersihkan semua",
    you_are_all_caught_up: "Anda semua terjebak!",
    check_back_later_for_new_notifications: "Periksa kembali nanti untuk notifikasi baru.",
    requires_answer: "membutuhkan jawaban",
    enter_member_id: "Masukkan id anggota",
    viewers_can_read_form_responses: "Pemirsa dapat membaca formulir dan melihat tanggapan",
    editors_edit_form_view_responses: "Editor dapat mengedit formulir dan melihat tanggapan",
    admin_edit_form_add_collaborator: "Admin dapat mengedit formulir dan menambahkan kolaborator",
    invalid_member_id: "ID anggota tidak valid",
    already_member_added: "Sudah anggota ini ditambahkan",
    add_collaborator: "Tambahkan Kolaborator",
    admin: "Admin",  //
    editor: "Editor",  //
    viewer: "Penonton",
    searched_form_not_found: "Formulir yang Dicari Tidak Ditemukan!",
    searched_quiz_not_found: "Kuis yang Dicari Tidak Ditemukan!",
    no_shared_data_available: "Tidak Ada Data Bersama yang Tersedia!",
    forms_server_is_not_available: "Server Formulir tidak tersedia",
    quiz_server_is_not_available: "Server Kuis tidak tersedia",
    leave: 'meninggalkan',
    password: 'Kata sandi',
    new_password: 'kata sandi baru',
    retype_password: 'Ketik ulang kata sandi',
    create_password: 'Buat Kata Sandi',
    view_results: 'Lihat hasil',
    control: 'Kontrol',
    q_control: 'ulangan Kontrol',
    q_result: ' ulangan Hasil',
    no_matches_found: "Tidak Ada Kecocokan Ditemukan",
    dropdown: "tarik-turun",
    checkboxes: "kotak centang",
    star_rating: 'Peringkat Bintang',
    linear_scale: 'Skala linear',
    multiple_choice_grid: 'Kotak Pilihan Ganda',
    checkboxes_grid: 'Kotak centang Kotak',
    smile: 'Senyum',
    info_1: "Informasi 1",
    info_2: "Informasi 2",
    info_3: "Informasi 3",
    new: 'baru',
    download_all: 'Unduh Semua',
    download: 'Unduh',
    sort_by: 'Sortir dengan',
    sorting: 'Penyortiran',
    refresh: 'menyegarkan',
    add: 'Menambah',
    refresh_preview_description: "Klik tombol 'Segarkan' untuk memperbarui Pratinjau",
    enter_users_email_address: 'Masukkan alamat Email',
    do_you_want_to_delete_these: 'Apakah Anda ingin menghapus ini?',
    do_you_want_to_delete_this_form: 'Apakah Anda ingin menghapus Formulir ini',
    all_responses_will_also_be_deleted: 'Semua tanggapan juga akan dihapus',
    this_action_cannot_be_undone: 'Tindakan ini tidak bisa dibatalkan',
    do_you_want_to_delete_this_quiz: 'Apakah Anda ingin menghapus Kuis ini',
    all_answer_sheets_will_also_be_unavailable: 'Semua lembar jawaban juga tidak akan tersedia.',
    change: 'Mengubah',
    roles_rights: 'Peran & Hak',
    learn_more: 'Belajarlah lagi',
    viewers_can_read_quiz_and_view_answers: 'Pemirsa dapat membaca kuis dan melihat jawabannya',
    editors_can_edit_quiz_view_answers: 'Editor dapat mengedit kuis dan melihat jawabannya',
    admin_can_edit_quiz_add_collaborator: 'Admin dapat mengedit kuis dan menambahkan kolaborator',
    change_roles: 'Ganti peran',
    remove: 'menghapu',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'Anda belum mengirimkan perubahan Anda. Apakah tidak apa-apa untuk pergi tanpa menyerahkan?',
    select_language: 'Pilih bahasa',
    are_you_sure_you_want_delete_this_collaborator: 'Yakin ingin menghapus kolaborator ini?',
    are_you_sure_you_want_to_delete_selected_files: 'Apakah Anda yakin ingin menghapus file yang dipilih secara permanen?',
    please_download_before_deleting: 'Silakan unduh sebelum menghapus. File yang pernah dihapus, tidak dapat dipulihkan!',
    role_has_been_changed_successfully: 'peran telah berhasil diubah',
    do_you_want_to_leave_collaboration: 'Apakah Anda ingin meninggalkan kolaborasi?',
    form_builder_access_denied: 'Akses Pembuat Formulir Ditolak',
    quiz_builder_access_denied: 'Akses Pembuat Kuis Ditolak',
    you_only_have_viewer_rights_for_quiz: 'Anda hanya memiliki Hak Pemirsa untuk Kuis ini.',
    you_only_have_viewer_rights_for_form: 'Anda hanya memiliki Hak Pemirsa untuk Formulir ini.',
    kindly_contact_the_form_owner_for_more_info: 'Silakan hubungi Pemilik Formulir untuk info lebih lanjut.',
    kindly_contact_the_quiz_owner_for_more_info: 'Silakan hubungi Pemilik Kuis untuk info lebih lanjut.',
    incomplete_questions: 'Pertanyaan Tidak Lengkap:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'Anda memiliki perubahan yang belum disimpan - apakah Anda yakin ingin meninggalkan halaman ini?',
    languages: 'Bahasa',
    copy: "salinan",
    not_good: 'tidak baik',
    not_allowed: 'tidak diperbolehkan',
    not_allowed_description: 'Jenis pertanyaan pilihan tidak dapat diubah setelah mengumpulkan tanggapan.',
    neutral: 'netral',
    good: 'bagus',
    very_good: 'sangat bagus',
    edit_rights_removed: 'Hak Edit Dihapus',
    your_rights_to_edit_this_form_have_been_removed: "Hak Anda untuk mengedit Formulir ini telah dihapus.",
    your_rights_to_edit_this_quiz_have_been_removed: "Hak Anda untuk mengedit Kuis ini telah dihapus.",
    please_contact_quiz_owner_for_more_info: "Silakan hubungi Pemilik Formulir untuk info lebih lanjut.",
    please_contact_form_owner_for_more_info: "Silakan hubungi Pemilik Kuis untuk info lebih lanjut.",
    last_edited_on: 'Terakhir Diedit pada',
    last_edited: 'Terakhir Diedit',
    by: 'oleh',
    you: "Anda",
    add_option: 'Tambahkan Opsi',
    add_other_option: "Tambahkan Opsi 'Lainnya'",
    estimated_time: "Perkiraan waktu",
    do_you_want_to_show_estimated_time_taking_quiz: "Apakah Anda ingin menunjukkan perkiraan waktu untuk mengikuti kuis?",
    do_you_want_to_show_estimated_time_taking_form: "Apakah Anda ingin menunjukkan perkiraan waktu untuk mengambil formulir?",
    show_question_count: "Tampilkan jumlah pertanyaan",
    do_you_want_to_show_total_number_questions_quiz: "Apakah Anda ingin menunjukkan jumlah total pertanyaan dalam kuis?",
    do_you_want_to_show_total_number_questions_form: " Apakah Anda ingin menunjukkan jumlah total pertanyaan dalam formulir?",
    show_question_marks: "Tampilkan Tanda Tanya",
    show_question_marks_quiz: "Tampilkan tanda tanya",
    you_dont_have_add_collaborator_permission: "Anda tidak memiliki izin menambahkan kolaborator",
    modified_date: "Tanggal yang diubah",
    created_date: "Tanggal Dibuat",
    question_cant_be_added: "PERTANYAAN TIDAK BISA DITAMBAHKAN",
    another_file_upload_question_cant_added: "PERTANYAAN UPLOAD FILE LAIN TIDAK BISA DITAMBAHKAN",
    you_can_have_only: "Anda hanya dapat memiliki",
    file_upload_questions_in_form: "Unggah File Pertanyaan dalam Formulir",
    file_upload_questions_in_quiz: "Unggah File Pertanyaan dalam Kuis",
    storage_capacity_exceeded: "Kapasitas Penyimpanan Melebihi",
    kindly_inform_form_builder_that: "Mohon beri tahu Pembuat Formulir bahwa",
    kindly_inform_quiz_builder_that: "Mohon beri tahu Pembuat Kuis bahwa",
    their_storage_capacity_file_uploads: "kapasitas penyimpanan mereka untuk Unggahan File",
    has_been_exceeded: "telah terlampaui",
    view_details: "MELIHAT RINCIAN",
    paste_your_video_link_here: "Tempelkan tautan video Anda di sini",
    paste_your_website_link_here: "Tempelkan tautan situs web Anda di sini",
    view_answer_sheet: "Lihat Lembar Jawaban",
    response_count: "Jumlah Respon",
    count: "menghitung",
    password_least_8_characters: "Kata sandi harus minimal 8 - 50 karakter",
    password_contain_least_1_number: "Kata sandi harus mengandung setidaknya 1 angka",
    password_contain_least_1_capital_letter: "Kata sandi harus mengandung setidaknya 1 huruf kapital",
    password_contain_least_1_special_character: "Kata sandi harus mengandung setidaknya 1 karakter khusus",
    please_fill_all_required_fields: "Harap isi semua bidang yang wajib diisi",
    passwords_dont_match: "Kata sandi tidak cocok",
    incorrect_password: "kata kunci Salah",
    click_or_change_current_theme: "Klik Tema saat ini atau 'Ubah' di bawah untuk memilih Tema yang berbeda",
    select: "Pilih",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "Apakah Anda ingin menghapus gambar ini? Jika Anda melanjutkan, gambar ini tidak akan tersedia untuk Formulir atau Kuis apa pun yang menggunakannya. Tindakan ini tidak bisa dibatalkan.",
    url_does_not_seem_to_valid: "URL tampaknya tidak valid.",
    you_have_reached_image_uploaded_limit: "Anda telah mencapai batas upload gambar.",
    undo: "Membuka",
    response_deleted: "Respons Dihapus",
    question_deleted: "Pertanyaan Dihapus",
    answer_deleted: "Jawaban Dihapus",
    responses_deleted: "Tanggapan Dihapus",
    questions_deleted: "Pertanyaan Dihapus",
    answers_deleted: "Jawaban Dihapus",
    collect_email_header: "Kumpulkan EMAIL",
    collect_email_alert_message: "Mengaktifkan fitur ini akan memaksa responden untuk masuk dengan alamat gmail mereka untuk mengisi formulir.",
    collect_email_alert_note: "CATATAN: Hanya akun Google yang didukung",
    allow_response_edit: "Izinkan Pengeditan Respons",
    allow_response_edit_description: "Apakah Anda ingin mengizinkan responden untuk mengedit respons setelah mengirimkan?",
    enter_desc_here: "Masukkan Deskripsi di sini",
    passcode_protection: "Perlindungan Kode Sandi",
    passcode_protection_description: "Apakah Anda ingin formulir Anda dilindungi kode sandi?",
    passcode_protection_description_quiz: "Apakah Anda ingin kuis Anda dilindungi kode sandi?",
    crop_image: "Pangkas Gambar",
    crop: "Tanaman",
    original: "Asli",
    collect_email: "Kumpulkan Alamat Email",
    collect_email_description: "Apakah Anda ingin mengumpulkan alamat email responden untuk referensi atau pemanfaatan potensial di masa mendatang?",
    opening_date: 'Tanggal Pembukaan',
    collect_email_description_quiz: "Mengaktifkan fitur ini akan memaksa responden untuk masuk dengan alamat gmail mereka untuk mengisi kuis.",
    collect_email_description_form: "Mengaktifkan fitur ini akan memaksa responden untuk masuk dengan alamat gmail mereka untuk mengisi formulir.",
    closing_date: 'Tanggal Penutupan',
    schedule_form_status: 'Status Formulir Jadwal',
    schedule_quiz_status: 'Jadwalkan Status Kuis',
    schedule_opening_date_and_time: "Jadwalkan Tanggal dan Waktu Pembukaan",
    schedule_closing_date_and_time: "Jadwal Tanggal dan Jam Penutupan",
    schedule_opening_date_and_time_description: "Aktifkan formulir pada tanggal dan waktu yang disebutkan",
    schedule_opening_date_and_time_description_quiz: "Aktifkan kuis pada tanggal dan waktu yang disebutkan",
    schedule_closing_date_and_time_description: "Nonaktifkan formulir pada tanggal dan waktu yang disebutkan",
    schedule_closing_date_and_time_description_quiz: "Nonaktifkan kuis pada tanggal dan waktu yang disebutkan",
    upgrade: "Meningkatkan",
    gold_plan: "Rencana Emas",
    view_plans: "Lihat rencana",
    premium_plans: "Paket premium",
    plan_duration: "/ Bulan",
    plan_logic_jump: "Lompatan logika",
    plan_remove_ads: "Hilangkan iklan",
    cancel_subscription: "Batalkan Berlangganan",
    quiz_has_answer_edit_message: "Kuis ini telah dikirimkan dan jawaban telah dikumpulkan. Mengedit kuis dapat mempengaruhi jawaban sebelumnya.",
    quiz_published_alert_edit_message: "Kuis ini telah dipublikasikan, Jika Anda mengedit kuis ini tidak akan dipublikasikan.",
    quiz_submit_after_edit_message: "Mengedit Kuis dapat mempengaruhi Nilai, Persentase dan memerlukan evaluasi ulang atas pertanyaan-pertanyaan tertentu.",
    title_activity_privacy_policy: "Kebijakan PrivasiAktivitas",
    action_blocked_message: "Tindakan diblokir karena jawaban yang ada untuk kuis ini. Mengizinkan tindakan ini mungkin memengaruhi jawaban sebelumnya.",
    email_addresses: "Alamat email",
    confirmation_message: "Pesan konfirmasi",
    confirmation_message_description: "Apakah Anda ingin menampilkan pesan konfirmasi setelah mengirimkan formulir?",
    confirmation_message_description_quiz: "Apakah Anda ingin menampilkan pesan konfirmasi setelah menjawab kuis?",
    default_custom_thank_message: "Terima kasih telah mengisi formulir ini.",
    default_custom_thank_message_quiz: "Terima kasih telah menyelesaikan kuis ini.",
    upgrade_your_plan: "Tingkatkan rencanamu!",
    upgrade_your_plan_1: "Tingkatkan Paket Anda",
    upgrade_your_plan_content: "Mohon tingkatkan versi premium untuk mengakses fitur ini dan menambah ukuran penyimpanan, dll",
    alert_icon: "Ikon Peringatan",
    usage_details: "Rincian Penggunaan",
    current_plan: "Rencana saat ini",
    total_submission: "Jumlah Penyerahan",
    image_attachments: "Lampiran Gambar",
    custom_themes: "Tema Khusus",
    free_limit_reached: "Kamu telah mencapai batas bebas—",
    payment_done_but_server_error: "Pembayaran Anda telah berhasil dipotong. Silakan buka aplikasi setelah beberapa waktu agar kami dapat memverifikasi pembayaran Anda.",
    response_quota_reached: "Anda telah mencapai batas gratis, silakan tingkatkan paket Anda untuk mengakses semua tanggapan.",
    answers_quota_reached: "Anda telah mencapai batas gratis, silakan tingkatkan paket Anda untuk mengakses semua jawaban.",
    logic_jump_enabled: "Lompatan logika diaktifkan.",
    logic_jump_disabled: "Lompatan logika dinonaktifkan.",
    cancel_subscription_message: "Apakah Anda yakin ingin membatalkan langganan Anda? Membatalkan langganan akan mengakibatkan hilangnya akses ke semua fitur premium.",
    plan_expired: "Paket Anda telah habis masa berlakunya. Silakan tingkatkan rencana Anda untuk menggunakan draf ini.",
    plan_expired_edit_form: "Paket Anda telah habis masa berlakunya. Silakan tingkatkan rencana Anda untuk mengedit formulir ini",
    plan_expired_edit_quiz: "Paket Anda telah habis masa berlakunya. Silakan tingkatkan rencana Anda untuk mengedit kuis ini",
    plan_expired_duplicate_form: "Paket Anda telah habis masa berlakunya. Mohon tingkatkan rencana Anda untuk menduplikasi formulir ini",
    plan_expired_duplicate_quiz: "Paket Anda telah habis masa berlakunya. Silakan tingkatkan rencana Anda untuk menduplikasi kuis ini",
    premium_features: "Fitur Unggulan",
    plan_file_upload: "Gambar tema Kustom Tanpa Batas",
    plan_custom_theme: "Gambar tema Kustom Tanpa Batas",
    plan_call_support: "Prioritas Tinggi Aktif - Dukungan Panggilan",
    plan_email_support: "Dukungan Email Prioritas Tinggi",
    plan_custom_domain: "Domain Khusus",
    subscribe: "Langganan",
    plan_remove_branding: "Hapus Merek",
    plan_on_call_support: "Dukungan Panggilan",
    plan_image_attachment: "Lampiran Gambar Tidak Terbatas",
    plan_custom_thank_you: "Halaman Terima Kasih yang Dapat Disesuaikan",
    plan_allowed_responses: "Jumlah Respons yang Diizinkan 50.000",
    trusted_by_number_of_user: "Dipercaya oleh lebih dari 5 juta pengguna",
    email_support: "Dukungan Email",
    call_support: "Hubungi Dukungan",
    keyboard_shortcuts: "Pintasan Papan Ketik",
    premium_limit_reached: "Anda telah mencapai batas paket. Silakan tingkatkan paket Anda untuk mengakses semua fitur premium.",
    premium_limit_reached_mobile: "Anda telah mencapai batas paket.",
    unlimited: "Tak terbatas",
    expired_on_next_renewal: "Masa berlakunya akan habis pada tanggal perpanjangan berikutnya",
    upgrade_plan: "Paket Peningkatan",
    downgrade_plan: "Paket Penurunan Versi",
    file_upload_questions: "Pertanyaan Unggah File",
    plan_response_edit: "Sunting Tanggapan",
    cant_process: "Tidak Dapat Memproses",
    you_have_subscribed_through_android: "Anda telah berlangganan melalui aplikasi Android kami. Silakan gunakan aplikasi SurveyHeart untuk pembaruan terkait pembayaran apa pun.",
    pay_now: "Bayar sekarang",
    update_payment_details: "Perbarui Detail Pembayaran",
    limit_reached_alert_message: "Anda telah mencapai batas gratis, silakan tingkatkan paket Anda untuk mengakses semua tanggapan.",
    limit_reached_alert_message_quiz: "Anda telah mencapai batas gratis, silakan tingkatkan paket Anda untuk mengakses semua jawaban.",
    limit_reached_UI_message: "Tanggapan tidak akan tersedia untuk paket Anda saat ini, Harap tingkatkan paket Anda untuk melihat semua tanggapan.",
    limit_reached_UI_message_quiz: "Jawaban tidak akan tersedia untuk paket Anda saat ini. Harap tingkatkan paket Anda untuk melihat semua jawaban.",
    owner_is_not_premium_user: "Pemiliknya bukan pengguna premium",
    cancel_subscription_description: "Apakah Anda yakin ingin membatalkan langganan Anda? Pembatalan akan mengakibatkan hilangnya akses ke semua fitur premium.",
    bronze_storage_description: "Dapatkan penyimpanan 25 GB untuk manajemen data yang mudah.",
    silver_storage_description: "Dapatkan penyimpanan 50 GB untuk manajemen data yang mudah.",
    gold_storage_description: "Dapatkan penyimpanan 150 GB untuk manajemen data yang mudah.",
    bronze_responses_description: "Gunakan 35.000 respons untuk mendapatkan wawasan survei yang hebat.",
    silver_responses_description: "Gunakan 75.000 respons untuk mendapatkan wawasan survei yang hebat.",
    gold_responses_description: "Gunakan 1.50.000 respons untuk mendapatkan wawasan survei yang hebat.",
    bronze_image_description: "Tingkatkan survei Anda dengan hingga 200 gambar untuk daya tarik visual.",
    silver_image_description: "Tingkatkan survei Anda dengan hingga 500 gambar untuk daya tarik visual.",
    gold_image_description: "Tingkatkan survei Anda dengan gambar tak terbatas untuk daya tarik visual.",
    bronze_theme_description: "Tambahkan 200 gambar khusus untuk mempersonalisasi survei bagi merek Anda.",
    silver_theme_description: "Tambahkan 500 gambar khusus untuk mempersonalisasi survei bagi merek Anda.",
    gold_theme_description: "Tambahkan gambar khusus tanpa batas untuk mempersonalisasi survei bagi merek Anda.",
    custom_theme_image: "Gambar Tema Kustom",
    file_upload_description: "Integrasikan pertanyaan unggahan file tanpa batas dengan lancar.",
    logic_jump_description: "Gunakan lompatan logika untuk menyesuaikan alur survei bagi pengalaman setiap responden",
    thank_you_message_description: "Personalisasi halaman Terima Kasih untuk membuat responden terkesan.",
    response_edit_description: "Persempit respons dengan mudah menggunakan fitur Edit Respons.",
    remove_branding_description: "Tingkatkan kehadiran merek Anda dengan menghapus merek SurveyHeart.",
    bronze_remove_ads_description: "Bebas iklan dengan Paket Perunggu kami dan fokus pada survei Anda.",
    silver_remove_ads_description: "Bebas iklan dengan Paket Silver kami dan fokus pada survei Anda.",
    gold_remove_ads_description: "Bebas iklan dengan Paket Gold kami dan fokus pada survei Anda.",
    bronze_email_support_description: "Akses dukungan email untuk prioritas rendah, memastikan bantuan tepat waktu.",
    silver_email_support_description: "Akses dukungan email untuk prioritas sedang, memastikan bantuan tepat waktu.",
    gold_email_support_description: "Akses dukungan email untuk prioritas tinggi, memastikan bantuan tepat waktu.",
    bronze_email_support_title: "Dukungan Email Prioritas Rendah",
    silver_email_support_title: "Dukungan Email Prioritas Sedang",
    gold_email_support_title: "Dukungan Email Prioritas Tinggi",
    update_your_payment_details_mobile: "Harap perbarui detail pembayaran Anda.",
    update_your_payment_details_pc: "Kami menghadapi beberapa masalah dengan pembaruan akun ini. Harap perbarui detail pembayaran Anda.",
    card_details_updated_successfully: "Detail Kartu Berhasil Diperbarui",
    card_details_updated_successfully_description: "Detail kartu Anda telah berhasil diperbarui. Kini Anda dapat terus menggunakan informasi pembayaran yang telah diperbarui.",
    payment_successful: "Pembayaran Berhasil",
    payment_successful_description: "Pembayaran Anda berhasil. Kini Anda berlangganan paket premium SurveyHeart. Dapatkan kenyamanan dan nikmati semua fitur menakjubkan yang akan Anda dapatkan.",
    payment_failed: "Pembayaran Gagal!",
    payment_failed_description: "Sayangnya. Kami tidak dapat memproses langganan Anda ke paket premium SurveyHeart. Harap coba lagi.",
    note_for_five_rupees: "Catatan: Ada biaya sebesar Rs. 5 untuk memperbarui detail kartu Anda, yang akan dikembalikan dalam waktu 3 hingga 4 hari kerja ke akun yang sama.",
    logic: "Logika",
    re_generate_code: "Buat Ulang Kode",
    plan_change_restrictions: "Pembatasan Perubahan Paket",
    plan_change_restriction_desc_1: "Anda tidak dapat mengubah paket menggunakan UPI.",
    plan_change_restriction_desc_2: "Beralih ke kartu untuk peningkatan dan penurunan paket, atau batalkan dan berlangganan lagi ke paket yang Anda inginkan.",
    update_card_details: "Perbarui Rincian Kartu",
    features_and_plans: "Fitur dan Paket",
    cancel_plan: "Batalkan Paket",
    cancel_plan_desc: "Anda telah membatalkan paket. Anda masih akan memiliki akses ke fitur berbayar hingga tanggal pembaruan.",
    explore_text: "Jelajahi dan Dapatkan Fitur Luar Biasa di Salah Satu Paket Langganan Premium Kami Hari Ini",
    cant_edit_this_form: "Tidak Dapat Mengedit Formulir Ini",
    cant_edit_this_quiz: "Tidak Dapat Mengedit Kuis Ini",
    cant_duplicate_this_form: "Tidak Dapat Menggandakan Formulir Ini",
    cant_duplicate_this_quiz: "Tidak Dapat Menduplikasi Kuis Ini",
    kindly_upgrade_the_premium: "Mohon tingkatkan versi premium untuk mengakses fitur ini.",
    check_box: 'Kotak Centang',
    continue_to_next_question: "Lanjutkan ke Pertanyaan Berikutnya",
    add_logic: "Tambah Logika",
    submit_form: "Kirim Formulir",
    save_logic: "Simpan Logika",
    remove_logic: "Hapus Logika",
    responses_add_ons: "Pengaya Tanggapan",
    additional_submissions: "Pengajuan Tambahan",
    validity: "Keabsahan",
    up_to_your_current_plan: "Hingga paket Anda saat ini",
    responses_add_ons_description: "Jumlah respons ini akan ditambahkan ke paket Anda saat ini.",
    redirect_url_title: "Arahkan ke tautan yang diinginkan",
    redirect_url_description: "Apakah Anda ingin mengarahkan pengguna Anda ke halaman web eksternal?",
    enter_weblink: "Masukkan tautan web",
    get: "Mendapatkan",
    jump_to: "Langsung ke",
    action_cannot_be_performed: "Tindakan tidak dapat dilakukan",
    upgrade_plan_desc1: "Apakah Anda ingin meningkatkan paket Anda ke",
    downgrade_plan_desc1: "Apakah Anda ingin menurunkan paket Anda ke",
    downgrade_plan_desc2: "Perubahan ini akan berlaku pada tanggal pembaruan berikutnya.",
    upgraded_plan: "Paket Ditingkatkan",
    downgraded_plan: "Paket Diturunkan",
    upgraded_plan_desc: "Anda telah meningkatkan paket Anda. Perubahan ini akan berlaku pada tanggal pembaruan berikutnya.",
    downgraded_plan_desc: "Anda telah menurunkan paket Anda. Perubahan ini akan berlaku pada tanggal pembaruan berikutnya.",
    buy_responses: "Beli Respons",
    passcode: "Kode Sandi",
    share_link_with_passcode: "Bagikan tautan dengan Kode Sandi"
}