import React from "react"
import {
    downloadAwsFilesByForms,
    downloadAwsFilesByQuestions,
    downloadAwsFilesByRespondent,
    TYPE
} from "../../../shared/utils/sash-v2-api";
import pdf from '../../../../public/images/export_pdf.svg'
import csv from '../../../../public/images/export_csv.svg'
import xlsx from '../../../../public/images/export_xlsx.svg'
import deleteIcon from '../../../../public/images/delete.svg'
import backArrow from '../../../../public/images/back_grey.svg'
import folderImage from "../../../../public/images/folder.svg";
import imageIcon from "../../../../public/images/image_icon.svg";
import downloadIcon from '../../../../public/images/download_blue.svg'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import rightPathArrow from "../../../../public/images/right_path_arrow.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {STORAGE_LEVEL, STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import {
    downloadAwsFilesByQuizQuestions,
    downloadAwsFilesByQuizRespondent, downloadAwsFilesByQuizzes
} from "../../../shared/utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import LimitReachedUI from "../../responses/components/LimitReachedUI";
import {PremiumJSON} from "../../../shared/utils/helper";

const StorageManagementDetailsComponent = (props) => {
    const {t} = useTranslation()
    const showForms = useSelector(state => state.storageManagementReducer.isForms)
    const selectAll = useSelector(state => state.storageManagementReducer.selectAll)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const currentList = useSelector(state => state.storageManagementReducer.currentList)
    const storageLevel = useSelector(state => state.storageManagementReducer.storageLevel)
    const totalUserResponseCount = useSelector(state => state.appReducer.totalUserResponseCount)
    const selectedFilesArray = useSelector(state => state.storageManagementReducer.selectedFilesArray)
    const dispatch = useDispatch()

    const getFileTypeImage = (fileType) => {
        if (fileType) {
            switch (fileType) {
                case (TYPE.PDF):
                    return pdf
                case (TYPE.CSV):
                    return csv
                case (TYPE.EXCEL):
                    return xlsx
                default:
                    return imageIcon
            }
        } else return folderImage
    }

    const handleDeleteButtonClick = () => {
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP, payload: true})
    }

    const handleDownloadButtonClick = () => {
        const successFunction = (res) => {
            if (res) {
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/zip'}))
                const link = document.createElement('a')
                link.href = url
                const fileName = 'myFile.zip'
                link.id = fileName;
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click();
                document.getElementById(fileName).remove()
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
            }
        }
        if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.FILE) {
            if (showForms) {
                downloadAwsFilesByRespondent(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                downloadAwsFilesByQuizRespondent(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        } else if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.QUESTION) {
            if (showForms) {
                downloadAwsFilesByQuestions(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                downloadAwsFilesByQuizQuestions(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        } else if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.FORQ) {
            if (showForms) {
                downloadAwsFilesByForms(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                downloadAwsFilesByQuizzes(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        }
    }


    return (
        <div className={"pop-up-right-container"}>
            {props.headerText ? <div className={"right-header"}>
                <span>
                    {props.headerText.showBackArrow ? <div className={"back-arrow"}
                                                           onClick={() => props.handleBackArrowClick(!!props.headerText.secondary)}>
                        <img src={backArrow} alt={"back"}/>
                    </div> : null}
                    <span
                        style={props.headerText.secondary ? {color: "#4D4D4D"} : null}>{props.headerText.primary?.slice(0, (props.headerText.secondary ? 3 : 30)) + (props.headerText.primary?.length > (props.headerText.secondary ? 3 : 30) ? "..." : "")}</span>
                    {props.headerText.secondary ? <img src={rightPathArrow} alt={"right-arrow"}/> : null}
                    {props.headerText.secondary ? (props.headerText.secondary.slice(0, 30) + (props.headerText.secondary?.length > 30 ? "..." : "")) : ""}
                </span>
                {!isMobileOnly ? <div className={"select-all"}>
                    <span>{t(translationKeys.select_all)}</span>
                    <MaterialCheckBox removeWidth={true}
                                      isChecked={selectedFilesArray?.length === currentList?.length && selectedFilesArray?.length > 0}
                                      onChange={props.handleSelectAllClick}/>
                </div> : null}
            </div> : null}
            <div className={"items-list"} style={selectedFilesArray?.length && !isMobileOnly ? {height: "79%"} : null}>
                {currentList?.length ? React.Children.toArray(currentList?.map((item) => {
                    return (
                        <div className={"item-row"}
                             onClick={() => props.handleListRowClick(item.id, item.questionIndex + 1, item.title)}>
                            <img src={getFileTypeImage(item.fileType)} alt={"file-type"}/>
                            <div style={{
                                display: "flex",
                                flexFlow: "column",
                                margin: "0 10px",
                                flexGrow: "1"
                            }}>
                                <span>{item.title?.slice(0, (isMobileOnly ? 30 : 50)) + (item?.title?.length > (isMobileOnly ? 30 : 50) ? "..." : "")}</span>
                                <p>{item.description}</p>
                            </div>
                            <div onClick={(e) => e.stopPropagation()}>
                                <MaterialCheckBox removeWidth={true}
                                                  isChecked={selectAll}
                                                  onChange={(checked) => props.handleRowCheckBoxClick(item.title, item.questionIndex, checked)}/>
                            </div>
                        </div>
                    )
                })) : totalUserResponseCount >= (PremiumJSON[subscriptionData?.subscription_plan]?.response_limit.access + subscriptionData?.response_addon_count) ?
                    <LimitReachedUI width={"-webkit-fill-available"}
                                    isQuiz={!showForms}
                                    height={isMobileOnly ? "90%" : "-webkit-fill-available"}
                                    background={"transparent"}/> :
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '-webkit-fill-available',
                        height: '-webkit-fill-available'
                    }}>
                        <p>No data Available</p>
                    </div>}
            </div>
            {selectedFilesArray?.length ? <div className={"bottom-buttons"}>
                <div className='footer-button' style={{borderRight: "1px solid #EBEBEB"}}
                     onClick={handleDownloadButtonClick}>
                    <img src={downloadIcon} alt={"download"}/>
                    <label className='modal-dialog-footer-text'>
                        {selectedFilesArray?.length === currentList?.length ? t(translationKeys.download_all) : t(translationKeys.download)}
                    </label>
                </div>
                <div className='footer-button' style={{borderLeft: "1px solid #EBEBEB"}}
                     onClick={handleDeleteButtonClick}>
                    <img src={deleteIcon} alt={"delete"}/>
                    <label className='modal-dialog-footer-text'>
                        {selectedFilesArray?.length === currentList?.length ? t(translationKeys.delete_all) : t(translationKeys.delete)}
                    </label>
                </div>
            </div> : null}
        </div>
    )
}

export default StorageManagementDetailsComponent
