import React, {useEffect, useState} from 'react'
import StorageBandComponent from "../../../app/dashboard/components/StorageBandComponent"
import faqVideos from "../../../../public/images/faq_videos.svg"
import Feedback from "../../../../public/images/feedback_blue.svg"
import FeatureSpotlight from "../../../../public/images/feature_spotlight_blue.svg"
import PrivacyPolicy from "../../../../public/images/policy_blue.svg"
import TranslateIcon from "../../../../public/images/icons/translate_icon.svg"
import PremiumIcon from "../../../../public/images/premium_crown.svg"
import PremiumIconBlue from "../../../../public/images/premium_crown_blue.svg"
import UsageIcon from "../../../../public/images/usage.svg"
import MailSupportIcon from "../../../../public/images/mail_support(blue).svg"
import CallSupportIcon from "../../../../public/images/call_support(blue).svg"
import ResponseAddOnIcon from "../../../../public/images/response-add-on(blue).svg"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import LanguageSelectionPopup from "./LanguageSelectionPopup";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux"
import {FEATURE_SPOTLIGHT_ACTION_TYPE} from "../../reducers/featureSpotlightReducer"
import CreateNewBanner from "./CreateNewBannerMobile";
import {STORAGE_LEVEL, STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {formatBytes, PremiumJSON} from "../../../shared/utils/helper";
import {MAX_USER_FILE_STORAGE} from "../../../shared/utils/constants";
import {getTotalStorageUsed as getFormTotalStorageUsed} from "../../../shared/utils/sash-v2-api";
import {getTotalStorageUsed as getQuizTotalStorageUsed} from "../../../shared/utils/quiz-api-services";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {APP_VERSION} from "../../utils/constants";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const MobileMoreContainer = () => {
    const dispatch = useDispatch()
    const [totalQuizStorageUsed, setTotalQuizStorageUsed] = useState()
    const [totalFormsStorageUsed, setTotalFormsStorageUsed] = useState()
    const [localizationShow, setLocalizationShow] = useState(false);
    const zohoMailTicket = useSelector(state => state.appReducer.zohoMailTicket)
    const zohoCallTicket = useSelector(state => state.appReducer.zohoCallTicket)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showStorageForqPopUp = useSelector(state => state.storageManagementReducer.showStorageForqPopUp)
    const totalStorage = PremiumJSON[subscriptionData?.subscription_plan]?.storage

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: '100%',
        width: '100%',
        zIndex: '10',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }

    useEffect(() => {
        const successFunction = (res, formAPI) => {
            if (res) {
                if (formAPI) {
                    setTotalFormsStorageUsed(formatBytes(res.result, res.result > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB"))
                } else {
                    setTotalQuizStorageUsed(formatBytes(res.result, res.result > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB"))
                }
            }
        }
        getFormTotalStorageUsed((res) => successFunction(res, true), (err) => console.log(err))
        getQuizTotalStorageUsed((res) => successFunction(res, false), (err) => console.log(err))
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
    }, [])

    const handleFormsCardClick = () => {
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.storage})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_IS_FORMS, payload: true})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.FORQ})
    }

    const handleQuizzesCardClick = () => {
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.storage})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_IS_FORMS, payload: false})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.FORQ})
    }

    return (
        <>
            <div id={'mobile-more-file-storage'} className='file-storage'>
                <StorageBandComponent totalStorage={formatBytes(totalStorage)} showManageButton={true}/>
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Premium', 'pop-up', 'open');
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
            }}>
                <div className="more-row-img">
                    <img alt={'Premium Icon'} src={PremiumIconBlue}/>
                </div>
                <span>{t(translationKeys.premium_plans)}</span>
                <span style={{
                    display: "flex",
                    justifyContent: "end",
                    flexGrow: "1"
                }}>{subscriptionData?.subscription_plan === "premium-on-hold" ? "HOLD" : `${subscriptionData?.subscription_plan.split("-")[0].toUpperCase()}`}</span>
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Responses Add On', 'pop-up', 'open');
                if (PremiumJSON[subscriptionData?.subscription_plan]?.responses_add_ons) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.response_add_on})
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
            }}>
                <div className="more-row-img">
                    <img alt={'Responses Add-ons'} src={ResponseAddOnIcon}/>
                </div>
                <span>{t(translationKeys.responses_add_ons)}</span>
                {PremiumJSON[subscriptionData?.subscription_plan]?.responses_add_ons ? null :
                    <img style={{margin: "0 0 0 10px"}} src={PremiumIcon} alt={"premium"}/>}
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Usage', 'pop-up', 'open');
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.usage})
            }}>
                <div className="more-row-img">
                    <img alt={'Usage Icon'} src={UsageIcon}/>
                </div>
                <span>{t(translationKeys.usage_details)}</span>
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Mail Support', 'pop-up', 'open');
                if (PremiumJSON[subscriptionData?.subscription_plan]?.email_support_rank) {
                    if (zohoMailTicket) {
                        dispatch({
                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET,
                            payload: zohoMailTicket.ticketNumber
                        })
                    } else {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.mail_support})
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MAIL_SUPPORT_POPUP, payload: true})
                    }
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
            }}>
                <div className="more-row-img">
                    <img alt={'Mail Icon'} src={MailSupportIcon}/>
                </div>
                <span>{t(translationKeys.email_support)}</span>
                {PremiumJSON[subscriptionData?.subscription_plan]?.email_support_rank ? null :
                    <img style={{margin: "0 0 0 10px"}} src={PremiumIcon} alt={"premium"}/>}
                {zohoMailTicket ?
                    <span style={{
                        display: "flex",
                        justifyContent: "end",
                        flexGrow: "1",
                        color: "#004ba0"
                    }}><p style={{background: "#F5F5F5", margin: "0", padding: "3px 10px", borderRadius: "10px"}}>In Progress</p></span> : null}
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Call Support', 'pop-up', 'open');
                if (PremiumJSON[subscriptionData?.subscription_plan]?.on_call_support) {
                    if (zohoCallTicket) {
                        dispatch({
                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET,
                            payload: zohoCallTicket.ticketNumber
                        })
                    } else {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.mail_support})
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_CALL_SUPPORT_POPUP, payload: true})
                    }
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
            }}>
                <div className="more-row-img">
                    <img alt={'Call Icon'} src={CallSupportIcon}/>
                </div>
                <span>{t(translationKeys.call_support)}</span>
                {PremiumJSON[subscriptionData?.subscription_plan]?.on_call_support ? null :
                    <img style={{margin: "0 0 0 10px"}} src={PremiumIcon} alt={"premium"}/>}
                {zohoCallTicket ?
                    <span style={{
                        display: "flex",
                        justifyContent: "end",
                        flexGrow: "1",
                        color: "#004ba0"
                    }}><p style={{background: "#F5F5F5", margin: "0", padding: "3px 10px", borderRadius: "10px"}}>In Progress</p></span> : null}
            </div>
            <div className="more-row" onClick={() => {
                registerGAEvent('Languages', 'pop-up', 'open');
                setLocalizationShow(true)
            }}>
                <div className="more-row-img">
                    <img alt={'Translate Icon'} src={TranslateIcon}/>
                </div>
                <span>{t(translationKeys.languages)}</span>
            </div>
            {
                localizationShow ? <LanguageSelectionPopup setLocalizationShow={setLocalizationShow}/> : null
            }
            <div className="more-row" onClick={() => window.open('https://youtube.com/@surveyheart')}>
                <div className="more-row-img">
                    <img alt={'FAQ'} src={faqVideos}/>
                </div>
                <span>{t(translationKeys.faq_videos)}</span>
            </div>
            <div id={'mobile-more-feature-spotlight'} className="more-row"
                 onClick={() => {
                     dispatch({
                         type: FEATURE_SPOTLIGHT_ACTION_TYPE.TOGGLE_VISIBILITY
                     })
                 }}>
                <div className="more-row-img">
                    <img alt={'Feature Spotlight'} src={FeatureSpotlight}/>
                </div>
                <span>{t(translationKeys.feature_spotlight)}</span>
            </div>
            <div id={'mobile-more-feedback'} className="more-row"
                 onClick={() => window.open('/form/5e2ad7279ddceb28451312d4')}>
                <div className="more-row-img">
                    <img alt={'Feedback'} src={Feedback}/>
                </div>
                <span>{t(translationKeys.feedback)}</span>
            </div>
            <div id={'mobile-more-privacy-policy'} className="more-row" onClick={() => window.open('/privacypolicy')}>
                <div className="more-row-img">
                    <img alt={'Privacy Policy'} src={PrivacyPolicy}/>
                </div>
                <div>{t(translationKeys.privacy_policy)}</div>
            </div>
            {showStorageForqPopUp ?
                <div style={popUpStyle} onClick={() => {
                    dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_FORQ_POP_UP, payload: false})
                }}>
                    <CreateNewBanner formsDesc={totalFormsStorageUsed}
                                     quizzesDesc={totalQuizStorageUsed}
                                     handleFormsCardClick={handleFormsCardClick}
                                     handleQuizzesCardClick={handleQuizzesCardClick}
                                     setShowCreateNewPopUp={(value) => {
                                         dispatch({
                                             type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_FORQ_POP_UP,
                                             payload: value
                                         })
                                     }}/>
                </div> : null}
            {window.location.host === "surveyheart.com" ? null :
                <div id={'mobile-more-version'} className="more-row" onClick={() => {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MORE_POPUP, payload: false})
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_VERSION, payload: true})
                }}>
                    <div className="more-row-img">
                        <img alt={'Version'} src={"../../../../images/surveyheart_icon_small.svg"}
                             style={{width: "40px"}}/>
                    </div>
                    <div>{APP_VERSION}</div>
                </div>}
        </>
    )
}

export default MobileMoreContainer
