export const translationSpanish = {
    all: 'Todos',
    ago: "atrás",
    now: "Ahora",
    start: "Iniciar",
    account_blocked: "Cuenta bloqueada",
    account_blocked_alert: "Esta cuenta ha sido bloqueada por violar nuestros términos y condiciones",
    sign_out: "Cerrar sesión",
    short_answer: "Respuesta corta",
    long_answer: "Respuesta larga",
    email: "Correo electrónico",
    number: "Número",
    file: "Archivo",
    files: "Archivos",
    faq_videos: "Vídeos de preguntas frecuentes",
    file_upload: "Subir archivo",
    question_error: "No se puede agregar la pregunta",
    storage: "Almacenamiento",
    form: "Formar",
    quiz: "Examen",
    fup_desc: "¡Ahora puede permitir que los encuestados de sus formularios y cuestionarios carguen archivos!",
    new_fup_feature_title: "¡Nueva función de carga de archivos!",
    multiple_choice: "pregunta de opción múltiple",
    date_text: "Fecha",
    time_text: "Hora",
    uploading: "Subiendo...",
    add_question: "Añadir pregunta",
    add_question_alert: "Por favor, añade al menos una pregunta con todos los elementos necesarios.",
    add_question_alert_quiz: "Por favor, agregue al menos una pregunta antes de enviar la prueba.",
    ok: "Aceptar",
    title: "Título",
    title_required: "Se requiere un título",
    form_name_alert: "Por favor, asígnale un nombre al formulario antes de publicarlo.",
    quiz_name_alert: "Por favor nombre el cuestionario antes de publicarlo.",
    no_connection: "Sin conexión",
    check_connection: "Por favor, revisa tu conexión de red.",
    finish_incomplete_question: "Por favor, termina las preguntas incompletas en el formulario",
    draft: "Borrador",
    save_to_draft_alert: "¿Quieres guardar este formulario como borrador?",
    save_to_draft_quiz_alert: "¿Le gustaría guardar este cuestionario como borrador para evitar perder sus cambios?",
    yes: "Sí",
    no: "No",
    cancel: "Cancelar",
    live_preview: "Vista previa en vivo",
    themes: "Temas",
    tap_to_set_theme: "Toca aquí para configurar el tema para el formulario.",
    select_image: "Seleccionar imagen",
    created: "Creado",
    form_created_successfully: "Formulario creado correctamente",
    warning: "Advertencia",
    form_edit_alert: "Este formulario ya se ha publicado y es posible que se hayan recabado respuestas. Editar el formulario puede afectar las respuestas que ya se recabaron.",
    quiz_edit_alert: 'Este cuestionario ya se envió y se han recopilado las respuestas. La edición del cuestionario puede afectar las respuestas anteriores.',
    edit: "Editar",
    download_as: "Descargar",
    delete_form: "Eliminar formulario",
    delete_form_alert: "¿Quieres eliminar este formulario? Esta acción no se puede deshacer.",
    delete_quizzes_alert: "¿Quieres eliminar estos cuestionarios? Esta acción no se puede deshacer.",
    delete_forms_alert: "¿Quieres eliminar estos formularios? Esta acción no se puede deshacer.",
    delete_quiz_alert: "¿Quieres eliminar este cuestionario? Esta acción no se puede deshacer.",
    delete_answers_alert: "¿Quieres borrar estas respuestas?",
    delete_answer_alert: "¿Quieres eliminar esta respuesta?",
    delete: "Eliminar",
    delete_all: "Eliminar todos",
    section: "Sección",
    worst: "Peor",
    server_error_alert: "No se pudo encontrar el servidor. ¡Por favor, inténtalo de nuevo más tarde!",
    signed_in_as: "Sesión iniciada como",
    signing_out: "Cerrando sesión…",
    create_form: "Crear formulario",
    create_blank_form: "Crear formulario en blanco",
    create_blank_quiz: "Crear cuestionario en blanco",
    close: "Cerrar",
    response_collection_exit_alert: "¿Quieres salir de la recabación de respuestas?",
    form_builder: "Generador de formularios",
    form_builder_promo: "Vista previa instantánea, plantillas y temas para tus encuestas",
    results: "Resultados",
    result_feature_promo: "Analiza y exporta los resultados de tus encuestas",
    notification: "Notificación",
    delete_option_alert: "¿Quieres eliminar esta opción?  [Nota: esta acción no se puede deshacer]",
    question_type: "Tipo de pregunta",
    change_question_type_alert: "Toca aquí para cambiar el tipo de pregunta",
    preview_not_available: "Vista previa no disponible",
    delete_question: "Eliminar pregunta",
    delete_response_collected_question_alert: "Eliminar esta pregunta seguramente afectará las respuestas recabadas.\n\n¿Seguro que quieres eliminar esta pregunta?",
    delete_question_alert: "¿Seguro que quieres eliminar esta pregunta?",
    options: "Opciones",
    option: 'Opción',
    no_answer: "Sin respuesta",
    no_response: "Ninguna respuesta",
    responses: "respuestas",
    response: "respuesta",
    serial_number: "S.NO",
    submit_time: "Hora de envío",
    general_form_type_description: "Crear nuevo formulario / encuesta",
    quiz_type_description: "Crear nuevo cuestionario",
    sheet_disconnected: 'Hoja de Google desconectada',
    sheet_not_connected: 'Hoja no conectada',
    sheet_not_connected_desc: 'Póngase en contacto con el propietario para conectar la hoja de Google.',
    export_success: "Exportado correctamente",
    answer: "Responder",
    answer_with_colan: "Responder :",
    others: "Otras",
    delete_response: "Eliminar respuesta",
    deleted: "Eliminada",
    my_themes: "Mis temas",
    limit_reached: "Límite alcanzado",
    limit_reached_description: "Has alcanzado el límite de imágenes subidas.",
    modified: "Modificado",
    delete_image: "Eliminar imagen",
    delete_image_alert: "¿Seguro que quieres eliminar esta imagen?",
    delete_theme_image_desc: "¿Quieres eliminar esta imagen? Si continúa, esta imagen no estará disponible para ningún formulario o cuestionario que la esté utilizando. Esta acción no se puede deshacer.",
    section_title: "Título de la sección",
    section_description: "Descripción de la sección",
    show_scale: 'Mostrar escala',
    show_options: "Mostrar opciones",
    options_required: "Opciones requeridas",
    link_copied: "Enlace copiado",
    app_not_found: "Aplicación no encontrada",
    default_text: "Predeterminado",
    dark_mode: "Modo oscuro",
    done: "Hecho",
    upload_image: "Subir imagen",
    question: "Pregunta",
    required: "Requerido",
    share: "Compartir",
    form_link: "Enlace de formulario",
    copy_link: "Copiar enlace",
    view_form: "Ver formulario",
    menu: "Menú",
    view_all: "Ver todo",
    settings: "Ajustes",
    submit: "Entregar",
    change_theme: "Cambiar tema",
    tap_here_to_choose_theme: "Toca aquí para elegir el tema",
    show_logo: "Mostrar logo",
    show_logo_alert: "¿Quieres mostrar el logo en el formulario?",
    welcome_page: "Página de bienvenida",
    show_welcome_page_alert: "¿Quieres mostrar la página de bienvenida?",
    start_button: "Botón de inicio",
    allow_multiple_responses: "Permitir múltiples respuestas",
    allow_more_response_alert: "¿Quieres permitir que el usuario responda más de una vez?",
    allow_more_quiz_attempt_alert: "¿Quieres permitir que el usuario intente más de una vez?",
    show_summary: "Mostrar resumen",
    show_results: "Mostrar resultados",
    allow_responders_view_summary_alert: "¿Quieres permitir que los encuestados vean el resumen de todas las respuestas después de enviar?",
    allow_result_view_quiz_alert: "¿Desea permitir que el usuario vea los resultados?",
    show_question_number: "Mostrar número de pregunta",
    show_question_number_in_form: "¿Quieres mostrar el número de pregunta en el formulario?",
    show_question_number_in_quiz: "¿Quieres mostrar el número de pregunta en el cuestionario?",
    shuffle_questions: "Revolver preguntas",
    shuffle_questions_alert: "¿Quieres revolver las preguntas del formulario?",
    shuffle_quiz_questions_alert: "¿Quieres mezclar las preguntas del cuestionario?",
    description: "Descripción",
    builder: "Generador",
    preview: "Vista previa",
    form_status: "Estado formulario",
    active: "Activo",
    inactive: "Inactivo",
    import_questions: 'Preguntas de importación',
    search_by_question_title_from_forms: 'Buscar por título de pregunta en formularios existentes',
    search_by_question_title_from_quizzes: 'Buscar por título de pregunta en cuestionarios existentes',
    view_responses: "Ver respuestas",
    edit_form: "Editar formulario",
    duplicate_form: "Duplicar formulario",
    drafts: "Borradores",
    no_form_available: "No hay formulario disponibles!",
    notifications: "Notificaciones",
    feedback: "Comentarios",
    privacy_policy: "Política de privacidad",
    open: 'Abierto',
    open_individual_responses: "Abrir respuestas individuales",
    bar_chart: "GRÁFICO DE BARRAS",
    pie_chart: "GRÁFICO CIRCULAR",
    summary: "Resumen",
    invalidated_form_cannot_be_selected: "No se puede seleccionar un formulario invalidado.",
    invalidated_quiz_cannot_be_selected: "No se puede seleccionar un cuestionario invalidado.",
    individual: "Individual",
    search: "Buscar…",
    image: "Imagen",
    video: "Vídeo",
    website: "Sitio web",
    exit: "Salir",
    response_limit_reached: "Límite de respuesta alcanzado",
    response_limit_reached_alert: "Este formulario ha excedido el umbral permitido para los datos de respuesta; por lo tanto, evitamos que este formulario acepte más respuestas.\n\nSi realmente necesita continuar con la Encuesta existente, le sugerimos que la duplique y comparta el enlace.",
    unapproved_form: "Formulario no aprobado",
    unapproved_form_alert: "Este formulario utiliza palabras que pueden violar nuestras políticas.\n\nSi cree que este formulario ha sido bloqueado por error, abra el enlace del formulario a continuación y proporcione la información solicitada en el formulario.",
    choose_your_language: "Elige tu idioma",
    provide_explanation: "Proporcionar explicación",
    selected: "seleccionado",
    review_button: "revisión",
    not_specified: "No especificado",
    quiz_control: "Control de cuestionarios",
    allow_multiple_attempts: "Permitir múltiples intentos",
    user_info: "Información de usuario",
    name_s: "Nombre",
    duration: "Duración",
    set_duration_quiz_alert: "¿Desea establecer una duración para el cuestionario?",
    user_information_description: "¿Quieres obtener información de los participantes?",
    set_duration: "Establecer duración (horas: minutos)",
    minutes: "Minutos",
    hours: "Horas",
    minute: "Minuto",
    hour: "Hora",
    correct: "Correcto",
    wrong: "Incorrecto",
    skipped: "Omitido",
    score: "Puntuación",
    forms: "Formas",
    quizzes: "Cuestionarios",
    more: "Más",
    quiz_status: "Estado cuestionario",
    view_answers: "Ver respuestas",
    view_quiz: "Ver cuestionario",
    duplicate_quiz: "Prueba duplicada",
    no_quiz_available: "No hay cuestionarios disponibles!",
    quiz_link: "Enlace de prueba",
    unapproved_quiz: "Prueba no aprobada",
    unapproved_quiz_alert: "Este cuestionario utiliza palabras que pueden violar nuestras políticas. Si cree que este cuestionario se bloqueó por error, abra el enlace del formulario a continuación y proporcione la información solicitada en el formulario.",
    do_you_want_to_show_all_correct_answers_to_users: "¿Quieres mostrar la respuesta correcta a los usuarios?",
    results_publish_date: "Fecha de publicación de resultados",
    do_you_want_to_set_date_to_publish_results: "¿Quieres establecer una fecha para publicar resultados?",
    disconnect: 'DESCONECTAR',
    quiz_result: "Resultado de la prueba",
    enter_answer: "Responder",
    manage: "Gestionar",
    manage_storage: 'Administrar almacenamiento',
    select_all: 'Seleccionar todo',
    shuffle_options: 'Opciones aleatorias',
    shuffle_options_enabled: 'Opciones aleatorias habilitadas',
    shuffle_options_disabled: 'Opciones de reproducción aleatoria deshabilitadas',
    add_options_to_enable_this_feature: 'Agregar opciones para habilitar esta función',
    attach: 'Adjuntar',
    shuffle: 'Barajar',
    marks: "Marcas",
    mark: "marca",
    correction_status: "Estado:",
    status_validated: "Validado",
    pending: "Pendiente",
    results_publish: "Publicar",
    publish: "Publicar",
    publish_description: "¿Quieres publicar los resultados de las pruebas?",
    publish_description_note: "(Las marcas se publicarán en la misma URL)",
    published_successfully: "Publicado con éxito",
    choice_type_validation_note: "*Opción múltiple validada automáticamente",
    short_answer_type_validation_note: "*Respuesta corta validada automáticamente",
    long_answer_type_validation_note: "*Respuesta larga NO validada automáticamente",
    fileupload_answer_type_validation_note: "*Carga de archivo NO validada automáticamente",
    dropdown_answer_type_validation_note: "*Opción desplegable validada automáticamente",
    save: "salvar",
    questions: "Preguntas",
    show_correct_answer: "Mostrar respuesta correcta",
    show_answer: "Mostrar respuesta",
    hide_answer: "Ocultar respuesta",
    no_marks: "Sin marca",
    question_is_required: "título pregunta es obligatorio",
    marks_is_required: "Se requieren marcas",
    options_are_required: "Se requieren opciones",
    options_limit_reached: 'límite de opciones alcanzado',
    options_limit_reached_description: 'Ha alcanzado el límite de opciones permitidas en una pregunta.',
    correct_answer_required: "Se requiere respuesta correcta",
    enter_valid_mark: "Ingrese una marca válida",
    evaluated: "Evaluado",
    saved: "Salvado",
    pending_evaluation: "Pendiente de evaluación",
    alphabet: "Alfabeto",
    not_evaluated: "No evaluado",
    percentage_ascending: "Porcentaje - Ascendente",
    percentage_descending: "Porcentaje - Descendente",
    pending_evaluation_alert_message: "Debes completar la evaluación de todas las respuestas antes de publicarlas.",
    show_unevaluated: "Mostrar sin evaluar",
    publish_success_alert_message: "Los encuestados pueden ver sus resultados en sus correspondientes hojas de respuestas.",
    publishing: "Publicación",
    quiz_published: "Prueba publicada",
    quiz_published_alert_before_edit_message: "Este cuestionario ha sido publicado. Si necesita hacer cambios, debe duplicar el cuestionario y compartirlo nuevamente.",
    quiz_has_answers: "Quiz tiene respuestas",
    quiz_has_answer_alert_message: "Para editar este cuestionario, debe eliminar todas las respuestas existentes.",
    unavailable_features_description: 'Este formulario tiene funciones que aún no son compatibles con la aplicación web',
    unavailable_quiz_features_description: 'Este cuestionario tiene funciones que aún no son compatibles con la aplicación web',
    unavailable: 'Indisponible',
    unpublished: "Inédito",
    published: "Publicado",
    setting_updated: "Configuración actualizada",
    account: "Cuenta",
    on: "EN",
    off: "APAGADO",
    scan_qr_code: "Escanear código QR",
    answers: "respuestas",
    answer_s: "responder",
    question_limit_reached: "Límite de preguntas alcanzado",
    reached_limit_for_number_of_questions: "Ha alcanzado el límite de preguntas permitidas en un formulario. Por lo tanto, no se pueden agregar nuevas preguntas.",
    life_time: "Toda la vida",
    last_year: "El año pasado",
    last_30_days: "Últimos 30 días",
    last_7_days: "Los últimos 7 días",
    last_24_hours: "Últimas 24 horas",
    custom_date: "Fecha personalizada",
    filter_by_date: "Filtrar por fecha",
    filter_by_question: "Filtrar por pregunta",
    filter_text: "Filtrar texto",
    add_filter: "Añadir filtro",
    apply: "Solicitar",
    from_date: "Partir de la fecha",
    today: 'Hoy',
    to_date: "Hasta la fecha",
    contains: "Contiene",
    equal_to: "Igual a",
    not_equal_to: "No igual a",
    from_date_required: "Desde la fecha requerida",
    to_date_required: "Hasta la fecha requerida",
    webapp_promo_text: "¡Acceda a sus formularios, encuestas, encuestas y cuestionarios en línea!",
    question_title: "Pregunta",
    invalid_date: "Fecha invalida",
    invalid_url: "URL invalida",
    invalid_url_description: "La URL no parece ser válida.",
    delete_response_alert: "¿Seguro que quieres eliminar esta respuesta?",
    delete_responses_alert: "¿Quieres eliminar estas respuestas?",
    overview: "Visión general",
    no_results_found: "No se han encontrado resultados",
    date_format: "dd-mm-yyyy",
    title_dashboard: "Tablero de mandos",
    title_notifications: "Notificaciones",
    select_one_response: "Seleccione al menos una respuesta para eliminar",

//Untranslated strings

    your_account: "Su cuenta",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    favourites: "Favoritos",
    shared: "Compartido",
    hide: "Ocultar",
    use_templates: "Usar plantillas",
    quick_settings: "Ajustes rápidos",
    leave_collaboration: "Dejar Colaboración",
    templates: "Plantillas",
    collapse: "Colapsar",
    start_scale_from_zero: 'Iniciar escala desde 0',
    center_label_optional: 'Etiqueta central (opcional)',
    left_label_optional: 'Etiqueta izquierda (opcional)',
    right_label_optional: 'Etiqueta correcta (opcional)',
    duplicate: "Duplicar",
    row: "Fila",
    column: "Columna",
    rows: "Filas",
    add_column: 'Agregar columna',
    add_row: 'Agregar fila',
    user_info_for_file_upload: "Información de usuario para carga de archivos",
    user_info_for_file_upload_desc1: "Dado que agregó una pregunta para cargar archivos, se ha habilitado la opción para recopilar información del usuario. Esto facilitará la identificación de los archivos descargados. Los encuestados verán un cuadro de diálogo para ingresar su información.",
    user_info_for_file_upload_desc2: "Puede cambiar esta opción en Configuración si no desea que se muestre el cuadro de diálogo.",
    show_rows_and_columns: "Mostrar filas & Columnas",
    scale_down: 'Reducir escala',
    scale_up: 'Escalar verticalmente',
    dislike: 'Aversión',
    like: 'Gustar',
    best: 'Mejor',
    remove_favourite: "Eliminar favorito",
    add_favourite: "Agregar favorito",
    collaborator: "Colaborador",
    collaborators: "colaboradores",
    show_more: "Mostrar más",
    create_quiz: "Crear cuestionario",
    collaborate: "Colaborar",
    share_QR_code: "Compartir código QR",
    add_image: "Añadir imagen",
    invalid_file_type: "Tipo de archivo invalido",
    drag_drop: "Arrastrar & Soltar",
    here: "Aquí",
    choose: "Elegir",
    add_account: 'Agregar cuenta',
    choose_account: 'Elegir cuenta',
    maximum_allowed_file_size: "El tamaño de archivo máximo permitido es ",
    or: "o",
    next: "Próximo",
    change_image: "Cambiar imagen",
    upload: "Subir",
    view: "Vista",
    collaboration: "Colaboración",
    share_collaboration_form: "¿Quieres compartir este formulario con otros?",
    share_collaboration_quiz: "¿Quieres compartir este cuestionario con otros?",
    multiple_responses_summary: "Respuestas múltiples, Summary",
    question_number_shuffle: "Número de pregunta, Signo de interrogación, Barajar",
    general: "General",
    browse: "Navegar",
    owner: "Dueño",
    something_went_wrong: "Algo salió mal!",
    forget_your_password: "Olvidaste tu contraseña?",
    sign_in: "Iniciar sesión",
    sign_up: "Inscribirse",
    using_email: "utilizando el correo electrónico",
    dont_have_account: "Don't have an account?",
    on_signing_up_agree_to_surveyhearts: "al registrarse, estás de acuerdo con SurveyHeart's",
    and: "y",
    terms_of_use: "Términos de Uso",
    already_have_an_account: "Ya tienes una cuenta?", //
    signed_in_previously_as_a_google_user: "Has iniciado sesión anteriormente como usuario de Google.",
    sign_in_google_to_continue: "Inicia sesión a través de Google para continuar.",
    no_account_found_email_address: "No se encontró ninguna cuenta con esta dirección de correo electrónico",
    account_already_exist_email: "La cuenta ya existe para este correo electrónico.",
    install_surveyheart_app: "Instalar SurveyHeart aplicación",
    easy_login_via_app: "Fácil inicio de sesión a través de la aplicación",
    get_notification_instantly: "Obtener notificación al instante",
    create_form_quiz_on_the_go: "Crear formulario/cuestionario sobre la marcha",
    check_your_inbox_to_activate_your_account: "Por favor revise su bandeja de entrada para activar su cuenta",
    select_questions: "Seleccionar preguntas",
    percentage: "Porcentaje",
    free_App_to_create_forms: "Obtenga la aplicación gratuita para crear formularios",
    we_cant_find_the_page_that_you_are_looking_for: "No podemos encontrar la página que estás buscando.",
    answered: "Contestada",
    completing_this_quiz: "Gracias por completar este cuestionario",
    connected_successfully: 'Conectado correctamente',
    seconds: "Segundos",
    enter_your_details: "Introduce tus detalles",
    enter_all_required_information: "Por favor introduce toda la información requerida",
    answer_data_not_found: "Datos de respuesta no encontrados",
    results_published_soon: "Los resultados se publicarán pronto",
    previous: 'Previa',
    save_link_for_future_reference: "Guarde este enlace para referencia futura",
    choice_questions_only: "Solo preguntas de elección",
    score_is_not_final: "Nota: Esta puntuación no es definitiva",
    tabular: "Tabular",
    text: "texto",
    answer_response_alert: "Esta pregunta requiere una respuesta",
    invalid_email_address: "Dirección de correo electrónico no válida",
    answer_not_available: "Respuesta no disponible",
    validated_automatically: "* validado automáticamente",
    answer_link: "AEnlace de respuesta",
    embed_code: 'Código de inserción',
    copy_code: 'Copiar código',
    code_copied: 'Código copiado',
    share_via: "Compartir via",
    print: "Impresión",
    columns: 'columnas',
    columns_all: "columnas (todas)",
    submitted_time: "Hora de envío",
    unable_to_submit: 'No se puede enviar',
    unable_to_submit_alert_line1: 'Dado que este cuestionario recibió respuestas, tiene acceso limitado para realizar cambios.',
    unable_to_submit_alert_line2: 'Para obtener acceso completo para editar este cuestionario, debe eliminar todas las respuestas existentes.',
    action_blocked: 'Acción bloqueada',
    action_blocked_alert: 'Acción bloqueada debido a respuestas existentes para este cuestionario. Permitir esto podría afectar las respuestas anteriores.',
    quiz_edit_submit_alert: 'La edición del cuestionario puede afectar las calificaciones y el porcentaje y requerir una reevaluación de ciertas preguntas.',
    continue: 'Continuar',
    submitted: "Presentada",
    submitting: "Sumisión...",
    filter: "Filtrar",
    exit_screen: "Pantalla de salida",
    full_screen: "Pantalla completa",
    tabular_view: "Vista tabular",
    clear_all: "Limpiar todo",
    you_are_all_caught_up: "todos ustedes están atrapados!",
    check_back_later_for_new_notifications: "Vuelve más tarde para ver nuevas notificaciones.",
    requires_answer: "requiere respuesta",
    enter_member_id: "Ingrese la identificación del miembro",
    viewers_can_read_form_responses: "Los espectadores pueden leer el formulario y ver las respuestas",
    editors_edit_form_view_responses: "Los editores pueden editar el formulario y ver las respuestas",
    admin_edit_form_add_collaborator: "El administrador puede editar el formulario y agregar colaborador",
    invalid_member_id: "Identificación de miembro no válida",
    already_member_added: "Ya este miembro agregó",
    add_collaborator: "Añadir colaborador",
    admin: "Administrador",
    editor: "editora",
    viewer: "Espectador",
    searched_form_not_found: "Formulario buscado no encontrado!",
    searched_quiz_not_found: "¡Cuestionario buscado no encontrado!",
    no_shared_data_available: "No hay datos compartidos disponibles!",
    forms_server_is_not_available: "El servidor de formularios no está disponible",
    quiz_server_is_not_available: "El servidor de preguntas no está disponible",
    leave: 'abandonar',
    password: 'Clave',
    new_password: 'Nueva contraseña',
    retype_password: 'Vuelva a escribir la contraseña',
    create_password: 'Crear contraseña',
    view_results: 'Ver resultados',
    control: 'Control',
    q_control: 'Q.Control',
    q_result: 'Q.Resultado',
    no_matches_found: "No se encontraron coincidencias",
    dropdown: "Desplegable",
    checkboxes: "Casillas de verificación",
    star_rating: 'Clasificación de estrellas',
    linear_scale: 'Escala lineal',
    multiple_choice_grid: 'Cuadrícula de opción múltiple',
    checkboxes_grid: 'Cuadrícula de casilla de verificación',
    smile: 'Sonreír',
    info_1: "Información 1",
    info_2: "Información 2",
    info_3: "Información 3",
    new: 'nuevo',
    download_all: 'Descargar todo',
    download: 'Descargar',
    sort_by: 'Ordenar por',
    sorting: 'Clasificación',
    refresh: 'actualizar',
    add: 'agregar',
    refresh_preview_description: "Haga clic en el botón 'Actualizar' para actualizar la Vista previa",
    enter_users_email_address: 'Introducir la dirección de correo electrónico',
    do_you_want_to_delete_these: '¿Quieres eliminar estos',
    do_you_want_to_delete_this_form: '¿Quieres eliminar este formulario?',
    all_responses_will_also_be_deleted: 'Todas las respuestas también serán eliminadas.',
    this_action_cannot_be_undone: 'Esta acción no se puede deshacer.',
    do_you_want_to_delete_this_quiz: '¿Quieres eliminar este Quiz',
    all_answer_sheets_will_also_be_unavailable: 'Todas las hojas de respuestas tampoco estarán disponibles.',
    change: 'Cambio',
    roles_rights: 'Funciones y derechos',
    learn_more: 'Aprende más',
    viewers_can_read_quiz_and_view_answers: 'Los espectadores pueden leer el cuestionario y ver las respuestas.',
    editors_can_edit_quiz_view_answers: 'Los editores pueden editar el cuestionario y ver las respuestas.',
    admin_can_edit_quiz_add_collaborator: 'El administrador puede editar el cuestionario y agregar colaborador.',
    change_roles: 'Cambiar roles',
    remove: 'retirar',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'No ha enviado sus cambios. ¿Está bien irse sin enviar?',
    select_language: 'Seleccione el idioma',
    are_you_sure_you_want_delete_this_collaborator: '¿Estás seguro de que quieres eliminar a este colaborador?',
    are_you_sure_you_want_to_delete_selected_files: '¿Está seguro de que desea eliminar permanentemente el archivo seleccionado?',
    please_download_before_deleting: 'Descargue antes de eliminar. ¡Los archivos una vez eliminados no se pueden recuperar!',
    role_has_been_changed_successfully: 'peran telah berhasil diubah',
    do_you_want_to_leave_collaboration: '¿Quieres dejar la colaboración?',
    form_builder_access_denied: 'Acceso al creador de formularios denegado',
    quiz_builder_access_denied: 'Acceso denegado al Creador de cuestionarios',
    you_only_have_viewer_rights_for_quiz: 'Solo tienes derechos de espectador para este cuestionario.',
    you_only_have_viewer_rights_for_form: 'Solo tiene derechos de espectador para este formulario.',
    kindly_contact_the_form_owner_for_more_info: 'Póngase en contacto con el propietario del formulario para obtener más información.',
    kindly_contact_the_quiz_owner_for_more_info: 'Póngase en contacto con el propietario del cuestionario para obtener más información.',
    incomplete_questions: 'Preguntas incompletas :',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'Tiene cambios sin guardar. ¿Está seguro de que desea abandonar esta página?',
    languages: 'Idiomas',
    copy: "Copiar",
    not_good: 'No bueno',
    not_allowed: 'No permitido',
    not_allowed_description: 'El tipo de pregunta de elección no se puede cambiar después de recopilar las respuestas.',
    neutral: 'neutral',
    good: 'bueno',
    very_good: 'Muy bueno',
    edit_rights_removed: 'Derechos de edición eliminados',
    your_rights_to_edit_this_form_have_been_removed: "Se han eliminado sus derechos para editar este formulario.",
    your_rights_to_edit_this_quiz_have_been_removed: "Se han eliminado sus derechos para editar este cuestionario.",
    please_contact_quiz_owner_for_more_info: "Póngase en contacto con el propietario del formulario para obtener más información.",
    please_contact_form_owner_for_more_info: "Comuníquese con el propietario del cuestionario para obtener más información.",
    last_edited_on: 'Última edición el',
    last_edited: "Última edición",
    by: 'por',
    you: "Tú",
    add_option: 'Añadir opción',
    add_other_option: "Agregar opción 'Otro'",
    estimated_time: "Hora prevista",
    do_you_want_to_show_estimated_time_taking_quiz: "¿Quieres mostrar el tiempo estimado para tomar la prueba?",
    do_you_want_to_show_estimated_time_taking_form: "¿Quieres mostrar el tiempo estimado para completar el formulario?",
    show_question_count: "Mostrar número de preguntas",
    do_you_want_to_show_total_number_questions_quiz: "¿Quieres mostrar el número total de preguntas en el cuestionario?",
    do_you_want_to_show_total_number_questions_form: "¿Quieres mostrar el número total de preguntas en el formulario?",
    show_question_marks: "Mostrar signos de interrogación",
    show_question_marks_quiz: "Mostrar signos de interrogación en el cuestionario",
    you_dont_have_add_collaborator_permission: "No tienes permiso para agregar colaborador",
    modified_date: "Fecha de modificación",
    created_date: "Fecha de creación",
    question_cant_be_added: "NO SE PUEDE AÑADIR LA PREGUNTA",
    another_file_upload_question_cant_added: "NO SE PUEDE AGREGAR OTRA PREGUNTA DE CARGA DE ARCHIVO",
    you_can_have_only: "solo puedes tener",
    file_upload_questions_in_form: "Preguntas de carga de archivos en un formulario",
    file_upload_questions_in_quiz: "Preguntas de carga de archivos en un cuestionario",
    storage_capacity_exceeded: "Capacidad de almacenamiento excedida",
    kindly_inform_form_builder_that: "Informe amablemente al Creador de formularios que",
    kindly_inform_quiz_builder_that: "Informe amablemente al Quiz Builder que",
    their_storage_capacity_file_uploads: "su capacidad de almacenamiento para cargas de archivos",
    has_been_exceeded: "se ha excedido",
    view_details: "VER DETALLES",
    paste_your_video_link_here: "Pegue el enlace de su video aquí",
    paste_your_website_link_here: "Pegue el enlace de su sitio web aquí",
    view_answer_sheet: "Ver hoja de respuestas",
    response_count: "Recuento de respuestas",
    count: "contar",
    password_least_8_characters: "La contraseña debe tener al menos 8 - 50 caracteres",
    password_contain_least_1_number: "La contraseña debe contener al menos 1 número",
    password_contain_least_1_capital_letter: "La contraseña debe contener al menos 1 letra mayúscula",
    password_contain_least_1_special_character: "La contraseña debe contener al menos 1 carácter especial",
    please_fill_all_required_fields: "por favor llene todos los campos requeridos",
    passwords_dont_match: "Las contraseñas no coinciden",
    incorrect_password: "Contraseña incorrecta",
    click_or_change_current_theme: "Haga clic en el tema actual o 'Cambiar' a continuación para elegir un tema diferente",
    select: "Seleccione",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "¿Quieres eliminar esta imagen? Si continúa, esta imagen no estará disponible para ningún formulario o cuestionario que la esté utilizando. Esta acción no se puede deshacer.",
    url_does_not_seem_to_valid: "La URL no parece ser válida.",
    you_have_reached_image_uploaded_limit: "Has alcanzado el límite de imágenes subidas.",
    feature_spotlight: "Característica destacada",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "Nota: para volver a ver esta información, haga clic en 'Más' en el menú del lado izquierdo",
    undo: "deshacer",
    response_deleted: "respuesta eliminada",
    question_deleted: "pregunta eliminada",
    answer_deleted: "respuesta eliminada",
    responses_deleted: "respuestas eliminadas",
    questions_deleted: "preguntas eliminadas",
    collect_email_header: "Recopilar CORREO ELECTRÓNICO",
    collect_email_alert_message: "Habilitar esta función obligará al encuestado a iniciar sesión con su dirección de Gmail para completar el formulario",
    collect_email_alert_note: "NOTA: Solo se admite la cuenta de Google",
    allow_response_edit: "Permitir editar la respuesta",
    allow_response_edit_description: "¿Desea permitir que los encuestados editen las respuestas después de enviarlas?",
    answers_deleted: "respuestas eliminadas",
    enter_desc_here: "Ingrese la descripción aquí",
    passcode_protection: "Protección con contraseña",
    passcode_protection_description_quiz: "¿Quiere que su cuestionario esté protegido con contraseña?",
    passcode_protection_description: "¿Quiere que su formulario esté protegido con contraseña?",
    crop_image: "Delimitar imagen",
    crop: "Cultivo",
    original: "Original",
    collect_email_description_quiz: "Si habilita esta función, los encuestados deberán iniciar sesión con su dirección de Gmail para completar el cuestionario.",
    collect_email_description_form: "Si habilita esta función, los encuestados deberán iniciar sesión con su dirección de Gmail para completar el formulario.",
    collect_email: "Recopilar dirección de correo electrónico",
    collect_email_description: "¿Quiere recopilar las direcciones de correo electrónico de los encuestados para posibles referencias o usos futuros?",
    opening_date: 'Fecha de apertura',
    closing_date: 'Fecha de cierre',
    schedule_form_status: 'Estado del formulario de programación',
    schedule_quiz_status: 'Programar estado del examen',
    schedule_opening_date_and_time: "Agendar fecha y hora de apertura",
    schedule_closing_date_and_time: "Fecha y hora de cierre del cronograma",
    schedule_opening_date_and_time_description: "Activa el formulario en la fecha y hora mencionadas.",
    schedule_opening_date_and_time_description_quiz: "Activa el cuestionario en la fecha y hora mencionadas.",
    schedule_closing_date_and_time_description: "Desactivar el formulario en la fecha y hora mencionadas",
    schedule_closing_date_and_time_description_quiz: "Desactivar el cuestionario en la fecha y hora mencionadas.",
    upgrade: "Mejora",
    gold_plan: "Plan Oro",
    view_plans: "Ver planos",
    premium_plans: "Planes premium",
    plan_duration: "/ Mes",
    plan_logic_jump: "Salto lógico",
    plan_remove_ads: "Quitar anuncios",
    cancel_subscription: "Cancelar suscripción",
    quiz_has_answer_edit_message: "Este cuestionario ya se envió y se recopilaron las respuestas. La edición del cuestionario puede afectar las respuestas anteriores",
    quiz_published_alert_edit_message: "Este cuestionario ha sido publicado. Si lo edita, este cuestionario no se publicará",
    quiz_submit_after_edit_message: "La edición del cuestionario puede afectar las calificaciones y el porcentaje y requerir una reevaluación de ciertas preguntas",
    title_activity_privacy_policy: "Política de PrivacidadActividad",
    action_blocked_message: "Acción bloqueada debido a respuestas existentes para este cuestionario. Permitir esto podría afectar las respuestas anteriores",
    email_addresses: "Correos electrónicos",
    confirmation_message: "Mensaje de confirmacion",
    confirmation_message_description: "¿Quieres mostrar un mensaje de confirmación después de enviar el formulario?",
    confirmation_message_description_quiz: "¿Quieres mostrar un mensaje de confirmación después de responder el cuestionario?",
    default_custom_thank_message: "Gracias por completar este formulario.",
    default_custom_thank_message_quiz: "Gracias por completar este cuestionario",
    upgrade_your_plan: "¡Actualiza tu plan!",
    upgrade_your_plan_1: "Actualiza tu plan",
    upgrade_your_plan_content: "Actualice la versión premium para acceder a esta función y aumentar el tamaño de almacenamiento, etc.",
    alert_icon: "Icono de alerta",
    usage_details: "Detalles de uso",
    current_plan: "Plan actual",
    total_submission: "Presentación Total",
    image_attachments: "Imagen adjunta",
    custom_themes: "Temas personalizados",
    free_limit_reached: "Has alcanzado el límite gratuito -",
    payment_done_but_server_error: "Su pago se ha deducido correctamente. Abra la aplicación después de un tiempo para permitirnos verificar su pago",
    response_quota_reached: "Has alcanzado el límite gratuito, por favor actualiza tu plan para acceder a todas las respuestas",
    answers_quota_reached: "Has alcanzado el límite gratuito, por favor actualiza tu plan para acceder a todas las respuestas",
    logic_jump_enabled: "Salto lógico habilitado",
    logic_jump_disabled: "Salto lógico deshabilitado",
    cancel_subscription_message: "¿Está seguro de que desea cancelar su suscripción? Cancelar la suscripción resultará en la pérdida del acceso a todas las funciones premium",
    plan_expired: "Su plan ha caducado. Por favor actualice su plan para utilizar este borrador",
    plan_expired_edit_form: "Su plan ha caducado. Actualice su plan para editar este formulario",
    plan_expired_edit_quiz: "Su plan ha caducado. Por favor actualice su plan para editar este cuestionario",
    plan_expired_duplicate_form: "Su plan ha caducado. Actualice su plan para duplicar este formulario",
    plan_expired_duplicate_quiz: "Su plan ha caducado. Por favor actualice su plan para duplicar este cuestionario",
    premium_features: "Función Premium",
    plan_file_upload: "Imágenes temáticas personalizadas ilimitadas",
    plan_custom_theme: "Imágenes temáticas personalizadas ilimitadas",
    plan_call_support: "Alta prioridad activada: llame al soporte técnico",
    plan_email_support: "Soporte por correo electrónico de alta prioridad",
    plan_custom_domain: "Dominio personalizado",
    subscribe: "Suscribir",
    plan_remove_branding: "Eliminar marca",
    plan_on_call_support: "Soporte de guardia",
    plan_image_attachment: "Imágenes adjuntas ilimitadas",
    plan_custom_thank_you: "Páginas de agradecimiento personalizables",
    plan_allowed_responses: "Recuento de respuestas permitidas 50000",
    trusted_by_number_of_user: "Con la confianza de más de 5 millones de usuarios",
    email_support: "Soporte de correo electrónico",
    call_support: "Llamar a soporte",
    keyboard_shortcuts: "Atajos de teclado",
    premium_limit_reached: "Has alcanzado el límite del plan. Actualice amablemente su plan para acceder a todas las funciones premium.",
    premium_limit_reached_mobile: "Has alcanzado el límite del plan.",
    unlimited: "Ilimitado",
    expired_on_next_renewal: "Estará caducado en su próxima fecha de renovación.",
    upgrade_plan: "Plan de actualización",
    downgrade_plan: "Plan de degradación",
    file_upload_questions: "Preguntas sobre carga de archivos",
    plan_response_edit: "Respuesta Editar",
    cant_process: "No se puede procesar",
    you_have_subscribed_through_android: "Te has suscrito a través de nuestra aplicación de Android. Utilice la aplicación SurveyHeart para cualquier actualización relacionada con el pago.",
    pay_now: "Pagar ahora",
    update_payment_details: "Actualizar detalles de pago",
    limit_reached_alert_message: "Ha alcanzado el límite gratuito, actualice su plan para acceder a todas las respuestas.",
    limit_reached_alert_message_quiz: "Ha alcanzado el límite gratuito, actualice su plan para acceder a todas las respuestas.",
    limit_reached_UI_message: "Las respuestas no estarán disponibles para su plan actual. Actualice su plan para ver todas las respuestas.",
    limit_reached_UI_message_quiz: "Las respuestas no estarán disponibles para su plan actual. Actualice su plan para ver todas las respuestas.",
    owner_is_not_premium_user: "El propietario no es un usuario premium",
    cancel_subscription_description: "¿Estás seguro de que deseas cancelar tu suscripción? La cancelación resultará en la pérdida del acceso a todas las funciones premium.",
    starter_storage_description: "Obtenga 5 GB de almacenamiento para una gestión de datos sin esfuerzo.",
    bronze_storage_description: "Obtenga 25 GB de almacenamiento para una gestión de datos sin esfuerzo.",
    silver_storage_description: "Obtenga 50 GB de almacenamiento para una gestión de datos sin esfuerzo.",
    gold_storage_description: "Obtenga 150 GB de almacenamiento para una gestión de datos sin esfuerzo.",
    starter_responses_description: "Use 10 000 respuestas para obtener información valiosa sobre las encuestas.",
    bronze_responses_description: "Use 35 000 respuestas para obtener información valiosa sobre las encuestas.",
    silver_responses_description: "Use 75 000 respuestas para obtener información valiosa sobre las encuestas.",
    gold_responses_description: "Use 150 000 respuestas para obtener información valiosa sobre las encuestas.",
    starter_image_description: "Mejore sus encuestas con hasta 100 imágenes para que sean más atractivas visualmente.",
    bronze_image_description: "Mejore sus encuestas con hasta 200 imágenes para que sean más atractivas visualmente.",
    silver_image_description: "Mejore sus encuestas con hasta 500 imágenes para que sean más atractivas visualmente.",
    gold_image_description: "Mejore sus encuestas con imágenes ilimitadas para que sean más atractivas visualmente.",
    starter_theme_description: "Agregue 100 imágenes personalizadas para personalizar sus encuestas para su marca.",
    bronze_theme_description: "Agregue 200 imágenes personalizadas para personalizar sus encuestas para su marca.",
    silver_theme_description: "Agregue 500 imágenes personalizadas para personalizar sus encuestas para su marca.",
    gold_theme_description: "Agregue imágenes personalizadas ilimitadas para personalizar sus encuestas para su marca.",
    custom_theme_image: "Imagen de tema personalizada",
    starter_upload_description: "Integración perfecta de 4 preguntas de carga de archivos",
    file_upload_description: "Integre sin problemas preguntas de carga de archivos ilimitadas.",
    logic_jump_description: "Use saltos lógicos para personalizar el flujo de la encuesta para la experiencia de cada encuestado",
    thank_you_message_description: "Personalice las páginas de agradecimiento para impresionar a los encuestados.",
    response_addon_description: "Obtenga respuestas adicionales para reunir más información.",
    response_edit_description: "Refine fácilmente las respuestas usando la función de edición de respuestas.",
    remove_branding_description: "Eleve la presencia de su marca eliminando la marca SurveyHeart.",
    bronze_remove_ads_description: "Elimine los anuncios con nuestro plan Bronze y concéntrese en su marca encuestas.",
    silver_remove_ads_description: "Elimine los anuncios con nuestro plan Silver y concéntrese en sus encuestas.",
    gold_remove_ads_description: "Elimine los anuncios con nuestro plan Gold y concéntrese en sus encuestas.",
    bronze_email_support_description: "Acceda al soporte por correo electrónico para asuntos de baja prioridad, lo que garantiza una asistencia oportuna.",
    silver_email_support_description: "Acceda al soporte por correo electrónico para asuntos de prioridad media, lo que garantiza una asistencia oportuna.",
    gold_email_support_description: "Acceda al soporte por correo electrónico para asuntos de alta prioridad, lo que garantiza una asistencia oportuna.",
    bronze_email_support_title: "Soporte por correo electrónico de baja prioridad",
    silver_email_support_title: "Soporte por correo electrónico de prioridad media",
    gold_email_support_title: "Soporte por correo electrónico de alta prioridad",
    update_your_payment_details_mobile: "Actualice sus datos de pago.",
    update_your_payment_details_pc: "Estamos enfrentando algunos problemas con la renovación esta cuenta. Actualice sus datos de pago.",
    card_details_updated_successfully: "Los datos de la tarjeta se actualizaron correctamente",
    card_details_updated_successfully_description: "Los datos de su tarjeta se actualizaron correctamente. Ahora puede continuar usando su información de pago actualizada.",
    payment_successful: "Pago exitoso",
    payment_successful_description: "Su pago se realizó correctamente. Ahora está suscrito al plan premium de SurveyHeart. Póngase cómodo y disfrute de todas las increíbles funciones que le esperan.",
    payment_failed: "¡Pago fallido!",
    payment_failed_description: "Lamentablemente, no pudimos procesar su suscripción al plan premium de SurveyHeart. Inténtelo nuevamente.",
    note_for_five_rupees: "Nota: Hay un cargo de Rs. 5 por actualizar los datos de su tarjeta, que se reembolsará dentro de los 3 a 4 días hábiles a la misma cuenta.",
    logic: "Lógica",
    re_generate_code: "Regenerar código",
    plan_change_restrictions: "Restricciones de cambio de plan",
    plan_change_restriction_desc_1: "No puedes cambiar tu plan usando UPI.",
    plan_change_restriction_desc_2: "Cambia a una tarjeta para actualizaciones y degradaciones, o cancela y vuelve a suscribirte a tu plan deseado.",
    update_card_details: "Actualizar detalles de la tarjeta",
    features_and_plans: "Funciones y planes",
    cancel_plan: "Cancelar plan",
    cancel_plan_desc: "Has cancelado el plan. Aún tendrás acceso a las funciones pagas hasta tu fecha de renovación.",
    explore_text: "Explora y desbloquea funciones excepcionales en uno de nuestros planes de suscripción premium hoy mismo",
    cant_edit_this_form: "No se puede editar este formulario",
    cant_edit_this_quiz: "No se puede editar este cuestionario",
    cant_duplicate_this_form: "No se puede duplicar este formulario",
    cant_duplicate_this_quiz: "No se puede duplicar este cuestionario",
    kindly_upgrade_the_premium: "Actualice la versión premium para acceder a esta función",
    check_box: 'Casilla de verificación',
    continue_to_next_question: "Continuar a la siguiente pregunta",
    add_logic: "Agregar lógica",
    submit_form: "Enviar formulario",
    save_logic: "Guardar lógica",
    remove_logic: "Eliminar lógica",
    responses_add_ons: "Complemento de respuestas",
    responses_add_available: "Complemento de respuestas disponible",
    additional_submissions: "Envíos adicionales",
    validity: "Validez",
    up_to_your_current_plan: "Hasta su plan actual",
    responses_add_ons_description: "Este recuento de respuestas se agregará a su plan existente.",
    redirect_url_title: "Redireccionar al enlace deseado",
    redirect_url_description: "¿Desea redirigir a sus usuarios a una página web externa?",
    enter_weblink: "Ingrese el enlace web",
    get: "Conseguir",
    jump_to: "Saltar a",
    action_cannot_be_performed: "No se puede realizar la acción",
    upgrade_plan_desc1: "¿Quieres actualizar tu plan a",
    downgrade_plan_desc1: "¿Quieres cambiar tu plan a una categoría inferior a",
    downgrade_plan_desc2: "Este cambio entrará en vigor en tu próxima fecha de renovación.",
    upgraded_plan: "Plan actualizado",
    downgraded_plan: "Plan reducido",
    upgraded_plan_desc: "Has actualizado tu plan. Este cambio entrará en vigor en tu próxima fecha de renovación.",
    downgraded_plan_desc: "Has cambiado tu plan a una categoría inferior. Este cambio entrará en vigor en tu próxima fecha de renovación.",
    buy_responses: "Comprar respuestas",
    passcode: "Código de acceso",
    share_link_with_passcode: "Compartir enlace con Código de acceso",
    contact_us: "Contáctenos",
    premium_limit_reached_for_gold: "Ha alcanzado el límite del plan. Comuníquese con nosotros para acceder a todas las funciones premium.",
    follow_us: "Síganos",
    billing_history: "Historial de facturación",
    your_current_plan: "Su plan actual",
    next_billing_date: "Próxima fecha de facturación",
    transaction_id: "ID de transacción",
    plan: "Plan",
    price: "Precio",
    status: "Estado",
    follow_us_desc: "Manténgase actualizado, conectado e interactúe con nosotros",
    ranking_choice: 'Opción de clasificación',
    ranking: 'Clasificación',
    picture_choice: 'Opción de imagen',
    agreement: 'Acuerdo',
    signature: "Firma",
    slider: 'Control deslizante',
    min: 'Mín.',
    max: 'Máx.',
    step_value: "Valor de paso",
    scale_range_error: "El rango de escala debe ser un valor entre -10 000 y 10 000.",
    scale_range_error2: "El valor de la escala máxima es menor que el valor aceptable.",
    max_min_error: "El valor de la escala mínima debe ser menor que la escala máxima.",
    step_error: "El tamaño del paso debe ser un valor entre",
    add_picture: 'Agregar imagen',
    terms_and_conditions: "Términos y condiciones",
    terms_and_conditions_required: "Términos y condiciones requeridos",
    i_agree: "Estoy de acuerdo",
    i_disagree: "No estoy de acuerdo",
    yearly: "Anual",
    monthly: "Mensual",
    save_upto_20: "Ahorre hasta un 20 % con planes anuales",
    paid: "Pago",
    cancelled: "Cancelado",
}