export const translationsPortuguese = {
    all: 'Todos',
    ago: "atrás",
    now: "Agora",
    start: "Iniciar",
    account_blocked: "Conta bloqueada",
    account_blocked_alert: "Esta conta foi bloqueada por violar nossos Termos e Condições",
    sign_out: "Sair",
    short_answer: "Resposta curta",
    long_answer: "Resposta longa",
    email: "Email",
    number: "Número",
    file: "Arquivo",
    files: "Arquivos",
    faq_videos: "Perguntas Frequentes Vídeos",
    file_upload: "Upload de arquivo",
    question_error: "A pergunta não pode ser adicionada",
    storage: "Armazenar",
    form: "Formato",
    quiz: "Questionário",
    fup_desc: "Agora você pode permitir que os respondentes de seus formulários e questionários carreguem arquivos!\n\nSelecione o tipo de pergunta de upload de arquivo para começar.",
    new_fup_feature_title: "Novo recurso de upload de arquivo!",
    multiple_choice: "Múltipla escolha",
    date_text: "Data",
    time_text: "Tempo",
    uploading: "Enviando…",
    add_question: "Adicionar pergunta",
    add_question_alert: "Por favor, adicione pelo menos uma pergunta a todos os itens necessários.",
    add_question_alert_quiz: "Adicione pelo menos uma pergunta antes de enviar o questionário",
    ok: "OK",
    title: "Título",
    title_required: "O título é obrigatório",
    form_name_alert: "Por favor, nomeie o formulário antes de publicá-lo.",
    quiz_name_alert: "Nomeie o questionário antes de publicá-lo",
    no_connection: "Sem conexão",
    check_connection: "Por favor, verifique sua conexão de rede.",
    finish_incomplete_question: "Por favor, termine as perguntas incompletas do formulário",
    draft: "Rascunho",
    save_to_draft_alert: "Deseja salvar este formulário como rascunho?",
    save_to_draft_quiz_alert: "Gostaria de salvar este questionário como um rascunho para evitar perder suas alterações?",
    yes: "Sim",
    no: "Não",
    cancel: "Cancelar",
    live_preview: "Visualização ao vivo",
    themes: "Temas",
    tap_to_set_theme: "Toque aqui para definir o tema do formulário.",
    select_image: "Selecione a imagem",
    created: "Criado",
    form_created_successfully: "Formulário criado com sucesso",
    warning: "Atenção",
    form_edit_alert: "Este formulário já foi publicado e as respostas foram coletadas. A edição do formulário pode afetar as respostas que já foram coletadas.",
    quiz_edit_alert: 'Este questionário já foi enviado e as respostas foram coletadas. A edição do questionário pode afetar as respostas anteriores',
    edit: "Editar",
    download_as: "Baixar",
    delete_form: "Excluir formulário",
    delete_form_alert: "Você deseja excluir este formulário? Essa ação não pode ser desfeita.",
    delete_quizzes_alert: "Quer excluir estes questionários? Essa ação não pode ser desfeita.",
    delete_forms_alert: "Deseja excluir esses formulários? Essa ação não pode ser desfeita.",
    delete_quiz_alert: "Você quer deletar este quiz? Essa ação não pode ser desfeita.",
    delete_answers_alert: "Você quer deletar essas respostas?",
    delete_answer_alert: "Você quer deletar esta resposta?",
    delete: "Excluir",
    delete_all: "Excluir tudo",
    section: "Seção",
    worst: "Pior",
    server_error_alert: "Não foi possível encontrar o servidor. Por favor, tente novamente mais tarde!!",
    signed_in_as: "Sessão iniciada como",
    signing_out: "Terminar sessão…",
    create_form: "Criar formulário",
    create_blank_form: "Criar formulário em branco",
    create_blank_quiz: "Criar questionário em branco",
    close: "Fechar",
    response_collection_exit_alert: "Deseja sair da coleta de resposta?",
    form_builder: "Construtor de formulários",
    form_builder_promo: "Pré-visualização instantânea, modelos e temas para suas pesquisas",
    results: "Resultados",
    result_feature_promo: "Análise e exporte os resultados da sua pesquisa",
    notification: "Notificação",
    delete_option_alert: "Deseja excluir esta opção?  [Nota: esta ação não pode ser desfeita]",
    question_type: "Tipo de pergunta",
    change_question_type_alert: "Toque aqui para alterar o tipo de pergunta",
    preview_not_available: "Pré-visualização não disponível",
    delete_question: "Excluir a pergunta",
    delete_response_collected_question_alert: "A exclusão desta pergunta certamente afetará as respostas coletadas.\n\nVocê realmente deseja excluir esta pergunta?",
    delete_question_alert: "Você realmente deseja excluir esta pergunta?",
    options: "Opções",
    option: 'Opção',
    no_answer: "Sem resposta",
    no_response: "Sem resposta",
    responses: "respostas",
    response: "resposta",
    serial_number: "S.não",
    submit_time: "Horário de envio",
    general_form_type_description: "Criar novo formulário / pesquisa",
    quiz_type_description: "Criar novo questionário",
    sheet_disconnected: 'Planilha Google desconectada',
    sheet_not_connected: 'Planilha não conectada',
    sheet_not_connected_desc: 'Entre em contato com o proprietário para conectar a planilha do Google.',
    export_success: "Exportado com sucesso",
    answer: "Responda",
    answer_with_colan: "Responda :",
    others: "Outras",
    delete_response: "Excluir resposta",
    deleted: "Excluído",
    my_themes: "Meus Temas",
    limit_reached: "Limite alcançado",
    limit_reached_description: "Você atingiu o limite de upload de imagens.",
    modified: "Modificado",
    delete_image: "Excluir imagem",
    delete_image_alert: "Você realmente deseja excluir esta imagem?",
    delete_theme_image_desc: "Deseja excluir esta imagem? Se você continuar, esta imagem não estará disponível para nenhum Formulário ou Questionário que a esteja usando. Essa ação não pode ser desfeita.",
    section_title: "Título da seção",
    section_description: "Descrição da seção",
    show_scale: 'Mostrar escala',
    show_options: "Mostrar opções",
    options_required: "Opções necessárias",
    link_copied: "Link copiado",
    app_not_found: "Aplicativo não encontrado",
    default_text: "Padrão",
    dark_mode: "Modo escuro",
    done: "Feito",
    upload_image: "Enviar Imagem",
    question: "Pergunta",
    required: "Obrigatório",
    share: "Compartilhar",
    form_link: "Link do formulário",
    copy_link: "Link de cópia",
    view_form: "Ver formulário",
    menu: "Menu",
    view_all: "Ver tudo",
    settings: "Configurações",
    submit: "Enviar",
    change_theme: "Alterar tema",
    tap_here_to_choose_theme: "Toque aqui para escolher o tema",
    show_logo: "Mostrar logotipo",
    show_logo_alert: "Deseja mostrar o logotipo no formulário?",
    welcome_page: "Página de boas-vindas",
    show_welcome_page_alert: "Deseja mostrar a página de boas-vindas?",
    start_button: "Botão de início",
    allow_multiple_responses: "Permitir múltiplas respostas",
    allow_more_response_alert: "Deseja permitir que o usuário responda mais de uma vez?",
    allow_more_quiz_attempt_alert: "Deseja permitir que o usuário tente mais de uma vez?",
    show_summary: "Mostrar Resumo",
    show_results: "Mostrar resultados",
    allow_responders_view_summary_alert: "Deseja permitir que os entrevistados vejam o resumo de todas as respostas após o envio?",
    allow_result_view_quiz_alert: "Deseja permitir que o usuário visualize resultados?",
    show_question_number: "Mostrar o número da pergunta",
    show_question_number_in_form: "Deseja mostrar os números das perguntas no formulário?",
    show_question_number_in_quiz: "Você quer mostrar os números das perguntas no quiz?",
    shuffle_questions: "Perguntas aleatórias",
    shuffle_questions_alert: "Deseja embaralhar as perguntas do formulário?",
    shuffle_quiz_questions_alert: "Você quer embaralhar as perguntas do questionário?",
    description: "Descrição",
    builder: "Construtor",
    preview: "Visualização",
    form_status: "Status formulário",
    active: "Ativo",
    inactive: "Inativo",
    import_questions: 'Perguntas de importação',
    search_by_question_title_from_forms: 'Pesquise pelo título da pergunta em formulários existentes',
    search_by_question_title_from_quizzes: 'Pesquise pelo título da pergunta em questionários existentes',
    view_responses: "Ver respostas",
    edit_form: "Editar formulário",
    duplicate_form: "Formulário duplicado",
    drafts: "Rascunhos",
    no_form_available: "Nenhum formulário disponível!",
    notifications: "Notificações",
    feedback: "Opinião",
    privacy_policy: "Política de Privacidade",
    open: 'Aberto',
    open_individual_responses: "Respostas individuais abertas",
    bar_chart: "GRÁFICO DE BARRAS",
    pie_chart: "GRÁFICO DE TORTA",
    summary: "Sumário",
    invalidated_form_cannot_be_selected: "Um Formulário Invalidado não pode ser selecionado.",
    invalidated_quiz_cannot_be_selected: "Um questionário inválido não pode ser selecionado.",
    individual: "Individual",
    search: "Pesquisar…",
    image: "Imagem",
    video: "Vídeo",
    website: "Site",
    exit: "Sair",
    response_limit_reached: "Limite de resposta atingido",
    response_limit_reached_alert: "Este formulário excedeu o limite permitido para dados de resposta; portanto, impedimos que este formulário aceite mais respostas.\n\nSe você realmente precisar continuar com a pesquisa existente, sugerimos que você a duplique e compartilhe o link.",
    unapproved_form: "Formulário não aprovado",
    unapproved_form_alert: "Este formulário está usando palavras que podem violar nossas políticas.\n\nSe você acredita que este formulário foi bloqueado por engano, abra o link abaixo e forneça as informações solicitadas no formulário.",
    choose_your_language: "Escolha o seu idioma",
    provide_explanation: "Fornecer explicação",
    selected: "selecionado",
    review_button: "Reveja",
    not_specified: "Não especificado",
    quiz_control: "Controle de teste",
    allow_multiple_attempts: "Permitir várias tentativas",
    user_info: "informação de usuário",
    name_s: "Nome",
    duration: "Duração",
    set_duration_quiz_alert: "Deseja definir uma duração para o questionário?",
    user_information_description: "Deseja obter informações dos participantes?",
    set_duration: "Definir duração (horas: minutos)",
    minutes: "Minutos",
    hours: "Horas",
    minute: "Minuto",
    hour: "Hora",
    correct: "Corrigir",
    wrong: "Errado",
    skipped: "Ignorado",
    score: "Ponto",
    forms: "Formulários",
    quizzes: "Testes",
    more: "Mais",
    quiz_status: "Status questionário",
    view_answers: "Ver respostas",
    view_quiz: "Ver questionário",
    duplicate_quiz: "Duplicar questionário",
    no_quiz_available: "Não há questionário disponível!",
    quiz_link: "Link do questionário",
    unapproved_quiz: "Questionário não aprovado",
    unapproved_quiz_alert: "Este questionário usa palavras que podem violar nossas políticas. Se você acredita que este questionário foi bloqueado por engano, abra o link do formulário abaixo e forneça as informações solicitadas no formulário.",
    do_you_want_to_show_all_correct_answers_to_users: "Você quer mostrar a resposta correta aos usuários?",
    results_publish_date: "Data de Publicação dos Resultados",
    disconnect: 'DESCONECTAR',
    do_you_want_to_set_date_to_publish_results: "Você quer definir uma data para publicar os resultados",
    quiz_result: "Resultado do questionário",
    enter_answer: "Responda",
    manage: "Gerenciar",
    manage_storage: 'Gerenciar armazenamento',
    select_all: 'Selecionar tudo',
    shuffle_options: 'Opções aleatórias',
    shuffle_options_enabled: 'Opções aleatórias ativadas',
    shuffle_options_disabled: 'Opções aleatórias desativadas',
    add_options_to_enable_this_feature: 'Adicione opções para ativar este recurso',
    attach: 'Anexar',
    shuffle: 'Embaralhar',
    marks: "Marcas",
    mark: "Marca",
    correction_status: "Status:",
    status_validated: "Validado",
    pending: "Pendente",
    results_publish: "Publicar",
    publish: "Publicar",
    publish_description: "Você quer publicar os resultados do questionário?",
    publish_description_note: "(As marcas serão publicadas no mesmo URL)",
    published_successfully: "Publicado com sucesso",
    choice_type_validation_note: "*Múltipla escolha validada automaticamente",
    short_answer_type_validation_note: "*Resposta curta validada automaticamente",
    long_answer_type_validation_note: "*Resposta longa NÃO validada automaticamente",
    fileupload_answer_type_validation_note: "*Upload de arquivo NÃO validado automaticamente",
    dropdown_answer_type_validation_note: "*Escolha suspensa validada automaticamente",
    save: "Salve ",
    questions: "Perguntas",
    show_correct_answer: "Mostrar resposta correta",
    show_answer: "Mostre a resposta",
    hide_answer: "Ocultar resposta",
    no_marks: "Nenhuma marca",
    question_is_required: "O título da pergunta é obrigatório",
    marks_is_required: "Marcas são obrigatórias",
    options_are_required: "As opções são obrigatórias",
    options_limit_reached: 'limite de opções atingido',
    options_limit_reached_description: 'Você atingiu o limite de opções permitidas em uma pergunta.',
    correct_answer_required: "A resposta correta é necessária",
    enter_valid_mark: "Insira uma marca válida",
    evaluated: "Avaliado",
    saved: "Salvou",
    pending_evaluation: "Avaliação Pendente",
    alphabet: "Alfabeto",
    not_evaluated: "Não avaliado",
    percentage_ascending: "Porcentagem - Crescente",
    percentage_descending: "Porcentagem - Decrescente",
    pending_evaluation_alert_message: "Você deve concluir a avaliação de todas as respostas antes de publicar.",
    show_unevaluated: "Mostrar não avaliado",
    publish_success_alert_message: "Os respondentes podem ver seus resultados em suas folhas de respostas correspondentes.",
    publishing: "Publicação",
    quiz_published: "Quiz Publicado",
    quiz_published_alert_before_edit_message: "Este questionário foi publicado, se você precisar fazer alterações, você deve duplicar o questionário e compartilhá-lo novamente.",
    quiz_has_answers: "Quiz tem respostas",
    quiz_has_answer_alert_message: "Para editar este questionário, você deve excluir todas as respostas existentes.",
    unavailable_features_description: 'Este formulário possui recursos que ainda não são suportados no Web App',
    unavailable_quiz_features_description: 'Este questionário possui recursos que ainda não são suportados no aplicativo Web',
    unavailable: 'Indisponível',
    unpublished: "Não publicado",
    published: "Publicados",
    setting_updated: "Configuração atualizada",
    account: "Conta",
    on: "EM",
    off: "FORA",
    scan_qr_code: "Leia o código QR",
    answers: "respostas",
    answer_s: "responda",
    question_limit_reached: "Limite de perguntas atingido",
    reached_limit_for_number_of_questions: "Você atingiu o limite de perguntas permitidas em um formulário. Portanto, nenhuma nova questão pode ser adicionada",
    life_time: "Tempo de vida",
    last_year: "Ano passado",
    last_30_days: "Últimos 30 dias",
    last_7_days: "Últimos 7 dias",
    last_24_hours: "Últimas 24 Horas",
    custom_date: "Data Personalizada",
    filter_by_date: "Filtrar por data",
    filter_by_question: "Filtrar por pergunta",
    filter_text: "Texto do filtro",
    add_filter: "Adicionar Filtro",
    apply: "Aplicar",
    from_date: "Da data",
    to_date: "Até a presente data",
    contains: "Contém",
    equal_to: "Igual a",
    not_equal_to: "Diferente de",
    from_date_required: "A partir da data necessária",
    to_date_required: "Até a data necessária",
    webapp_promo_text: "Acesse seus formulários, pesquisas, enquetes e questionários online!",
    question_title: "Pergunta",
    invalid_date: "Data inválida",
    invalid_url: "URL inválida",
    invalid_url_description: "A URL não parece ser válida.",
    delete_response_alert: "Você realmente deseja excluir esta resposta?",
    delete_responses_alert: "Você quer deletar essas respostas?",
    overview: "Visão geral",
    no_results_found: "Nenhum resultado encontrado",
    date_format: "dd-mm-yyyy",
    title_dashboard: "Painel",
    title_notifications: "Notificações",
    select_one_response: "Selecione pelo menos uma resposta para excluir",

//Untranslated strings

    your_account: "Sua conta",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    favourites: "Favoritos",
    shared: "Compartilhado",
    hide: "Esconder",
    use_templates: "Usar modelos",
    quick_settings: "Configurações rápidas",
    leave_collaboration: "Sair da Colaboração",
    templates: "Modelos",
    collapse: "Colapso",
    start_scale_from_zero: 'Iniciar escala de 0',
    center_label_optional: 'Rótulo central (opcional)',
    left_label_optional: 'Rótulo Esquerdo (Opcional)',
    right_label_optional: 'Rótulo direito (opcional)',
    duplicate: "Duplicado",
    row: "Linha",
    column: "coluna",
    rows: "Linhas",
    add_column: 'Adicionar coluna',
    add_row: 'Adicionar linha',
    user_info_for_file_upload: "Informações do usuário para uploads de arquivos",
    user_info_for_file_upload_desc1: "Como você adicionou uma pergunta de upload de arquivo, a opção de coletar informações do usuário foi habilitada. Isso tornará mais fácil identificar os arquivos baixados. Os entrevistados verão uma caixa de diálogo para inserir suas informações.",
    user_info_for_file_upload_desc2: "Você pode alterar esta opção em Configurações se não quiser que a caixa de diálogo seja exibida.",
    show_rows_and_columns: "Mostrar Linhas & colunas",
    scale_down: 'Diminuir gradativamente',
    scale_up: 'Ampliar',
    dislike: 'Não gosto',
    like: 'Como',
    best: 'Melhor',
    remove_favourite: "Remover favorito",
    add_favourite: "Adicionar favorito",
    collaborator: "Colaborador",
    collaborators: "contribuidores",
    show_more: "Mostre mais",
    create_quiz: "Criar questionário",
    collaborate: "Colaborar",
    share_QR_code: "Compartilhar código QR",
    add_image: "Adicionar imagem",
    invalid_file_type: "Tipo de arquivo inválido",
    drag_drop: "Arraste e solte",
    here: "Aqui",
    choose: "Escolher",
    add_account: 'Adicionar Conta',
    choose_account: 'Escolha a conta',
    maximum_allowed_file_size: "Tamanho máximo de arquivo permitido é ",
    or: "ou",
    next: "Próximo",
    change_image: "Alterar imagem",
    upload: "Envio",
    view: "Visão",
    collaboration: "Colaboração",
    share_collaboration_form: "Deseja compartilhar este formulário com outras pessoas?",
    share_collaboration_quiz: "Você quer compartilhar este quiz com outras pessoas?",
    multiple_responses_summary: "Várias respostas, resumo",
    question_number_shuffle: "Número da pergunta, Ponto de interrogação, Aleatório",
    general: "Em geral",
    browse: "Navegar",
    owner: "Proprietário",
    something_went_wrong: "Algo deu errado!",
    forget_your_password: "Esqueça sua senha?",
    sign_in: "Entrar",
    sign_up: "Inscrever-se",
    using_email: "usando e-mail",
    dont_have_account: "Não tem uma conta?",
    on_signing_up_agree_to_surveyhearts: "Ao se inscrever, você concorda com os termos da SurveyHeart",
    and: "e",
    terms_of_use: "Termos de uso",
    already_have_an_account: "Já tem uma conta?",
    signed_in_previously_as_a_google_user: "Você fez login anteriormente como usuário do Google.",
    sign_in_google_to_continue: "Faça login pelo Google para continuar.",
    no_account_found_email_address: "Nenhuma conta encontrada com este endereço de e-mail",
    account_already_exist_email: "A conta já existe para este e-mail.",
    install_surveyheart_app: "Instale o aplicativo SurveyHeart",
    easy_login_via_app: "Acesso fácil pelo aplicativo",
    get_notification_instantly: "Receba Notificação Instantaneamente",
    create_form_quiz_on_the_go: "Criar formulário/teste em qualquer lugar",
    check_your_inbox_to_activate_your_account: "Por favor, verifique sua caixa de entrada para ativar sua conta",
    select_questions: "Selecionar perguntas",
    percentage: "Percentagem",
    free_App_to_create_forms: "Obtenha o aplicativo gratuito para criar formulários",
    we_cant_find_the_page_that_you_are_looking_for: "Não encontramos a página que você está procurando",
    answered: "Respondidos",
    completing_this_quiz: "Obrigado por completar este quiz",
    connected_successfully: 'Conectado com sucesso',
    seconds: "Segundos",
    enter_your_details: "Insira seus dados",
    enter_all_required_information: "Por favor, insira todas as informações necessárias",
    answer_data_not_found: "Dados de resposta não encontrados",
    results_published_soon: "Os resultados serão publicados em breve",
    previous: 'anterior',
    save_link_for_future_reference: "Salve este link para referência futura",
    choice_questions_only: "Apenas perguntas de escolha",
    score_is_not_final: "Nota: Esta pontuação não é final",
    tabular: "Tabular",
    text: "texto",
    answer_response_alert: "Esta questão requer uma resposta",
    invalid_email_address: "Endereço de email invalido",
    answer_not_available: "Resposta não disponível",
    validated_automatically: "* validado automaticamente",
    answer_link: "Link de resposta",
    embed_code: 'Código embutido',
    copy_code: 'Copiar código',
    code_copied: 'Código copiado',
    share_via: "Compartilhar via",
    print: "Imprimir",
    columns: 'Colunas',
    columns_all: "Colunas (todas)",
    submitted_time: "Hora de envio",
    unable_to_submit: 'Não foi possível enviar',
    unable_to_submit_alert_line1: 'Como este questionário recebeu respostas, você tem acesso limitado para fazer alterações.',
    unable_to_submit_alert_line2: 'Para obter acesso total para editar este questionário, você deve excluir todas as respostas existentes.',
    action_blocked: 'Ação bloqueada',
    action_blocked_alert: 'Ação bloqueada devido a respostas existentes para este questionário. Permitir isso pode afetar as respostas anteriores.',
    quiz_edit_submit_alert: 'A edição do questionário pode afetar as notas e a porcentagem e exigir a reavaliação de determinadas questões.',
    continue: 'Continuar',
    submitted: "Submetido",
    submitting: "Enviando",
    filter: "Filtro",
    exit_screen: "Sair da tela",
    full_screen: "Tela cheia",
    tabular_view: "Visualização Tabular",
    clear_all: "Limpar tudo",
    you_are_all_caught_up: "Você está todo preso!",
    check_back_later_for_new_notifications: "Volte mais tarde para novas notificações.",
    requires_answer: "requer resposta",
    enter_member_id: "Insira o ID do membro",
    viewers_can_read_form_responses: "Os espectadores podem ler o formulário e ver as respostas",
    editors_edit_form_view_responses: "Os editores podem editar o formulário e visualizar as respostas",
    admin_edit_form_add_collaborator: "O administrador pode editar o formulário e adicionar colaborador",
    invalid_member_id: "ID de membro inválido",
    already_member_added: "Este membro já foi adicionado",
    add_collaborator: "Adicionar colaborador",
    admin: "Administrador",
    editor: "Editor",
    viewer: "Visualizador",
    searched_form_not_found: "Formulário pesquisado não encontrado!",
    searched_quiz_not_found: "Questionário pesquisado não encontrado!",
    no_shared_data_available: "Nenhum dado compartilhado disponível!",
    forms_server_is_not_available: "Servidor de formulários não está disponível",
    quiz_server_is_not_available: "O servidor de questionários não está disponível",
    leave: 'sair',
    password: 'senha',
    new_password: 'Nova Senha',
    retype_password: 'Redigite a senha',
    create_password: 'Criar senha',
    view_results: 'Ver resultados',
    control: 'Ao controle',
    q_control: 'Controle de quiz',
    q_result: 'resultado do quiz',
    no_matches_found: "Nenhuma equivalência encontrada",
    dropdown: "Suspenso",
    checkboxes: "Caixas de seleção",
    star_rating: 'Classificação por estrelas',
    linear_scale: 'Escala linear',
    multiple_choice_grid: 'Grade de Múltipla Escolha',
    checkboxes_grid: 'Grade de caixas de seleção',
    smile: 'Sorriso',
    info_1: "Informações 1",
    info_2: "Informações 2",
    info_3: "Informações 3",
    new: 'novo',
    download_all: 'Baixar tudo',
    download: 'Download',
    sort_by: 'Ordenar por',
    sorting: 'Ordenação',
    refresh: 'atualizar',
    add: 'adicionar',
    refresh_preview_description: "Clique no botão 'Atualizar' para atualizar a visualização",
    enter_users_email_address: 'Digite o e-mail',
    do_you_want_to_delete_these: 'Você deseja excluir estes',
    do_you_want_to_delete_this_form: 'Deseja excluir este formulário',
    all_responses_will_also_be_deleted: 'Todas as respostas também serão excluídas',
    this_action_cannot_be_undone: 'Essa ação não pode ser desfeita.',
    do_you_want_to_delete_this_quiz: 'Deseja excluir este teste',
    all_answer_sheets_will_also_be_unavailable: 'Todas as folhas de respostas também estarão indisponíveis',
    change: 'Mudar',
    roles_rights: 'Funções e Direitos',
    learn_more: 'Saber mais',
    viewers_can_read_quiz_and_view_answers: 'Os espectadores podem ler o questionário e ver as respostas',
    editors_can_edit_quiz_view_answers: 'Os editores podem editar o questionário e visualizar as respostas',
    admin_can_edit_quiz_add_collaborator: 'O administrador pode editar o questionário e adicionar o colaborador',
    change_roles: 'Alterar funções',
    remove: 'remover',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'Você não enviou suas alterações. Tudo bem sair sem se submeter?',
    select_language: 'Selecione o idioma',
    are_you_sure_you_want_delete_this_collaborator: 'Tem certeza de que deseja excluir este colaborador?',
    are_you_sure_you_want_to_delete_selected_files: 'Tem certeza de que deseja excluir permanentemente o arquivo selecionado?',
    please_download_before_deleting: 'Faça o download antes de excluir. Os arquivos, uma vez excluídos, não podem ser recuperados!',
    role_has_been_changed_successfully: 'a função foi alterada com sucesso',
    do_you_want_to_leave_collaboration: 'Quer sair da colaboração?',
    form_builder_access_denied: 'Acesso negado ao Form Builder',
    quiz_builder_access_denied: 'Acesso negado ao criador de questionários',
    you_only_have_viewer_rights_for_quiz: 'Você só tem direitos de espectador para este teste.',
    you_only_have_viewer_rights_for_form: 'Você só tem direitos de visualizador para este formulário.',
    kindly_contact_the_form_owner_for_more_info: 'Por favor, entre em contato com o proprietário do formulário para obter mais informações.',
    kindly_contact_the_quiz_owner_for_more_info: 'Por favor, entre em contato com o proprietário do questionário para obter mais informações.',
    incomplete_questions: 'Perguntas incompletas:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'Você tem alterações não salvas - tem certeza de que deseja sair desta página?',
    languages: 'Linguagem',
    copy: "cópia de",
    not_good: 'não é bom',
    not_allowed: 'não permitido',
    not_allowed_description: 'O tipo de pergunta de escolha não pode ser alterado após a coleta das respostas.',
    neutral: 'neutra',
    good: 'boa',
    very_good: 'muito bom',
    edit_rights_removed: 'Editar direitos removidos',
    your_rights_to_edit_this_form_have_been_removed: "Seus direitos para editar este formulário foram removidos.",
    your_rights_to_edit_this_quiz_have_been_removed: "Seus direitos para editar este quiz foram removidos.",
    please_contact_quiz_owner_for_more_info: "Entre em contato com o proprietário do formulário para obter mais informações.",
    please_contact_form_owner_for_more_info: "Entre em contato com o proprietário do questionário para obter mais informações.",
    last_edited_on: 'Última edição em',
    last_edited: 'Última edição',
    by: 'por',
    you: "Você",
    add_option: 'Adicionar opção',
    add_other_option: "Adicionar opção 'Outro'",
    estimated_time: "Tempo estimado",
    do_you_want_to_show_estimated_time_taking_quiz: "Deseja mostrar o tempo estimado para fazer o teste ?",
    do_you_want_to_show_estimated_time_taking_form: "Deseja mostrar o tempo estimado para preenchimento do formulário ?",
    show_question_count: " Mostrar contagem de perguntas",
    do_you_want_to_show_total_number_questions_quiz: "Deseja mostrar o número total de perguntas no questionário ?",
    do_you_want_to_show_total_number_questions_form: "Deseja mostrar o número total de perguntas no formulário ?",
    show_question_marks: "Mostrar contagem de perguntas",
    show_question_marks_quiz: "Mostrar pontos de interrogação no quiz",
    you_dont_have_add_collaborator_permission: "Você não tem permissão para adicionar colaborador",
    modified_date: "Data modificada",
    created_date: "Data de criação",
    question_cant_be_added: "A PERGUNTA NÃO PODE SER ADICIONADA",
    another_file_upload_question_cant_added: "OUTRA PERGUNTA DE UPLOAD DE ARQUIVO NÃO PODE SER ADICIONADA",
    you_can_have_only: "Você pode ter apenas",
    file_upload_questions_in_form: "Perguntas de upload de arquivo em um formulário",
    file_upload_questions_in_quiz: "Perguntas sobre upload de arquivo em um quiz",
    storage_capacity_exceeded: "Capacidade de armazenamento excedida",
    kindly_inform_form_builder_that: "Por favor, informe o Form Builder que",
    kindly_inform_quiz_builder_that: "Por favor, informe o Criador de questionários que",
    their_storage_capacity_file_uploads: "sua capacidade de armazenamento para uploads de arquivos",
    has_been_exceeded: "Foi excedido",
    view_details: "VER DETALHES",
    paste_your_video_link_here: "Cole o link do seu vídeo aqui",
    paste_your_website_link_here: "Cole o link do seu site aqui",
    view_answer_sheet: "Ver folha de respostas",
    response_count: "Contagem de respostas",
    count: "contar",
    password_least_8_characters: "A senha deve conter pelo menos 8 - 50 caracteres",
    password_contain_least_1_number: "A senha deve conter pelo menos 1 número",
    password_contain_least_1_capital_letter: "A senha deve conter pelo menos 1 letra maiúscula",
    password_contain_least_1_special_character: "A senha deve conter pelo menos 1 caractere especial",
    please_fill_all_required_fields: "Por favor, preencha todos os campos obrigatórios",
    passwords_dont_match: "As senhas não correspondem",
    incorrect_password: "Senha incorreta",
    click_or_change_current_theme: "Clique no tema atual ou em 'Alterar' abaixo para escolher um tema diferente",
    select: "Selecionar",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "Deseja excluir esta imagem? Se você continuar, esta imagem não estará disponível para nenhum Formulário ou Questionário que a esteja usando. Essa ação não pode ser desfeita.",
    url_does_not_seem_to_valid: "A URL não parece ser válida.",
    you_have_reached_image_uploaded_limit: "Você atingiu o limite de upload de imagens.",
    feature_spotlight: "Destaque do recurso",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "Nota: Para ver esta informação novamente, clique no 'Mais' no menu na esquerda.",
    undo: "Desfazer",
    response_deleted: "Resposta excluída",
    question_deleted: "Pergunta excluída",
    answer_deleted: "Resposta excluída",
    responses_deleted: "Respostas excluídas",
    questions_deleted: "Perguntas excluídas",
    answers_deleted: "Respostas excluídas",
    collect_email_header: "Recolher EMAIL",
    collect_email_alert_message: "A ativação desta funcionalidade obrigará o inquirido a entrar com o seu endereço Gmail para preencher o formulário.",
    collect_email_alert_note: "NOTA: Apenas a conta Google é suportada",
    allow_response_edit: "Permitir edição de resposta",
    permitir_response_edit_description: "Deseja permitir que os respondentes editem as respostas após o envio?",
    enter_desc_here: "Digite a descrição aqui",
    passcode_protection: "Proteção por senha",
    passcode_protection_description_quiz: "Você quer que seu teste seja protegido por senha?",
    passcode_protection_description: "Você deseja que seu formulário seja protegido por senha?",
    crop_image: "Cortar imagem",
    crop: "Cortar",
    original: "Original",
    collect_email: "Coletar endereço de e-mail",
    collect_email_description: "Você deseja reunir os endereços de e-mail dos entrevistados para possível referência ou utilização futura?",
    collect_email_description_quiz: "A ativação desta funcionalidade obrigará os respondentes a iniciar sessão com o seu endereço Gmail para preencher o questionário.",
    collect_email_description_form: "A ativação desta funcionalidade obrigará os respondentes a entrar com o seu endereço Gmail para preencher o formulário.",
    opening_date: 'Data de abertura',
    closing_date: 'Data de encerramento',
    schedule_form_status: 'Status do Formulário de Agendamento',
    schedule_quiz_status: 'Agendar status do questionário',
    schedule_opening_date_and_time: "Agendar data e hora de abertura",
    schedule_closing_date_and_time: "Data e hora de encerramento do cronograma",
    schedule_opening_date_and_time_description: "Ative o formulário na data e hora mencionadas",
    schedule_opening_date_and_time_description_quiz: "Ative o quiz na data e hora mencionadas",
    schedule_closing_date_and_time_description: "Desative o formulário na data e hora mencionadas",
    schedule_closing_date_and_time_description_quiz: "Desative o quiz na data e hora mencionadas",
    upgrade: "Atualização",
    gold_plan: "Plano Ouro",
    view_plans: "Ver planos",
    premium_plans: "Planos Premium",
    plan_duration: "/ Mês",
    plan_logic_jump: "Salto lógico",
    plan_remove_ads: "Remover anúncios",
    cancel_subscription: "Cancelar subscrição",
    quiz_has_answer_edit_message: "Este questionário já foi enviado e as respostas foram recolhidas. A edição do questionário pode afetar as respostas anteriores.",
    quiz_published_alert_edit_message: "Este questionário foi publicado. Se editar, este questionário será despublicado.",
    quiz_submit_after_edit_message: "A edição do questionário pode afetar as notas e a percentagem e exigir a reavaliação de determinadas questões.",
    title_activity_privacy_policy: "Política de PrivacidadeAtividade",
    action_blocked_message: "Ação bloqueada devido às respostas existentes para este questionário. Permitir isto pode afetar as respostas anteriores.",
    email_addresses: "Endereço de e-mail",
    confirmation_message: "Mensagem de confirmação",
    confirmation_message_description: "Deseja mostrar mensagem de confirmação após submeter o formulário?",
    confirmation_message_description_quiz: "Deseja mostrar uma mensagem de confirmação depois de responder ao questionário?",
    default_custom_thank_message: "Obrigado por preencher este formulário.",
    default_custom_thank_message_quiz: "Obrigado por completar este teste.",
    upgrade_your_plan: "Atualize o seu plano!",
    upgrade_your_plan_1: "Atualize o seu plano",
    upgrade_your_plan_content: "Atualize a versão premium para aceder a esta funcionalidade e aumentar o tamanho do armazenamento, etc.",
    alert_icon: "Ícone de alerta",
    usage_details: "Detalhes de utilização",
    current_plan: "Plano atual",
    total_submission: "Submissão Total",
    image_attachments: "Anexos de imagem",
    custom_themes: "Temas personalizados",
    free_limit_reached: "Atingiu o limite gratuito -",
    payment_done_but_server_error: "O seu pagamento foi deduzido com sucesso. Abra a aplicação passado algum tempo para que possamos verificar o seu pagamento.",
    response_quota_reached: "Atingiu o limite gratuito, por favor atualize o seu plano para aceder a todas as respostas.",
    answers_quota_reached: "Atingiu o limite gratuito, por favor atualize o seu plano para aceder a todas as respostas.",
    logic_jump_enabled: "Salto lógico ativado.",
    logic_jump_disabled: "Salto lógico desativado.",
    cancel_subscription_message: "Tem a certeza de que pretende cancelar a sua subscrição? O cancelamento da subscrição resultará na perda de acesso a todas as funcionalidades premium.",
    plan_expired: "O seu plano expirou. Atualize o seu plano para utilizar este rascunho.",
    plan_expired_edit_form: "O seu plano expirou. Atualize o seu plano para editar este formulário",
    plan_expired_edit_quiz: "O seu plano expirou. Atualize o seu plano para editar este questionário",
    plan_expired_duplicate_form: "O seu plano expirou. Atualize o seu plano para duplicar este formulário",
    plan_expired_duplicate_quiz: "O seu plano expirou. Atualize o seu plano para duplicar este teste",
    premium_features: "Recurso Premium",
    plan_file_upload: "Imagens de temas personalizadas ilimitadas",
    plan_custom_theme: "Imagens de temas personalizadas ilimitadas",
    plan_call_support: "Suporte de chamadas de alta prioridade",
    plan_email_support: "Suporte por e-mail de alta prioridade",
    plan_custom_domain: "Domínio personalizado",
    subscribe: "Inscrever-se",
    plan_remove_branding: "Remover marca",
    plan_on_call_support: "Apoio de plantão",
    plan_image_attachment: "Anexos de imagens ilimitadas",
    plan_custom_thank_you: "Páginas de agradecimento personalizáveis",
    plan_allowed_responses: "Contagem de respostas permitidas 50.000",
    trusted_by_number_of_user: "Fiável por mais de 5 milhões de utilizadores",
    email_support: "Suporte por e-mail",
    call_support: "Ligue para o apoio",
    keyboard_shortcuts: "Atalhos do teclado",
    premium_limit_reached: "Atingiu o limite do plano. Atualize o seu plano para aceder a todos os recursos premium.",
    premium_limit_reached_mobile: "Atingiu o limite do plano.",
    unlimited: "Ilimitado",
    expired_on_next_renewal: "Expirará na próxima data de renovação",
    upgrade_plan: "Plano de atualização",
    downgrade_plan: "Plano de downgrade",
    file_upload_questions: "Perguntas sobre upload de ficheiros",
    plan_response_edit: "Edição de resposta",
    cant_process: "Não é possível processar",
    you_have_subscribed_through_android: "Inscreveu-se por meio de nosso aplicativo Android. Utilize a aplicação SurveyHeart para quaisquer atualizações relacionadas com pagamentos.",
    pay_now: "Pague agora",
    update_payment_details: "Atualizar os dados de pagamento",
    limit_reached_alert_message: "Você atingiu o limite gratuito, por favor atualize seu plano para acessar todas as respostas.",
    limit_reached_alert_message_quiz: "Você atingiu o limite gratuito, por favor atualize seu plano para acessar todas as respostas.",
    limit_reached_UI_message: "As respostas não estarão disponíveis para o seu plano atual. Atualize seu plano para ver todas as respostas.",
    limit_reached_UI_message_quiz: "As respostas não estarão disponíveis para o seu plano atual. Atualize seu plano para ver todas as respostas.",
    owner_is_not_premium_user: "O proprietário não é um usuário premium",
    cancel_subscription_description: "Tem certeza de que deseja cancelar sua assinatura? O cancelamento resultará na perda de acesso a todos os recursos premium.",
    starter_storage_description: "Obtenha 5 GB de armazenamento para uma gestão de dados sem esforço.",
    bronze_storage_description: "Obtenha 25 GB de armazenamento para uma gestão de dados sem esforço.",
    silver_storage_description: "Obtenha 50 GB de armazenamento para uma gestão de dados sem esforço.",
    gold_storage_description: "Obtenha 150 GB de armazenamento para uma gestão de dados sem esforço.",
    starter_responses_description: "Utilize 10.000 respostas para obter insights poderosos da investigação.",
    bronze_responses_description: "Utilize 35.000 respostas para obter insights poderosos da investigação.",
    silver_responses_description: "Utilize 75.000 respostas para obter insights poderosos da investigação.",
    gold_responses_description: "Utilize 1.50.000 respostas para obter insights poderosos da investigação.",
    starter_image_description: "Melhore as suas pesquisas com até 100 imagens para um apelo visual.",
    bronze_image_description: "Melhore as suas pesquisas com até 200 imagens para um apelo visual.",
    silver_image_description: "Melhore as suas pesquisas com até 500 imagens para um apelo visual.",
    gold_image_description: "Melhore as suas pesquisas com imagens ilimitadas para um apelo visual.",
    starter_theme_description: "Adicione 100 imagens personalizadas para personalizar as suas pesquisas para a sua marca.",
    bronze_theme_description: "Adicione 200 imagens personalizadas para personalizar as suas pesquisas para a sua marca.",
    silver_theme_description: "Adicione 500 imagens personalizadas para personalizar as suas pesquisas para a sua marca.",
    gold_theme_description: "Adicione imagens personalizadas ilimitadas para personalizar as suas pesquisas para a sua marca.",
    custom_theme_image: "Imagem de tema personalizada",
    starter_upload_description: "Integre perfeitamente 4 perguntas de carregamento de ficheiros",
    file_upload_description: "Integre perfeitamente perguntas ilimitadas sobre upload de ficheiros.",
    logic_jump_description: "Utilizar saltos lógicos para personalizar o fluxo do inquérito de acordo com a experiência de cada inquirido",
    Thank_you_message_description: "Personalize as páginas de agradecimento para impressionar os respondentes.",
    response_addon_description: "Obtenha respostas adicionais para reunir mais insights.",
    response_edit_description: "Refine respostas facilmente utilizando a funcionalidade Editar Resposta.",
    remove_branding_description: "Eleve a presença da sua marca removendo a marca SurveyHeart.",
    bronze_remove_ads_description: "Fique sem anúncios com o nosso Plano Bronze e concentre-se nas suas pesquisas.",
    silver_remove_ads_description: "Fique sem anúncios com o nosso Plano Silver e concentre-se nas suas pesquisas.",
    gold_remove_ads_description: "Fique sem anúncios com o nosso Plano Gold e concentre-se nas suas pesquisas.",
    bronze_email_support_description: "Aceda ao suporte por e-mail de baixa prioridade, garantindo assistência atempada.",
    silver_email_support_description: "Aceda ao suporte por e-mail de prioridade média, garantindo assistência atempada.",
    gold_email_support_description: "Aceda ao suporte por e-mail de alta prioridade, garantindo assistência atempada.",
    bronze_email_support_title: "Suporte por e-mail de baixa prioridade",
    silver_email_support_title: "Suporte por e-mail de prioridade média",
    gold_email_support_title: "Suporte por e-mail de alta prioridade",
    update_your_payment_details_mobile: "Por favor, atualize os seus dados de pagamento.",
    update_your_payment_details_pc: "Estamos a ter alguns problemas com a renovação desta conta. Atualize os seus dados de pagamento.",
    card_details_updated_successfully: "Detalhes do cartão atualizados com sucesso",
    card_details_updated_successfully_description: "Os dados do seu cartão foram atualizados com sucesso. Agora pode continuar a utilizar as suas informações de pagamento atualizadas.",
    payment_successful: "Pagamento realizado com sucesso",
    payment_successful_description: "O seu pagamento foi realizado com sucesso. Agora está inscrito no plano premium da SurveyHeart. Sinta-se confortável e desfrute de todas as características incríveis que estão disponíveis.",
    payment_failed: "Falha no pagamento!",
    payment_failed_description: "Infelizmente. Não foi possível processar a sua subscrição do plano premium da SurveyHeart. Tente novamente.",
    note_for_five_rupees: "Nota: há uma cobrança de Rs. 5 para atualizar os dados do seu cartão, que \n será reembolsada dentro de 3 a 4 dias úteis para a mesma conta.",
    logic: "Lógica",
    re_generate_code: "Gerar novamente código",
    plan_change_restrictions: "Restrições à alteração do plano",
    plan_change_restriction_desc_1: "Não pode alterar o seu plano utilizando UPI.",
    plan_change_restriction_desc_2: "Mude para um cartão para upgrades e downgrades ou cancele e subscreva novamente o plano pretendido.",
    update_card_details: "Atualizar os dados do cartão",
    features_and_plans: "Recursos e Planos",
    cancel_plan: "Cancelar plano",
    cancel_plan_desc: "Cancelou o plano. Continuará a ter acesso aos recursos pagos até à data de renovação.",
    explore_text: "Explore e desbloqueie hoje mesmo funcionalidades excecionais num dos nossos planos de subscrição premium",
    cant_edit_this_form: "Impossible de modifier ce formulaire",
    cant_edit_this_quiz: "Impossible de modifier ce questionnaire",
    cant_duplicate_this_form: "Impossible de dupliquer ce formulaire",
    cant_duplicate_this_quiz: "Não é possível duplicar este questionário",
    kindly_upgrade_the_premium: "Atualize a versão premium para aceder a esta funcionalidade.",
    check_box: 'Caixa de selecção',
    continue_to_next_question: "Continuar para a próxima questão",
    add_logic: "Adicionar Lógica",
    submit_form: "Submeter formulário",
    save_logic: "Guardar Lógica",
    remove_logic: "Remover Lógica",
    responses_add_ons: "Complemento de respostas",
    responses_add_available: "Complemento de respostas disponível",
    additional_submissions: " Envios Adicionais",
    validity: " Validade",
    up_to_your_current_plan: " Até o seu plano atual",
    responses_add_ons_description: " Estas contagens de respostas serão adicionadas ao seu plano existente.",
    redirect_url_title: " Redirecionar para o link pretendido",
    redirect_url_description: " Pretende redirecionar os seus utilizadores para uma página externa?",
    enter_weblink: " Insira o link da web",
    get: "Obter",
    jump_to: "Ir para",
    action_cannot_be_performed: "A ação não pode ser executada",
    upgrade_plan_desc1: "Quer atualizar o seu plano para",
    downgrade_plan_desc1: "Pretende fazer o downgrade do seu plano para",
    downgrade_plan_desc2: "Esta alteração entrará em vigor na próxima data de renovação.",
    upgraded_plan: "Plano atualizado",
    downgraded_plan: "Plano rebaixado",
    upgraded_plan_desc: "Atualizou o seu plano. Esta alteração entrará em vigor na próxima data de renovação.",
    downgraded_plan_desc: "Fez downgrade do seu plano. Esta alteração entrará em vigor na próxima data de renovação.",
    buy_responses: "Compre respostas",
    passcode: "Senha",
    share_link_with_passcode: "Partilhar link com senha",
    contact_us: "Contate-nos",
    premium_limit_reached_for_gold: "Atingiu o limite do plano. Por favor, contacte-nos para aceder a todos os recursos premium.",
    follow_us: "Siga-nos",
    billing_history: "Histórico de cobrança",
    your_current_plan: "O seu plano atual",
    next_billing_date: "Próxima data de cobrança",
    transaction_id: "ID da transação",
    plan: "Plano",
    price: "Preço",
    status: "Estatuto",
    follow_us_desc: "Mantenha-se atualizado, conectado e interaja connosco",
    ranking_choice: 'Escolha de classificação',
    ranking: 'Classificação',
    picture_choice: 'Escolha de imagem',
    agreement: 'Acordo',
    signature: "Assinatura",
    slider: 'Controle deslizante',
    min: 'Mínimo',
    max: 'Máx.',
    step_value: "Valor do passo",
    scale_range_error: "O intervalo de escala deve ter um valor entre -10.000 e 10.000.",
    scale_range_error2: "O valor da Escala Máxima é inferior ao valor aceitável.",
    max_min_error: "O valor da Escala Mínima deve ser inferior à escala Máxima.",
    step_error: "O tamanho do passo deve ser um valor entre",
    add_picture: 'Adicionar imagem',
    terms_and_conditions: "Termos e Condições",
    terms_and_conditions_required: "Termos e Condições necessários",
    i_agree: "Concordo",
    i_disagree: "Discordo",
    yearly: "Anual",
    monthly: "Mensal",
    save_upto_20: "Poupe até 20% com planos anuais",
    paid: "Pago",
    cancelled: "Cancelado",
}