import React, {useState} from 'react'
import "../css/premium-plan-detail.css"
import cross from '../../../../public/images/cross_red.svg'
import blueTick from "../../../../public/images/tick_blue.svg"
import whiteTick from "../../../../public/images/icons/tick_white_mark.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {formatBytes, getPlanBasedOnRank, PremiumJSON} from "../../../shared/utils/helper";
import PayNowPopUp from "./PayNowPopUp";
import {
    createSubscriptionId,
    getSubscriptionId,
    upgradeSubscriptionPlan
} from "../../../shared/utils/sash-v2-api";
import {useSelector} from "react-redux";
import ModalDialog from "../../../shared/components/ModalDialog";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const PremiumPlanDetail = (props) => {
    const {t} = useTranslation()
    const [showPayNow, setShowPayNow] = useState()
    const [subscriptionId, setSubscriptionId] = useState()
    const [showUpgradePopUp, setShowUpgradePopUp] = useState()
    const [showUpgradedPlanPopUp, setShowUpgradedPlanPopUp] = useState()
    const [showCrossPlatformPopUp, setShowCrossPlatformPopUp] = useState()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)

    const getSubscribeButtonText = () => {
        if (subscriptionData.subscription_plan === "premium-on-hold") {
            return t(translationKeys.subscribe).toUpperCase()
        } else if (subscriptionData.subscription_plan === props.premiumPlan && subscriptionData.is_canceled) {
            return t(translationKeys.expired_on_next_renewal)
        } else if (subscriptionData.subscription_plan === props.premiumPlan) {
            return t(translationKeys.cancel_subscription)
        } else if (subscriptionData.subscription_plan !== "free-user" && !subscriptionData.is_canceled) {
            if (props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank) {
                return t(translationKeys.upgrade_plan)
            } else {
                return t(translationKeys.downgrade_plan)
            }
        } else {
            return t(translationKeys.subscribe).toUpperCase()
        }
    }

    const successFunction = (res) => {
        if (res?.result) {
            setSubscriptionId(res.subscription_id)
            setShowPayNow(true)
            props.setShowLoading(false)
        }
    }
    const failureFunction = (err) => {
        if (err?.data?.result === "subscriptions cannot be updated when payment mode is upi") {
            props.setShowLoading(false)
            props.setShowChangeUPI(true)
        } else {
            alert('Something went wrong!')
        }
    }

    const handleCardClick = () => {
        if (subscriptionData.subscription_plan === "free-user" || subscriptionData.subscription_platform === "WEBAPP") {
            if (props.premiumPlan === "free-user") {
                return null
            } else if (subscriptionData.subscription_plan !== "free-user" && subscriptionData.is_canceled) {
                props.setShowCanceledPlanPopUp(true)
            } else if (!subscriptionData || subscriptionData.subscription_plan === "free-user") {
                props.setShowLoading(true)
                createSubscriptionId(props.premiumPlan, successFunction, failureFunction)
            } else {
                getSubscriptionId(successFunction, failureFunction)
            }
        } else {
            setShowCrossPlatformPopUp(true)
        }
    }

    return (
        <>
            <div className={"premium-plan-detail"} onClick={handleCardClick}
                 style={{
                     cursor: props.premiumPlan === "free-user" ? "default" : null,
                     boxShadow: props.premiumPlan === "free-user" ? "0 4px 8px rgba(0, 0, 0, 0.25)" : null,
                     border: subscriptionData.subscription_plan === props.premiumPlan && props.premiumPlan !== "free-user" ? "1px solid #004BA0" : null
                 }}>
                <div className={"plan-detail-header"}>
                    <h3>{getPlanBasedOnRank(props.premiumPlanData.rank)}</h3>
                    <span>{props.premiumPlanData.price ? "₹ " + props.premiumPlanData.price + " / month" : "Free"}</span>
                    {subscriptionData.subscription_plan === props.premiumPlan ? <div className={"current-plan-tag"}>
                        <img src={whiteTick} alt={"current"}/>
                        <span>Current plan</span>
                    </div> : null}
                </div>
                <div className={"plan-detail-body"}>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.storage)} {formatBytes(props.premiumPlanData.storage, 0, true, "GB")}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.response_count)} {props.premiumPlanData.response_limit.access} / {t(translationKeys.life_time)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{props.premiumPlanData.question_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.question_attachments} {t(translationKeys.image_attachments)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{props.premiumPlanData.theme_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.theme_attachments} {t(translationKeys.custom_themes)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{props.premiumPlanData.file_upload_questions_per_form === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.file_upload_questions_per_form} {t(translationKeys.file_upload_questions)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.logic_jump ? blueTick : cross} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.plan_logic_jump)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.customizable_thank_you ? blueTick : cross}
                                 alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.plan_custom_thank_you)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.response_edit ? blueTick : cross} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.plan_response_edit)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.email_support_rank ? blueTick : cross} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.email_support)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.on_call_support ? blueTick : cross} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.call_support)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.advertising ? cross : blueTick} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.plan_remove_ads)}</span>
                    </div>
                    <div className={"detail-row"}>
                        <div className={"tick-cross-image"}>
                            <img src={props.premiumPlanData.remove_branding ? blueTick : cross} alt={"tick/cross"}/>
                        </div>
                        <span>{t(translationKeys.plan_remove_branding)}</span>
                    </div>
                </div>
                {subscriptionData.subscription_plan === "premium-on-hold" ? null :
                    <div style={{display: "flex", justifyContent: "center", height: "70px", alignItems: "center"}}>
                        {props.premiumPlan === "free-user" || (subscriptionData.subscription_plan !== props.premiumPlan && subscriptionData.subscription_plan !== "free-user" && subscriptionData.is_canceled) ? null :
                            <MaterialButton data={{
                                title: getSubscribeButtonText(),
                                customButtonContainerStyle: {
                                    borderRadius: '50px',
                                    backgroundColor: subscriptionData.subscription_plan === props.premiumPlan ? 'transparent' : '#004BA0',
                                    boxShadow: subscriptionData.subscription_plan === props.premiumPlan ? "none" : null
                                },
                                customButtonTextStyle: {
                                    fontFamily: 'Nunito Sans, Sans-serif, serif',
                                    fontSize: '14px',
                                    textDecoration: subscriptionData.subscription_plan === props.premiumPlan ? "underline" : null,
                                    color: subscriptionData.subscription_plan === props.premiumPlan ? "#4D4D4D" : "#FFFFFF"
                                }
                            }} handleClick={(e) => {
                                e.stopPropagation()
                                if (subscriptionData.subscription_plan === "free-user" || subscriptionData.subscription_platform === "WEBAPP") {
                                    if (subscriptionData.subscription_plan === "premium-on-hold") {
                                        getSubscriptionId(successFunction, failureFunction)
                                    } else if (subscriptionData.subscription_plan === props.premiumPlan && subscriptionData.is_canceled) {
                                        props.setShowCanceledPlanPopUp(true)
                                    } else if (subscriptionData.subscription_plan === props.premiumPlan) {
                                        props.setShowCancelPopUp(true)
                                    } else if (subscriptionData.subscription_plan !== "free-user") {
                                        setShowUpgradePopUp(true)
                                    } else {
                                        props.setShowLoading(true)
                                        createSubscriptionId(props.premiumPlan, successFunction, failureFunction)
                                    }
                                } else {
                                    setShowCrossPlatformPopUp(true)
                                }
                            }}
                            />
                        }
                    </div>
                }
            </div>
            {showPayNow ? <PayNowPopUp setShowPayNow={setShowPayNow} subscriptionId={subscriptionId}
                                       setShowUpgradePopUp={setShowUpgradePopUp}
                                       setShowLoading={props.setShowLoading}
                                       premiumPlanData={props.premiumPlanData}/> : null}
            {showUpgradedPlanPopUp ? <ModalDialog
                header={`${props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgraded_plan).toUpperCase() : t(translationKeys.downgraded_plan).toUpperCase()}`}
                body={[`${props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgraded_plan_desc) : t(translationKeys.downgraded_plan_desc)}`]}
                buttons={[
                    {
                        text: t(translationKeys.done).toUpperCase(),
                        action: () => {
                            setShowUpgradedPlanPopUp(false)
                        }
                    }
                ]}
            /> : null}
            {showUpgradePopUp ? <ModalDialog
                header={`${props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgrade_plan).toUpperCase() : t(translationKeys.downgrade_plan).toUpperCase()}`}
                body={[`${props.premiumPlanData.rank > PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgrade_plan_desc1) : t(translationKeys.downgrade_plan_desc1)} ${getPlanBasedOnRank(props.premiumPlanData.rank)}. ${t(translationKeys.downgrade_plan_desc2)}`]}
                buttons={[
                    {
                        text: t(translationKeys.no).toUpperCase(),
                        action: () => {
                            setShowUpgradePopUp(false)
                        }
                    },
                    {
                        text: t(translationKeys.yes).toUpperCase(),
                        action: () => {
                            props.setShowLoading(true)
                            setShowUpgradePopUp(false)
                            upgradeSubscriptionPlan(props.premiumPlan, (res) => {
                                if (res?.result) {
                                    props.setShowLoading(false)
                                    setShowUpgradedPlanPopUp(true)
                                }
                            }, failureFunction)
                        }
                    }
                ]}
            /> : null}
            {showCrossPlatformPopUp ? <ModalDialog header={t(translationKeys.cant_process).toUpperCase()}
                                                   body={[t(translationKeys.you_have_subscribed_through_android)]}
                                                   buttons={[
                                                       {
                                                           text: t(translationKeys.ok).toUpperCase(),
                                                           action: () => {
                                                               setShowCrossPlatformPopUp(false)
                                                           }
                                                       }
                                                   ]}
            /> : null}
        </>
    )
}

export default PremiumPlanDetail
