import premiumHold from "../../../public/images/premium-hold.svg";
import goldCrown from "../../../public/images/crown_gold.svg";
import silverCrown from "../../../public/images/crown_silver.svg";
import bronzeCrown from "../../../public/images/crown_bronze.svg";

export const REQUEST_METHOD = {
    POST: "POST",
    GET: "GET",
    DELETE: "DELETE",
    PUT: "PUT"
};

export const RESPONSE_CODE = {
    BLOCKED_USER: 450,
    FORBIDDEN: 403,
    SUCCESS: 200
}

const ESTIMATED_TIME = {
    SHORT_TEXT: 60,
    LONG_TEXT: 600,
    EMAIL: 30,
    NUMBER: 60,
    MULTIPLE_CHOICE: 60,
    CHECKBOX_CHOICE: 60,
    DROPDOWN_CHOICE: 60,
    STAR_RATING_SCALE: 60,
    LINEAR_SCALE: 60,
    SMILEY: 60,
    MULTIPLE_CHOICE_GRID: 120,
    CHECKBOX_GRID: 120,
    DATE: 60,
    TIME: 60,
    SLIDER: 60,
    AGREEMENT: 60,
    PICTURE_QUESTION: 60,
    RANKING_CHOICE: 120,
    SIGNATURE_QUESTION: 120,
    FILE_UPLOAD: 120
}

// 'ESTIMATED_TIME_KEY' was created because if 'import {QUESTION_TYPE} from "./constants"' and 'QUESTION_TYPE.SHORT_TEXT.key',
// are used, localization doesn't work for some unknown reason.
const ESTIMATED_TIME_KEY = {
    SHORT_TEXT: "SHORT_TEXT",
    LONG_TEXT: "LONG_TEXT",
    EMAIL: "EMAIL",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    CHECKBOX_CHOICE: "CHECKBOX_CHOICE",
    DROPDOWN_CHOICE: "DROPDOWN_CHOICE",
    CHECKBOX_GRID: "CHECKBOX_GRID",
    LINEAR_SCALE: "LINEAR_SCALE",
    SLIDER: "SLIDER",
    AGREEMENT: "AGREEMENT",
    SIGNATURE_QUESTION: "SIGNATURE_QUESTION",
    RANKING_CHOICE: "RANKING_CHOICE",
    PICTURE_QUESTION: "PICTURE_QUESTION",
    SMILEY: "SMILEY",
    MULTIPLE_CHOICE_GRID: "MULTIPLE_CHOICE_GRID",
    STAR_RATING_SCALE: "STAR_RATING_SCALE",
    DATE: "DATE",
    TIME: "TIME",
    SECTION: "SECTION",
    NUMBER: "NUMBER",
    FILE_UPLOAD: "FILE_UPLOAD",
    WEB_LINK: "WEBLINK"
}

export const formatBytes = (bytes, decimals, insertSpaceAfterSize, unit) => {
    if (unit === "GB") {
        if (bytes === 0) return `${insertSpaceAfterSize ? '0 GB' : '0GB'}`;
        const dm = decimals < 0 ? 0 : decimals;

        let floatValue = parseFloat((bytes / 1024 / 1000 / 1000).toFixed(dm))

        if (floatValue < 0.01) {
            return `${insertSpaceAfterSize ? '< 0.01 GB' : '< 0.01GB'}`
        }

        return (((bytes / 1024 / 1000 / 1000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + `${insertSpaceAfterSize ? ' ' : ''}`) + "GB")

    } else if (unit === undefined) {
        return (bytes / 1024 / 1000)
    } else {
        if (bytes === 0) return `${insertSpaceAfterSize ? '0 MB' : '0MB'}`;

        const dm = decimals < 0 ? 0 : decimals;

        let floatValue = parseFloat((bytes / 1024 / 1000).toFixed(dm))

        if (floatValue === 0) {
            return `${insertSpaceAfterSize ? '< 0.1 MB' : '< 0.1MB'}`
        }

        return ((parseFloat((bytes / 1024 / 1000).toFixed(2) >= 1000 ? 1 : (bytes / 1024 / 1000).toFixed(2))) + `${insertSpaceAfterSize ? ' ' : ''}` + ((bytes / 1024 / 1000).toFixed(2) >= 1000 ? "GB" : "MB"))
    }
}

export const getRole = (collaboratorsData, userId) => {
    let role = 2
    collaboratorsData?.map((collaborator) => {
        if (collaborator.user_id === userId) {
            role = collaborator.role
        }
    })
    return role
}

export const estimatedTime = (questions) => {
    let time = 0
    questions.map((question) => {
        switch (question.type) {
            case ESTIMATED_TIME_KEY.SHORT_TEXT:
                time += ESTIMATED_TIME.SHORT_TEXT
                break
            case ESTIMATED_TIME_KEY.LONG_TEXT:
                time += ESTIMATED_TIME.LONG_TEXT
                break
            case ESTIMATED_TIME_KEY.TIME:
                time += ESTIMATED_TIME.TIME
                break
            case ESTIMATED_TIME_KEY.DATE:
                time += ESTIMATED_TIME.DATE
                break
            case ESTIMATED_TIME_KEY.SECTION:
                time += 0
                break
            case ESTIMATED_TIME_KEY.EMAIL:
                time += ESTIMATED_TIME.EMAIL
                break
            case ESTIMATED_TIME_KEY.CHECKBOX_CHOICE:
                time += ESTIMATED_TIME.CHECKBOX_CHOICE
                break
            case ESTIMATED_TIME_KEY.DROPDOWN_CHOICE:
                time += ESTIMATED_TIME.DROPDOWN_CHOICE
                break
            case ESTIMATED_TIME_KEY.FILE_UPLOAD:
                time += ESTIMATED_TIME.FILE_UPLOAD
                break
            case ESTIMATED_TIME_KEY.STAR_RATING_SCALE:
                time += ESTIMATED_TIME.STAR_RATING_SCALE
                break
            case ESTIMATED_TIME_KEY.NUMBER:
                time += ESTIMATED_TIME.NUMBER
                break
            case ESTIMATED_TIME_KEY.MULTIPLE_CHOICE:
                time += ESTIMATED_TIME.MULTIPLE_CHOICE
                break
            case ESTIMATED_TIME_KEY.MULTIPLE_CHOICE_GRID:
                time += ESTIMATED_TIME.MULTIPLE_CHOICE_GRID
                break
            case ESTIMATED_TIME_KEY.CHECKBOX_GRID:
                time += ESTIMATED_TIME.CHECKBOX_GRID
                break
            case ESTIMATED_TIME_KEY.SMILEY:
                time += ESTIMATED_TIME.SMILEY
                break
            case ESTIMATED_TIME_KEY.LINEAR_SCALE:
                time += ESTIMATED_TIME.LINEAR_SCALE
                break
            case ESTIMATED_TIME_KEY.SLIDER:
                time += ESTIMATED_TIME.SLIDER
                break
            case ESTIMATED_TIME_KEY.AGREEMENT:
                time += ESTIMATED_TIME.AGREEMENT
                break
            case ESTIMATED_TIME_KEY.PICTURE_QUESTION:
                time += ESTIMATED_TIME.PICTURE_QUESTION
                break
            case ESTIMATED_TIME_KEY.RANKING_CHOICE:
                time += ESTIMATED_TIME.RANKING_CHOICE
                break
            case ESTIMATED_TIME_KEY.SIGNATURE_QUESTION:
                time += ESTIMATED_TIME.SIGNATURE_QUESTION
                break
        }
    })
    return time / 60
}

export const questionCount = (questions) => {
    let count = questions?.length
    questions.map((question) => {
        if (question.type === ESTIMATED_TIME_KEY.SECTION) {
            count -= 1
        }
    })
    return count
}
export const convertFirstLetterToUpper = (string) => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const isInt = (n) => {
    return Number(n) === n && n % 1 === 0
}

export const getPlanBasedOnRank = (rank) => {
    switch (rank) {
        case (0):
            return "Basic Plan"
        case (1):
            return "Bronze Plan"
        case (2):
            return "Silver Plan"
        case (3):
            return "Gold Plan"
    }
}

export const getCrownBasedOnRank = (rank) => {
    switch (rank) {
        case (-1):
            return premiumHold
        case (0):
            return null
        case (1):
            return bronzeCrown
        case (2):
            return silverCrown
        case (3):
            return goldCrown
    }
}

export const PremiumJSON = {
    "premium-on-hold": {
        "rank": -1,
        "price": 0,
        "currency": "INR",
        "storage": 1024000000,
        "response_limit": {"collect": 250000, "access": 10000},
        "question_attachments": 50,
        "theme_attachments": 50,
        "file_upload_questions_per_form": 4,
        "logic_jump": false,
        "customizable_thank_you": false,
        "email_support_rank": 0,
        "advertising": true,
        "on_call_support": false,
        "remove_branding": false,
        "response_edit": false,
        "redirect_url": false,
        "templates": 100,
        "responses_add_ons": false
    },
    "free-user": {
        "rank": 0,
        "price": 0,
        "currency": "INR",
        "storage": 1024000000,
        "response_limit": {"collect": 250000, "access": 10000},
        "question_attachments": 50,
        "theme_attachments": 50,
        "file_upload_questions_per_form": 4,
        "logic_jump": false,
        "customizable_thank_you": false,
        "email_support_rank": 0,
        "on_call_support": false,
        "advertising": true,
        "remove_branding": false,
        "response_edit": false,
        "redirect_url": false,
        "templates": 100,
        "responses_add_ons": false
    },
    "bronze-monthly": {
        "rank": 1,
        "price": 999,
        "currency": "INR",
        "storage": 25600000000,
        "response_limit": {"collect": 250000, "access": 35000},
        "question_attachments": 200,
        "theme_attachments": 200,
        "file_upload_questions_per_form": Infinity,
        "logic_jump": true,
        "customizable_thank_you": true,
        "email_support_rank": 1,
        "on_call_support": false,
        "advertising": false,
        "remove_branding": true,
        "response_edit": true,
        "redirect_url": true,
        "templates": 500,
        "responses_add_ons": true
    },
    "silver-monthly": {
        "rank": 2,
        "price": 2999,
        "currency": "INR",
        "storage": 51200000000,
        "response_limit": {"collect": 250000, "access": 75000},
        "question_attachments": 500,
        "theme_attachments": 500,
        "file_upload_questions_per_form": Infinity,
        "logic_jump": true,
        "customizable_thank_you": true,
        "email_support_rank": 2,
        "on_call_support": true,
        "advertising": false,
        "remove_branding": true,
        "response_edit": true,
        "redirect_url": true,
        "templates": 500,
        "responses_add_ons": true
    },
    "gold-monthly": {
        "rank": 3,
        "price": 4999,
        "currency": "INR",
        "storage": 153600000000,
        "response_limit": {"collect": 250000, "access": 150000},
        "question_attachments": Infinity,
        "theme_attachments": Infinity,
        "file_upload_questions_per_form": Infinity,
        "logic_jump": true,
        "customizable_thank_you": true,
        "email_support_rank": 3,
        "on_call_support": true,
        "advertising": false,
        "remove_branding": true,
        "response_edit": true,
        "redirect_url": true,
        "templates": 500,
        "responses_add_ons": true
    }
}
