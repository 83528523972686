import React from 'react'
import {QUIZ_BASE_URL} from "../shared/utils/quiz-api-services";
import {createRoot} from "react-dom/client";
import HomePage from "./components/HomePage";
import {HashRouter, Route, Routes} from "react-router-dom";
import Templates from "./components/Templates";
import PricingBody from "../pricing-page/components/PricingBody";
import Careers from "../careers-page/components/Careers";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import RCPolicy from "./components/RCPolicy";
import ResponsesAddOns from "../app/dashboard/components/ResponsesAddOns";
import {Provider} from "react-redux";
import appStore from "../app/store/appStore";
import {isMobileOnly} from "react-device-detect";
import MobileResponsesAddOns from "../app/dashboard/components/MobileResponsesAddOns";

const domContainer = document.querySelector('#home-page');
const careersRoot = createRoot(domContainer)
const url = new URL(location.href);
let isQuiz
if (url.origin === QUIZ_BASE_URL) {
    isQuiz = true
}

careersRoot.render(<React.Fragment>
    <HashRouter>
        <Routes>
            <Route path={"/"} element={<HomePage/>}/>
            <Route path={"/templates"} element={<Templates/>}/>
            <Route path={"/pricing"} element={<PricingBody/>}/>
            <Route path={"/careers"} element={<Careers/>}/>
            <Route path={"/privacypolicy"} element={<PrivacyPolicy isQuiz={isQuiz}/>}/>
            <Route path={"/terms"} element={<Terms/>}/>
            <Route path={"/rc-policy"} element={<RCPolicy/>}/>
            <Route path={"/add-on"} element={<Provider store={appStore}>
                {isMobileOnly ? <MobileResponsesAddOns/> : <ResponsesAddOns/>}
            </Provider>}/>
        </Routes>
    </HashRouter>
</React.Fragment>, domContainer);
