import React, {useEffect, useRef, useState} from 'react'
import "../../builder/css/localization.css";
import {isMobileOnly} from "react-device-detect";
import MaterialButton from "../../../shared/components/MaterialButton";
import {Pages} from "../../utils/pages";
import {deleteSASHWebToken, getQuizzoryWebToken, WEB_TOKEN_SASH_ALIAS} from "../../utils/jwt";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {APP_VERSION, NOTIFICATION_ACTION_TYPE} from "../../utils/constants";
import {
    deleteNotification,
    getNotifications,
    updateNotificationStatus
} from "../../../shared/utils/sash-v2-api";
import {useDispatch, useSelector} from "react-redux";
import NotificationsRowContainer from "../../builder/components/NotificationsRowContainer";
import {
    deleteQuizNotification,
    getQuizNotifications,
    updateQuizNotificationStatus
} from "../../../shared/utils/quiz-api-services";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import LanguageSelectionPopup from "./LanguageSelectionPopup";
import {t} from "i18next";
import {USER_ACTION_TYPE} from "../../reducers/userReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {EMAIL_ACTION_TYPE} from "../../reducers/collabAutocompleteReducer";
import userIcon from "../../../../public/images/icons/user-icon.svg"
import ModalDialog from "../../../shared/components/ModalDialog";
import {SHARED_ACTION_TYPE} from "../../reducers/sharedReducer";
import {TABS_DATA} from "./SharesContainer";
import UsageComponent from "./UsageComponent";
import {formatBytes, getCrownBasedOnRank, PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import useFeatureSpotlightDialogMgr from "../../hooks/useFeatureSpotlightDialogMgr";
import MobileFeatureSpotlightDialog from "./MobileFeatureSpotlightDialog";
import FeatureSpotlightDialog from "../../../shared/components/FeatureSpotlightDialog";

function AccInfoContainer(props) {
    const userId = useSelector(state => state.userReducer?.userId)
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [notificationInfo, setNotificationInfo] = useState(false);
    const [clearAll, setClearAll] = useState(false)
    const [localizationShow, setLocalizationShow] = useState(false)
    const accountBadgeColor = getColor(userId ? userId[0] : '0')
    const containerRef = useRef();
    const dispatch = useDispatch()
    const userInfoContainerRef = useRef();
    const notificationRef = useRef();
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const notificationData = useSelector(state => state.notificationReducer.notifications)
    const newNotificationCount = useSelector(state => state.notificationReducer.count)
    const localizationContainerRef = useRef()
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const showVersion = useSelector(state => state.appDashboardReducer.showVersion)
    const featureSpotlightIsVisible = useSelector(state => state.featureSpotlightReducer?.featureSpotlightIsVisible)
    const {
        newFeatures,
        popNewFeaturesAndThumbnails,
        selectedLanguage,
    } = useFeatureSpotlightDialogMgr()

    const crownIcon = subscriptionData ? getCrownBasedOnRank(PremiumJSON[subscriptionData?.subscription_plan]?.rank) : null

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: showUserInfo ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            setShowUserInfo(false)
            setLocalizationShow(false)
            setNotificationInfo(false)
        } else if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})
        }
    }

    useEffect(() => {
        let data = []
        let dataCount = 0
        const successFunction = (res) => {
            if (res) {
                const filterData = res.result.filter(notifications => notifications.is_notified === false)
                dataCount = filterData.length
                data = [...data.concat(res.result)]
                if (getQuizzoryWebToken()) {
                    getQuizNotifications((res) => {
                        if (res) {
                            const filterData = res.result.filter(notifications => notifications.is_notified === false)
                            dispatch({
                                type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                                payload: dataCount + filterData.length
                            })
                            data = [...data.concat(res.result)]
                            dispatch({type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_DATA, payload: data})
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }

        getNotifications(successFunction, failureFunction)

        if (!newFeatures) {
            try {
                popNewFeaturesAndThumbnails()

                if (document.getElementById("app")) {
                    if (selectedLanguage === 'fa') {
                        document.getElementById("app").dir = "rtl"
                    } else {
                        document.getElementById("app").dir = 'ltr'
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [])

    useEffect(() => {
        if (!localStorage?.getItem(WEB_TOKEN_SASH_ALIAS)) {
            window.location.hash = '#login'
        }
    }, [window.location.hash])

    const clearAllNotificationHandler = () => {
        setClearAll(true)
        if (notificationData) {
            registerGAEvent('Dashboard', 'Notification', 'clicked-clear-all');
            const deleteFormNotificationIds = []
            const deleteQuizNotificationIds = []
            notificationData.map((notification) => {
                if (notification._id && notification.form_id) {
                    deleteFormNotificationIds.push(notification._id)
                } else if (notification._id && notification.quiz_id) {
                    deleteQuizNotificationIds.push(notification._id)
                }
            })
            if (deleteFormNotificationIds.length > 0) {
                deleteNotification({notification_ids: deleteFormNotificationIds}, () => {
                }, (err) => {
                    console.log(err)
                })
            }
            if (deleteQuizNotificationIds.length > 0) {
                deleteQuizNotification({notification_ids: deleteQuizNotificationIds}, () => {
                }, (err) => {
                    console.log(err)
                })
            }
            setTimeout(() => {
                dispatch({
                    type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                    payload: 0
                })
                dispatch({type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_DATA, payload: []})
            }, 500)
        }
    }

    const handleReloadClick = () => {
        registerGAEvent('Dashboard', 'Notification', 'clicked-refresh');
        setClearAll(false)
        let data = []
        const successFunction = (res) => {
            if (res) {
                data = [...data.concat(res.result)]
                updateNotificationStatus({user_id: userId}, () => {
                }, (err) => {
                    console.log(err)
                })
                getQuizNotifications((res) => {
                    data = [...data.concat(res.result)]
                    dispatch({type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_DATA, payload: data})
                    updateQuizNotificationStatus(() => {
                    }, (err) => {
                        console.log(err)
                    })

                }, (err) => {
                    console.log(err)
                })
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        getNotifications(successFunction, failureFunction)
    }

    useEffect(() => {
        if (document.getElementById("app")) {
            if (selected === 'fa' || selected === 'ar') {
                document.documentElement.dir = "rtl"
                document.getElementById("app").dir = "rtl"
            } else {
                document.getElementById("app").dir = 'ltr'
            }
        }
    }, [localizationShow]);

    const signOutButtonClick = () => {
        deleteSASHWebToken()
        registerGAEvent('Dashboard', 'Sign-out', 'success');
        dispatch({type: USER_ACTION_TYPE.SET_USER_ID, payload: undefined})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.login})
        dispatch({type: EMAIL_ACTION_TYPE.SET_EMAIL_SUGGESTIONS_FROM_LOCAL, payload: null})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.RESET_ALL})
        dispatch({type: SHARED_ACTION_TYPE.RESET_ALL})
        dispatch({type: APP_ACTION_TYPE.RESET_ALL})
        window.location.reload()
        const responderToken = localStorage.getItem('responder_token')
        // Note: please note if you are going to clear local storage don't forget to save responder_token again in local storage after clearing it.
        localStorage.clear()
        localStorage.setItem('responder_token', responderToken)
    }

    return (
        <div id={'acc_logo_container'}
             className={isMobileOnly ? "account-logo-container-mobile" : "account-logo-container"}>
            <div className={'notifications'} onClick={() => {
                registerGAEvent('Dashboard', 'Notification', 'clicked-notification-icon');
                if (isMobileOnly) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.notification})
                } else {
                    setNotificationInfo(true)
                }
                setClearAll(false)
                document.onmousedown = e => {
                    e.stopPropagation();
                    props.previouslyOpenedDropDownRef.current = null;
                    if (notificationRef.current && !notificationRef.current.contains(e.target)) {
                        setNotificationInfo(false)
                        document.onmousedown = null;
                    }
                }
            }}>
                <img style={isMobileOnly ? {cursor: 'pointer', width: '18px'} : {
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px'
                }} src={'./images/notification.svg'} alt={'notification'}/>
                {newNotificationCount ? <span className={'notification-span'} style={newNotificationCount > 9 ? {
                    fontSize: '11px',
                    height: '15px',
                    width: newNotificationCount > 99 ? '25px' : null
                } : null}>{newNotificationCount > 99 ? '99+' : newNotificationCount}</span> : null}
            </div>
            {/*Localization */}
            {isMobileOnly ? null : <div className={'localization-icon'} onClick={() => {
                registerGAEvent('Languages', 'pop-up', 'open');
                setLocalizationShow(true)
            }
            }>
                <img style={isMobileOnly ? {cursor: 'pointer', width: '18px'} : {
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px',
                    marginLeft: '1px',
                }} src={'./images/global-icon.svg'} alt={'notification'}/>
                <span className={'localization-icon-span'}> {selected === 'en-US' ? 'en' : selected}</span>
            </div>}
            <div id={'acc-logo'} style={{backgroundColor: accountBadgeColor}} ref={containerRef}
                 className={isMobileOnly ? 'acc-logo-mobile' : "acc-logo"}
                 onClick={e => {
                     e.stopPropagation();
                     props.previouslyOpenedDropDownRef.current?.element ? handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current) : null;
                     setShowUserInfo(!showUserInfo);
                     // props.setShowUserInfo(!props.showUserInfo);
                     props.previouslyOpenedDropDownRef.current = {
                         element: isMobileOnly ? userInfoContainerRef.current : containerRef.current
                     };
                     document.onmousedown = e => {
                         e.stopPropagation();
                         props.previouslyOpenedDropDownRef.current = null;
                         if (isMobileOnly) {
                             dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: TABS_DATA.FORM})
                             if (userInfoContainerRef.current && !userInfoContainerRef.current.contains(e.target)) {
                                 // props.setShowUserInfo(false);
                                 setShowUserInfo(false);
                                 document.onmousedown = null;
                             }
                         } else {
                             if (containerRef.current && !containerRef.current.contains(e.target)) {
                                 // props.setShowUserInfo(false);
                                 setShowUserInfo(false);
                                 document.onmousedown = null;
                             }
                         }
                         registerGAEvent('Dashboard', 'Sign-out', 'click-outside');
                     }
                 }}>
                {crownIcon ? <img className={'premium-crown'} src={crownIcon} alt={"plan"}/> : null}
                <span
                    className={isMobileOnly ? 'profile-initial-mobile' : "profile-initial"}>{userId ? userId[0].toUpperCase() : '0'}</span>
                {showUserInfo ?
                    isMobileOnly ?
                        <div style={popUpStyle}>
                            <ModalDialog imageIcon={userIcon}
                                         header={t(translationKeys.account)}
                                         body={<>
                                             <div style={{
                                                 width: '100%',
                                                 display: 'flex',
                                                 justifyContent: 'center',
                                                 margin: isMobileOnly ? '12px 0 8px' : '16px 0 8px'
                                             }}>
                                                 <div className={'profile-logo-mobile'}
                                                      style={{backgroundColor: accountBadgeColor}}>
                                                     {crownIcon ?
                                                         <img className={'premium-crown'} src={crownIcon}
                                                              style={{width: "25px", height: "25px", top: "-20px"}}
                                                              alt={"plan"}/> : null}
                                                     <span className={'profile-initial'}>
                                                         {userId[0].toUpperCase()}
                                                     </span>
                                                 </div>
                                             </div>
                                             <span style={{textAlign: 'center', color: '#212121', fontSize: '16px'}}>
                                                 {userId.length > 25 ? userId.slice(0, 24) + '...' : userId}
                                             </span>
                                         </>}
                                         buttons={[{
                                             text: t(translationKeys.cancel).toUpperCase(),
                                             action: () => {
                                                 setShowUserInfo(false)
                                             }
                                         }, {
                                             text: t(translationKeys.sign_out).toUpperCase(),
                                             action: () => {
                                                 signOutButtonClick()
                                             }
                                         }]}/>
                        </div>
                        :
                        <div style={popUpStyle}>
                            <div className="user-info-container" onClick={e => e.stopPropagation()}
                                 ref={userInfoContainerRef}
                                 style={{
                                     display: showUserInfo ? 'flex' : 'none'
                                 }}>
                                <PopUpCard accountBadgeColor={accountBadgeColor} crownIcon={crownIcon}
                                           setShowUserInfo={setShowUserInfo}/>
                            </div>
                        </div> : null}
            </div>
            {
                (() => {
                    if (featureSpotlightIsVisible && localStorage) {
                        if (isMobileOnly) return (
                            <MobileFeatureSpotlightDialog/>
                        )

                        return (
                            <FeatureSpotlightDialog/>
                        )
                    }
                })()
            }
            {
                (() => {
                    if (showVersion) {
                        return (
                            <ModalDialog
                                header={"APP VERSION"}
                                body={[APP_VERSION]}
                                buttons={
                                    [
                                        {
                                            text: "CLOSE",
                                            action: () => dispatch({
                                                type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_VERSION,
                                                payload: false
                                            })
                                        }
                                    ]
                                }
                            />
                        )
                    }
                })()
            }
            {localizationShow ?
                <LanguageSelectionPopup localizationContainerRef={localizationContainerRef}
                                        localizationShow={localizationShow}
                                        setLocalizationShow={setLocalizationShow}
                /> : null}
            {notificationInfo ? <div className={'notification-pc'} ref={notificationRef} style={{
                height: notificationData?.length <= 2 ? '300px' : notificationData?.length > 5 ? '590px' : 'fit-content',
                right: (selected === 'fa' || selected === 'ar') ? "auto" : null,
                overflowY: notificationData?.length > 5 ? 'scroll' : null
            }}>
                <h3 id='notifications-text' style={{
                    margin: '3%',
                    textAlign: (selected === 'fa' || selected === 'ar') ? "end" : "start"
                }}>{t(translationKeys.notifications)} <span
                    style={notificationData.length === 0 ? {color: '#757575'} : null}
                    onClick={clearAllNotificationHandler}>{t(translationKeys.clear_all)}</span></h3>
                {notificationData.length === 0 ? <div className={'no-notifications'}>
                    <h3 style={{color: '#BDBDBD'}}>{t(translationKeys.you_are_all_caught_up)}</h3>
                    <p style={{
                        color: '#757575',
                        fontSize: '17px'
                    }}>{t(translationKeys.check_back_later_for_new_notifications)}</p>
                    <MaterialButton id={'notifications-refresh-btn'} handleClick={handleReloadClick}
                                    data={{
                                        title: t(translationKeys.refresh),
                                        bg_color: '#1976D2',
                                        customButtonContainerStyle: {borderRadius: '20px'}
                                    }}/>
                </div> : null}
                {notificationData?.map((notification, index) => {
                    return <div key={index}>
                        <NotificationsRowContainer index={index}
                                                   clearAll={clearAll}
                                                   setNotificationInfo={setNotificationInfo}
                                                   notification={notification}/>
                    </div>
                })}
                {updateNotificationStatus((res) => {
                    if (res.result) {
                        updateQuizNotificationStatus((res) => {
                            if (res.result) {
                                dispatch({
                                    type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                                    payload: 0
                                })
                                dispatch({
                                    type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_STATUS,
                                    payload: res.result
                                })
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }
                }, (err) => {
                    console.log(err)
                })}
            </div> : null}
        </div>
    )
}

export default AccInfoContainer

const PopUpCard = (props) => {
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const totalStorageUsed = useSelector(state => state.appReducer.totalStorageUsed)
    const totalUserResponseCount = useSelector(state => state.appReducer.totalUserResponseCount)
    const userId = useSelector(state => state.userReducer?.userId)
    const awsThemes = useSelector(state => state.appReducer?.awsThemes)
    const formImages = useSelector(state => state.appReducer?.formImages)
    const dispatch = useDispatch()
    const accHeader = {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: isMobileOnly ? '18px' : '22px',
        color: '#454545',
        margin: '15px'
    }
    const textContainerStyle = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: isMobileOnly ? '12px 0 8px' : '16px 0 8px'
    }
    const signOutButtonClick = () => {
        deleteSASHWebToken()
        registerGAEvent('Dashboard', 'Sign-out', 'success');
        window.localStorage.removeItem("email-suggestions")
        dispatch({type: USER_ACTION_TYPE.SET_USER_ID, payload: undefined})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.login})
        dispatch({type: EMAIL_ACTION_TYPE.SET_EMAIL_SUGGESTIONS_FROM_LOCAL, payload: null})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.RESET_ALL})
        dispatch({type: SHARED_ACTION_TYPE.RESET_ALL})
        dispatch({type: APP_ACTION_TYPE.RESET_ALL})
        window.location.reload()
        const responderToken = localStorage.getItem('responder_token')
        // Note: please note if you are going to clear local storage don't forget to save responder_token again in local storage after clearing it.
        localStorage.clear()
        localStorage.setItem('responder_token', responderToken)
    };

    return (
        <>
            <span style={accHeader}>{t(translationKeys.your_account)}</span>
            <div className={"account-details"}>
                <div style={textContainerStyle}>
                    <div className={isMobileOnly ? 'profile-logo-mobile' : "profile-logo"}
                         style={{backgroundColor: props.accountBadgeColor}}>
                        {props.crownIcon ? <img className={'premium-crown'} src={props.crownIcon} alt={"plan"}/> : null}
                    <span
                        className={isMobileOnly ? 'profile-initial-mobile' : "profile-initial"}>{userId[0].toUpperCase()}</span>
                    </div>
                </div>
                <span style={{textAlign: 'center', color: '#212121', fontSize: '16px'}}>
                    {userId.length > 25 ? userId.slice(0, 24) + '...' : userId}
                        </span>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: "15px"
                }}>
                    <MaterialButton data={{
                        title: t(translationKeys.sign_out),
                        customButtonContainerStyle: {
                            borderRadius: '50px',
                            backgroundColor: '#004BA0',
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                            fontSize: '14px'
                        }
                    }} handleClick={signOutButtonClick}
                    />
                </div>
            </div>
            <div className={"current-plan"}>
                <p>{t(translationKeys.current_plan)}</p>
                <span>{subscriptionData?.subscription_plan === "premium-on-hold" ? "HOLD" : `${subscriptionData?.subscription_plan.split("-")[0].toUpperCase()}`}</span>
            </div>
            <div className={"usage-details"}>
                <UsageComponent title={t(translationKeys.storage)}
                                description={`${formatBytes(totalStorageUsed, 2, false, "GB")} of ${formatBytes(PremiumJSON[subscriptionData?.subscription_plan]?.storage, 0, false, "GB")} Used`}
                                used={formatBytes(totalStorageUsed)}
                                max={formatBytes(PremiumJSON[subscriptionData?.subscription_plan]?.storage)}/>
                <UsageComponent title={t(translationKeys.total_submission)}
                                description={`${totalUserResponseCount} of ${PremiumJSON[subscriptionData?.subscription_plan]?.response_limit.access + subscriptionData?.response_addon_count} Used`}
                                used={parseInt(totalUserResponseCount)}
                                max={PremiumJSON[subscriptionData?.subscription_plan]?.response_limit.access + subscriptionData?.response_addon_count}/>
                <UsageComponent title={t(translationKeys.image_attachments)}
                                description={`${formImages?.length ? formImages?.length : "0"} of ${PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments === Infinity ? "Unlimited" : PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments} Used`}
                                used={parseInt(formImages?.length)} max={PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments}/>
                <UsageComponent title={t(translationKeys.custom_themes)}
                                description={`${awsThemes?.length ? awsThemes?.length : "0"} of ${PremiumJSON[subscriptionData?.subscription_plan]?.theme_attachments === Infinity ? "Unlimited" : PremiumJSON[subscriptionData?.subscription_plan]?.theme_attachments} Used`}
                                used={parseInt(awsThemes?.length)} max={PremiumJSON[subscriptionData?.subscription_plan]?.theme_attachments}/>
                <MaterialButton data={{
                    title: t(translationKeys.upgrade_your_plan).toUpperCase(),
                    customButtonContainerStyle: {
                        borderRadius: '50px',
                        backgroundColor: '#004BA0',
                        width: '100%',
                    },
                    customButtonTextStyle: {
                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                        fontSize: '14px'
                    }
                }} handleClick={() => {
                    props.setShowUserInfo(false);
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                }}
                />
            </div>
        </>)
}

function getColor(alphabet) {
    let upperCaseAlphabet = alphabet.toUpperCase();
    const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const colors = ['#FF8271', '#FF8660', '#FFAC60', '#E4B845', '#D7BB73', '#C9BC4A', '#C8DA5A', '#3FD7BB', '#57E8CE', '#42E0F5', '#9A9DE4', '#BCBEF3', '#C0A7F3', '#BB9BFF', '#BF9EE0', '#CE7AC6', '#D79CD1', '#D79CAD', '#EEA8BD', '#FF8DAF', '#DC86A0', '#DC8686', '#F19090', '#FF9D9D', '#FA8C8C', '#DF8181']
    for (let i = 0; i < 25; i++) {
        if (alphabets[i] === upperCaseAlphabet) {
            return colors[i];
        }
    }
    return '#005cb2'
}

export const handlePreviouslyOpenedSortCard = (previousElementObject) => {
    if (previousElementObject.element) {
        if (!isMobileOnly && (previousElementObject.element.className === 'form-sort-options-container')) {
            let sortFormsTitleElement = document.querySelector('.sort-forms');
            sortFormsTitleElement.style.backgroundColor = '#fff';
            sortFormsTitleElement.querySelector('img').src = '../../../images/sort_more.svg'
        }
    }
}

