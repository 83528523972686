import React, {useEffect, useState} from "react";
import "../css/responses-add-ons.css"
import close from "../../../../public/images/close_black.svg";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {useDispatch} from "react-redux";
import MaterialButton from "../../../shared/components/MaterialButton";
import {createOrderForAddOn, verifyPaymentForAddOn} from "../../../shared/utils/sash-v2-api";
import usePremiumMgr from "../../hooks/usePremiumMgr";
import PaymentSuccessPopUp from "./PaymentSuccessPopUp";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import Loader from "../../../shared/components/Loader";

const ResponsesAddOns = () => {
    const {t} = useTranslation()
    const [showLoading, setShowLoading] = useState(false)
    const [showPaymentFailedPopup, setShowPaymentFailedPopup] = useState()
    const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState()
    const {displayRazorpayForAddOns} = usePremiumMgr()
    const dispatch = useDispatch()

    useEffect(() => {
        if (location.hash && location.hash?.split('?')[1]?.split("&")[0] && location.hash?.split('?')[1]?.split("&")[1]) {
            const plan = location.hash?.split('?')[1]?.split("&")[0]
            const token = location.hash?.split('?')[1]?.split("&")[1]
            switch (plan) {
                case ("small"):
                    handleGetAddOnClick("small", token)
                    break
                case ("medium"):
                    handleGetAddOnClick("medium", token)
                    break
                case ("large"):
                    handleGetAddOnClick("large", token)
                    break
                default:
                    handleGetAddOnClick("medium", token)
            }
        }
    }, [])

    const verifyRazorpayPayment = (result, token) => {
        setShowLoading(true)
        setShowPaymentFailedPopup(false)
        const successFunction = (res) => {
            if (res?.result) {
                setShowLoading(false)
                setShowPaymentFailedPopup(false)
                setShowPaymentSuccessPopup(true)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
            setShowPaymentFailedPopup(true)
        }
        if (result?.razorpay_order_id && result?.razorpay_signature && result?.razorpay_payment_id && token) {
            verifyPaymentForAddOn(result.razorpay_order_id, result.razorpay_payment_id, result.razorpay_signature, successFunction, failureFunction, token)
        } else {
            if (result?.razorpay_order_id && result?.razorpay_signature && result?.razorpay_payment_id) {
                verifyPaymentForAddOn(result.razorpay_order_id, result.razorpay_payment_id, result.razorpay_signature, successFunction, failureFunction)
            }
        }
    }

    const handleGetAddOnClick = (plan, token) => {
        const successFunction = (res) => {
            if (res.order_id) {
                displayRazorpayForAddOns(res.order_id, (result) => verifyRazorpayPayment(result, token), failureFunction)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (token) {
            createOrderForAddOn(plan, successFunction, failureFunction, token)
        } else {
            createOrderForAddOn(plan, successFunction, failureFunction)
        }
    }

    return (
        <div className={"responses-add-ons-popup"}>
            <div className={"responses-add-ons"}>
                <div className={'localization-header'} style={{background: "#FFFFFF", height: "70px"}}>
                    <div style={{width: "100%"}}>
                        <div className={'localization-header-text'}
                             style={{color: "#212121", margin: "10px 0", textAlign: "center", fontWeight: "bold"}}>
                            {t(translationKeys.responses_add_ons)}
                        </div>
                        <span style={{display: "block", color: "#4D4D4D", textAlign: "center", fontSize: "14px"}}>
                            {t(translationKeys.responses_add_ons_description)}
                        </span>
                    </div>
                    {location.hash?.split('?')[1]?.split("&")[0] ? null :
                        <img onClick={() => {
                            registerGAEvent('Responses Add On', 'pop-up', 'close');
                            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_RESPONSE_ADD_ON, payload: false})
                        }} className={'localization-close-img'}
                             src={close} alt={close}/>}
                </div>
                <div className={"responses-add-ons-body"}>
                    <div style={{width: "33%", textAlign: "center"}}>
                        <p style={{color: "#0045A0", fontSize: "20px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                            2500 / {t(translationKeys.life_time)}
                        </p>
                        <span style={{fontSize: "14px"}}>{t(translationKeys.additional_submissions)}</span>
                        <p style={{fontSize: "24px", fontWeight: "bold", margin: "10px auto"}}>Rs. 1000</p>
                        <MaterialButton data={{
                            title: t(translationKeys.get),
                            bg_color: '#1976D2',
                            fontSize: '14px',
                            textTransform: 'uppercase',
                            customButtonContainerStyle: {
                                borderRadius: '24px',
                                padding: '15px 35px',
                                width: '100px',
                                height: '35px'
                            },
                            customButtonTextStyle: {
                                fontWeight: "bold"
                            }
                        }} handleClick={() => handleGetAddOnClick("small")}/>
                    </div>
                    <div style={{
                        width: "33%",
                        textAlign: "center",
                        borderRight: "solid 1px #BDBDBD",
                        borderLeft: "solid 1px #BDBDBD"
                    }}>
                        <p style={{color: "#0045A0", fontSize: "20px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                            5000 / {t(translationKeys.life_time)}
                        </p>
                        <span style={{fontSize: "14px"}}>{t(translationKeys.additional_submissions)}</span>
                        <p style={{fontSize: "24px", fontWeight: "bold", margin: "10px auto"}}>Rs. 2000</p>
                        <MaterialButton data={{
                            title: t(translationKeys.get),
                            bg_color: '#1976D2',
                            fontSize: '14px',
                            textTransform: 'uppercase',
                            customButtonContainerStyle: {
                                borderRadius: '24px',
                                padding: '15px 35px',
                                width: '100px',
                                height: '35px'
                            },
                            customButtonTextStyle: {
                                fontWeight: "bold"
                            }
                        }} handleClick={() => handleGetAddOnClick("medium")}/>
                    </div>
                    <div style={{width: "33%", textAlign: "center"}}>
                        <p style={{color: "#0045A0", fontSize: "20px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                            10000 / {t(translationKeys.life_time)}
                        </p>
                        <span style={{fontSize: "14px"}}>{t(translationKeys.additional_submissions)}</span>
                        <p style={{fontSize: "24px", fontWeight: "bold", margin: "10px auto"}}>Rs. 3500</p>
                        <MaterialButton data={{
                            title: t(translationKeys.get),
                            bg_color: '#1976D2',
                            fontSize: '14px',
                            textTransform: 'uppercase',
                            customButtonContainerStyle: {
                                borderRadius: '24px',
                                padding: '15px 35px',
                                width: '100px',
                                height: '35px'
                            },
                            customButtonTextStyle: {
                                fontWeight: "bold"
                            }
                        }} handleClick={() => handleGetAddOnClick("large")}/>
                    </div>
                </div>
                <p style={{color: "#424242", textAlign: "center"}}>
                    <span style={{fontWeight: "bold"}}>{t(translationKeys.validity)}: </span>{t(translationKeys.up_to_your_current_plan)}
                </p>
            </div>
            {showLoading ? <Loader width={'100%'} height={'100%'}/> : null}
            {showPaymentSuccessPopup ? <PaymentSuccessPopUp success={true}
                                                            title={t(translationKeys.payment_successful)}
                                                            description={t(translationKeys.payment_successful_description)}
                                                            handlePopUpClick={() => {
                                                                if (location.hash?.split('?')[1]?.split("&")[0]) {
                                                                    window.location = "https://surveyheart.com"
                                                                } else {
                                                                    setShowPaymentSuccessPopup(false)
                                                                    window.location.reload()
                                                                }
                                                            }}/> : null}
            {showPaymentFailedPopup ? <PaymentSuccessPopUp success={false}
                                                           title={t(translationKeys.payment_failed)}
                                                           description={t(translationKeys.payment_failed_description)}
                                                           handlePopUpClick={() => {
                                                               if (location.hash?.split('?')[1]?.split("&")[0]) {
                                                                   window.location = "https://surveyheart.com"
                                                               } else {
                                                                   setShowPaymentFailedPopup(false)
                                                               }
                                                           }}/> : null}
        </div>
    )
}

export default ResponsesAddOns
