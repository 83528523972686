export const translationsArabic = {
    all: 'الجميع',
    grouped_bar_chart: 'مخطط شريطي مجمع',
    stacked_bar_chart: 'مخطط شريطي مكدس',
    tabular_chart: 'مخطط جدولي',
    ago: "منذ",
    now: "الآن",
    start: "يبدأ",
    add_column: 'إضافة عمود',
    add_row: 'اضف سطر',
    your_account: "الحساب الخاص بك",
    account_blocked: "حساب تم إغلاقه",
    account_blocked_alert: "لقد تم حظر هذا الحساب لانتهاكه الشروط والأحكام الخاصة بنا.",
    sign_out: "خروج",
    short_answer: "اجابة قصيرة",
    long_answer: "اجابة طويلة",
    email: "بريد إلكتروني",
    number: "رقم",
    app_name: "SurveyHeart",
    file: 'ملف',
    files: 'ملفات',
    file_upload: "تحميل الملف",
    faq_videos: "فيديوهات الأسئلة الشائعة",
    question_error: "لا يمكن إضافة السؤال",
    storage: "تخزين",
    form: "استمارة",
    quiz: "اختبار",
    fup_desc: "يمكنك الآن السماح للمستجيبين للنماذج والاختبارات الخاصة بك بتحميل الملفات!\n" +
        "\n" +
        "حدد نوع سؤال تحميل الملف للبدء.",
    new_fup_feature_title: "ميزة رفع الملفات الجديدة!",
    multiple_choice: "متعدد الخيارات",
    multiple_choice_grid: "شبكة الاختيار من متعدد",
    date_text: "تاريخ",
    time_text: "وقت",
    uploading: "جارٍ التحميل…",
    add_question: "أضف سؤال",
    add_question_alert: "يرجى إضافة سؤال واحد على الأقل قبل إرسال النموذج.",
    add_question_alert_quiz: "يرجى إضافة سؤال واحد على الأقل قبل إرسال الاختبار.",
    ok: "نعم",
    title: "عنوان",
    title_required: "العنوان مطلوب",
    form_name_alert: "يرجى تسمية النموذج قبل تقديمه",
    quiz_name_alert: "يرجى تسمية الاختبار قبل إرساله",
    no_connection: "لا يوجد اتصال",
    check_connection: "يرجى التحقق من اتصال الشبكة الخاصة بك.",
    finish_incomplete_question: "يرجى الانتهاء من الأسئلة غير المكتملة في النموذج",
    draft: "مسودة",
    save_to_draft_alert: "هل ترغب في حفظ هذا النموذج كمسودة لتجنب فقدان تغييراتك؟",
    save_to_draft_quiz_alert: "هل ترغب في حفظ هذا الاختبار كمسودة لتجنب فقدان تغييراتك؟",
    yes: "نعم",
    no: "لا",
    cancel: "يلغي",
    live_preview: "المعاينة المباشرة",
    themes: "المواضيع",
    tap_to_set_theme: "انقر هنا لتعيين موضوع النموذج.",
    select_image: "اختر صورة",
    created: "مخلوق",
    form_created_successfully: "تم إنشاء النموذج بنجاح",
    warning: "تحذير",
    favourites: "المفضلة",
    form_edit_alert: "لقد تم بالفعل تقديم هذا النموذج وتم جمع الردود. تحرير النموذج قد يؤثر على الردود السابقة.",
    quiz_edit_alert: 'لقد تم إرسال هذا الاختبار بالفعل وتم جمع الإجابات. قد يؤثر تحرير الاختبار على الإجابات السابقة',
    edit: "يحرر",
    download_as: "تحميل ك",
    delete_form: "حذف النموذج",
    delete_form_alert: "هل تريد حذف هذا النموذج؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_quizzes_alert: "هل تريد حذف هذه الاختبارات؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_forms_alert: "هل تريد حذف هذه النماذج؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_quiz_alert: "هل تريد حذف هذا الاختبار؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_answers_alert: "هل تريد حذف هذه الإجابات؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_answer_alert: "هل تريد حذف هذه الإجابة؟ لا يمكن التراجع عن هذا الإجراء.",
    delete: "يمسح",
    delete_all: "حذف الكل",
    section: "قسم",
    worst: "أسوأ",
    server_error_alert: "لا يمكن العثور على الخادم. من فضلك حاول مرة أخرى بعد بعض من الوقت!!",
    signed_in_as: "وقعت في مثل",
    signing_out: "تسجيل خروج…",
    create_form: "إنشاء نموذج",
    create_blank_form: "إنشاء نموذج فارغ",
    create_blank_quiz: "إنشاء مسابقة فارغة",
    close: "يغلق",
    smile: "يبتسم",
    dislike: 'لم يعجبنى',
    like: 'يحب',
    best: 'أفضل',
    response_collection_exit_alert: "هل تريد الخروج من جمع الاستجابة؟",
    form_builder: "منشئ النموذج",
    form_builder_promo: "معاينة فورية وقوالب وموضوعات للنماذج والاختبارات",
    results: "نتائج",
    result_feature_promo: "تحليل وتصدير نتائج الاستطلاع الخاص بك",
    notification: "الإخطارات الفورية",
    delete_option_alert: "هل تريد حذف هذا الخيار؟ [ملاحظة: لا يمكن التراجع عن هذا الإجراء]",
    question_type: "نوع السؤال",
    change_question_type_alert: "انقر هنا لتغيير نوع السؤال",
    preview_not_available: "المعاينة غير متوفرة",
    delete_question: "حذف السؤال",
    delete_response_collected_question_alert: "سيؤثر حذف هذا السؤال على الردود المجمعة السابقة.\n" +
        "\n" +
        "هل انت متأكد انك تريد المتابعة؟",
    delete_question_alert: "هل أنت متأكد، هل تريد حذف هذا السؤال؟",
    add_option: 'إضافة خيار',
    add_other_option: "أضف خيار \"أخرى\".",
    general_form_type_description: "إنشاء نموذج/استطلاع جديد",
    quiz_type_description: 'إنشاء مسابقة جديدة',
    sheet_disconnected: 'تم قطع اتصال ورقة Google',
    sheet_not_connected: 'الورقة غير متصلة',
    sheet_not_connected_desc: 'يرجى الاتصال بالمالك لتوصيل ورقة جوجل.',
    options: "خيارات",
    option: 'خيار',
    no_answer: "لا اجابة",
    no_response: "لا يوجد رد",
    responses: "استجابات",
    response: "إجابة",
    serial_number: "رقم سري",
    submit_time: "إرسال الوقت",
    export_success: "تم التصدير بنجاح",
    answer_with_colan: "إجابة :",
    others: "آحرون",
    delete_response: "حذف الرد",
    deleted: "تم الحذف",
    my_themes: "مواضيعي",
    limit_reached: "بلغ الحد",
    limit_reached_description: "لقد وصلت إلى الحد الأقصى لتحميل الصورة.",
    modified: "معدل",
    delete_image: "حذف صورة",
    delete_image_alert: "هل أنت متأكد، هل تريد حذف هذه الصورة؟",
    delete_theme_image_desc: "هل تريد حذف هذه الصورة؟ إذا تابعت، فلن تكون هذه الصورة متاحة لأي نماذج أو اختبارات تستخدمها. لا يمكن التراجع عن هذا الإجراء.",
    section_title: "عنوان القسم",
    section_description: "وصف القسم",
    show_scale: 'إظهار المقياس',
    show_options: "إظهار الخيارات",
    show_rows_and_columns: "إظهار الصفوف والأعمدة",
    options_required: "الخيارات المطلوبة",
    link_copied: "تم نسخ الرابط",
    app_not_found: "لم يتم العثور على التطبيق",
    default_text: "تقصير",
    dark_mode: "الوضع المظلم",
    done: "منتهي",
    upload_image: "تحميل الصور",
    question: "سؤال",
    required: "مطلوب",
    share: "يشارك",
    form_link: "رابط النموذج:",
    copy_link: "نسخ الوصلة",
    view_form: "عرض النموذج",
    menu: "قائمة طعام",
    view_all: "عرض الكل",
    settings: "إعدادات",
    submit: "يُقدِّم",
    change_theme: "غير الخلفية",
    tap_here_to_choose_theme: "انقر هنا لاختيار الموضوع",
    click_or_change_current_theme: "انقر على السمة الحالية أو \"تغيير\" أدناه لاختيار سمة مختلفة",
    show_logo: "إظهار الشعار",
    show_logo_alert: "هل تريد أن تظهر شعارك؟",
    welcome_page: "صفحة الترحيب",
    show_welcome_page_alert: "هل تريد إظهار صفحة الترحيب؟",
    start_button: "زر البدء",
    allow_multiple_responses: "السماح باستجابات متعددة",
    allow_more_response_alert: "هل تريد السماح للمستخدم بالرد أكثر من مرة؟",
    allow_more_quiz_attempt_alert: "هل تريد السماح للمستخدم بالمحاولة أكثر من مرة؟",
    show_summary: "عرض الملخص",
    show_results: "عرض نتائج الاختبار",
    allow_responders_view_summary_alert: "هل تريد السماح للمستجيبين بمشاهدة ملخص كافة الإجابات بعد الإرسال؟",
    allow_result_view_quiz_alert: "هل تريد السماح للمستخدم بعرض النتائج؟",
    show_question_number: "إظهار رقم السؤال",
    show_question_number_in_form: "هل تريد إظهار أرقام الأسئلة في النموذج؟",
    show_question_number_in_quiz: "هل تريد إظهار أرقام الأسئلة في الاختبار؟",
    shuffle_questions: "خلط الأسئلة",
    shuffle_questions_alert: "هل تريد خلط أسئلة النموذج عشوائيًا؟",
    shuffle_quiz_questions_alert: "هل تريد خلط أسئلة الاختبار؟",
    description: "وصف",
    disconnect: 'قطع الاتصال',
    builder: "منشئ",
    preview: "معاينة",
    form_status: "حالة النموذج",
    active: "نشيط",
    inactive: "غير نشط",
    search_by_question_title_from_forms: 'البحث حسب عنوان السؤال من النماذج الموجودة',
    search_by_question_title_from_quizzes: 'البحث حسب عنوان السؤال من الاختبارات الموجودة',
    view_responses: "عرض الردود",
    edit_form: "تحرير النموذج",
    duplicate_form: "نموذج مكرر",
    drafts: "المسودات",
    no_form_available: "لا يوجد نموذج متاح!",
    notifications: "إشعارات",
    feedback: "تعليق",
    privacy_policy: "سياسة الخصوصية",
    open_individual_responses: "فتح الردود الفردية",
    bar_chart: "شريط الرسم البياني",
    pie_chart: "مخطط دائري",
    summary: "ملخص",
    invalidated_form_cannot_be_selected: "لا يمكن تحديد نموذج غير صالح.",
    invalidated_quiz_cannot_be_selected: "لا يمكن تحديد اختبار غير صالح.",
    individual: "فردي",
    search: "يبحث",
    image: "صورة",
    video: "فيديو",
    website: "موقع إلكتروني",
    exit: "مخرج",
    response_limit_reached: "تم الوصول إلى حد الاستجابة",
    response_limit_reached_alert: "لقد تجاوز هذا النموذج الحد المسموح به لبيانات الاستجابة؛ ولذلك، قمنا بمنع هذا النموذج من قبول أي ردود أخرى.\n" +
        "\n" +
        "إذا كنت تريد حقًا متابعة الاستبيان الحالي، فنقترح عليك نسخه ومشاركة الرابط.",
    unapproved_form: "نموذج غير معتمد",
    unapproved_form_alert: "يستخدم هذا النموذج كلمات قد تنتهك سياساتنا.\n" +
        "\n" +
        "إذا كنت تعتقد أن هذا النموذج قد تم حظره عن طريق الخطأ، فيرجى فتح رابط النموذج أدناه وتقديم المعلومات المطلوبة في النموذج.",
    choose_your_language: "اختر لغتك",
    provide_explanation: "تقديم شرح",
    selected: "المحدد",
    review_button: "مراجعة",
    row: "صف",
    not_specified: "غير محدد",
    quiz_control: "التحكم في الاختبار",
    allow_multiple_attempts: "السماح بمحاولات متعددة",
    user_info: "معلومات المستخدم",
    name_s: "اسم",
    duration: "مدة",
    set_duration_quiz_alert: "هل تريد تحديد مدة الاختبار؟",
    user_information_description: "هل ترغب في الحصول على معلومات المشاركين؟",
    set_duration: "ضبط المدة (الساعات: الدقائق)",
    minutes: "دقائق",
    hours: "ساعات",
    minute: "دقيقة",
    hour: "ساعة",
    correct: "صحيح",
    wrong: "خطأ",
    skipped: "تم تخطيه",
    score: "نتيجة",
    forms: "نماذج",
    quizzes: "الإختبارات",
    more: "أكثر",
    quiz_status: "حالة الاختبار",
    view_answers: "عرض الإجابات",
    view_quiz: "عرض الاختبار",
    duplicate_quiz: "مسابقة مكررة",
    no_quiz_available: "لا يوجد اختبار متاح!",
    quiz_link: "رابط المسابقة",
    unapproved_quiz: "مسابقة غير معتمدة",
    unapproved_quiz_alert: "يستخدم هذا الاختبار كلمات قد تنتهك سياساتنا.\n" +
        "\n" +
        "إذا كنت تعتقد أنه تم حظر هذا الاختبار عن طريق الخطأ، فيرجى فتح رابط النموذج أدناه وتقديم المعلومات المطلوبة في النموذج.",
    do_you_want_to_show_all_correct_answers_to_users: "هل تريد إظهار الإجابة الصحيحة للمستخدمين؟",
    results_publish_date: "تاريخ نشر النتائج",
    do_you_want_to_set_date_to_publish_results: "هل تريد تحديد موعد لنشر النتائج",
    quiz_result: "نتيجة الاختبار",
    enter_answer: "إجابة",
    manage: "يدير",
    manage_storage: 'ادارة المساحة',
    shuffle_options: 'خيارات خلط ورق اللعب',
    attach: 'يربط',
    shuffle: 'خلط',
    marks: "ماركس",
    mark: "علامة",
    correction_status: "حالة:",
    status_validated: "تم التحقق من صحتها",
    pending: "قيد الانتظار",
    results_publish: "ينشر",
    publish: "ينشر",
    publish_description: "هل تريد نشر نتائج الاختبار؟",
    publish_description_note: "(ستكون النتيجة النهائية مرئية في ورقة إجابة المستجيب)",
    published_successfully: "تم النشر بنجاح",
    choice_type_validation_note: "* يتم التحقق من صحة الاختيار من متعدد تلقائيا",
    short_answer_type_validation_note: "* يتم التحقق من صحة الإجابة القصيرة تلقائيًا",
    long_answer_type_validation_note: "* لم يتم التحقق من صحة الإجابة الطويلة تلقائيًا",
    fileupload_answer_type_validation_note: "*لم يتم التحقق من صحة تحميل الملف تلقائيًا",
    dropdown_answer_type_validation_note: "* يتم التحقق من صحة اختيار القائمة المنسدلة تلقائيًا",
    save: "يحفظ",
    questions: "أسئلة",
    show_correct_answer: "عرض الإجابة الصحيحة",
    show_answer: "اظهر الاجابة",
    hide_answer: "اخفي الاجابة",
    no_marks: "لا علامات",
    question_is_required: "عنوان السؤال مطلوب",
    marks_is_required: "العلامات مطلوبة",
    options_are_required: "الخيارات مطلوبة",
    correct_answer_required: "مطلوب الإجابة الصحيحة",
    enter_valid_mark: "أدخل علامة صالحة",
    evaluated: "تم تقييمه",
    saved: "أنقذ",
    pending_evaluation: "في انتظار التقييم",
    alphabet: "الأبجدية",
    not_evaluated: "لم يتم تقييمه",
    percentage_ascending: "النسبة المئوية - تصاعدي",
    percentage_descending: "النسبة المئوية - تنازلي",
    pending_evaluation_alert_message: "يجب عليك إكمال تقييم جميع الإجابات قبل النشر.",
    show_unevaluated: "عرض غير مقيم",
    publish_success_alert_message: "يمكن للمستجيبين رؤية نتائجهم في أوراق الإجابة المقابلة لهم.\n" +
        "\n" +
        "لقد تم إغلاق هذا الاختبار تلقائيا.",
    publishing: "نشر",
    quiz_published: "تم نشر الاختبار",
    quiz_published_alert_before_edit_message: "تم نشر هذا الاختبار، إذا كنت بحاجة إلى إجراء تغييرات، فيجب عليك نسخ الاختبار ومشاركته مرة أخرى.",
    quiz_has_answers: "مسابقة لديها إجابات",
    quiz_has_answer_alert_message: "لتعديل هذا الاختبار، يجب عليك حذف جميع الإجابات الموجودة.",   // two strings
    unpublished: "غير منشورة",
    published: "نشرت",
    setting_updated: "تم تحديث الإعداد",
    account: "حساب",
    on: "على",
    off: "عن",
    quizzory: "لغز",
    scan_qr_code: "مسح رمز الاستجابة السريعة",
    answers: "الإجابات",
    answer_s: "إجابة",
    question_limit_reached: "تم الوصول إلى حد السؤال",
    reached_limit_for_number_of_questions: "لقد وصلت إلى الحد الأقصى لعدد الأسئلة المسموح بها في النموذج. ولذلك، لا يمكن إضافة أي أسئلة جديدة",
    life_time: "حياة",
    last_year: "العام الماضي",
    last_30_days: "آخر 30 يومًا",
    last_7_days: "اخر 7 ايام",
    last_24_hours: "أخر 24 ساعه",
    custom_date: "تاريخ مخصص",
    filter_by_date: "تصفية حسب التاريخ",
    filter_by_question: "تصفية حسب السؤال",
    filter_text: "تصفية النص",
    add_filter: "إضافة عامل تصفية",
    apply: "يتقدم",
    from_date: "من التاريخ",
    today: 'اليوم',
    to_date: "ان يذهب في موعد",
    contains: "يتضمن",
    equal_to: "يساوي",
    not_equal_to: "لا يساوي",
    from_date_required: "من التاريخ المطلوب",
    to_date_required: "مطلوب حتى الآن",
    webapp_promo_text: "الوصول إلى النماذج والاستطلاعات واستطلاعات الرأي والاختبارات عبر الإنترنت!",
    question_title: "سؤال",
    invalid_date: "تاريخ غير صالح",
    invalid_url: "URL غير صالح",
    invalid_url_description: "يبدو أن عنوان URL غير صالح.",
    delete_response_alert: "هل تريد حذف هذا الرد؟ لا يمكن التراجع عن هذا الإجراء.",
    delete_responses_alert: "هل تريد حذف هذه الردود؟ لا يمكن التراجع عن هذا الإجراء.",
    overview: "ملخص",
    no_results_found: "لم يتم العثور على نتائج",
    date_format: "dd-mm-yyyy",
    powered_by_surveyheart: "Powered by \'SurveyHeart\'",
    title_dashboard: "لوحة القيادة",
    title_notifications: "إشعارات",
    select_one_response: "الرجاء تحديد رد واحد على الأقل لحذفه",
    //New String
    shared: "مشترك",
    hide: "يخفي",
    use_templates: "استخدم القوالب",
    quick_settings: "الإعدادات السريعة",
    leave_collaboration: "ترك التعاون",
    templates: "قوالب",
    collapse: "ينهار",
    remove_favourite: "إزالة المفضلة",
    add_favourite: "أضف المفضلة",
    collaborator: "متعاون",
    collaborators: "المتعاونون",
    show_more: "أظهر المزيد",
    create_quiz: "إنشاء اختبار",
    collaborate: "يتعاون",
    share_QR_code: "مشاركة رمز الاستجابة السريعة",
    add_image: "إضافة صورة",
    invalid_file_type: "نوع الملف غير صالح",
    drag_drop: "السحب والإسقاط",
    here: "هنا",
    choose: "يختار",
    add_account: 'إضافة حساب',
    choose_account: 'اختر الحساب',
    maximum_allowed_file_size: "الحد الأقصى لحجم الملف المسموح به هو ",
    or: "أو",
    next: "التالي",
    change_image: "تغيير الصورة",
    upload: "رفع",
    view: "منظر",
    collaboration: "تعاون",
    share_collaboration_form: "هل تريد مشاركة هذا النموذج مع الآخرين؟",
    share_collaboration_quiz: "هل ترغب في مشاركة هذا الاختبار مع الآخرين؟",
    multiple_responses_summary: "ردود متعددة، ملخص",
    question_number_shuffle: "رقم السؤال، علامات الاستفهام، خلط ورق اللعب",
    general: "عام",
    browse: "تصفح",
    owner: "مالك",
    something_went_wrong: "هناك خطأ ما!",
    forget_your_password: "نسيت كلمة المرور؟",
    sign_in: "تسجيل الدخول",
    sign_up: "اشتراك",
    using_email: "باستخدام البريد الإلكتروني",
    dont_have_account: "ليس لديك حساب؟",
    on_signing_up_agree_to_surveyhearts: "من خلال التسجيل، فإنك توافق على SurveyHeart's",
    and: "و",
    terms_of_use: "شروط الاستخدام",
    already_have_an_account: "هل لديك حساب؟",
    signed_in_previously_as_a_google_user: "لقد قمت بتسجيل الدخول مسبقًا كمستخدم Google.",
    sign_in_google_to_continue: "الرجاء تسجيل الدخول عبر جوجل للمتابعة.",
    no_account_found_email_address: "لم يتم العثور على حساب باستخدام عنوان البريد الإلكتروني هذا",
    account_already_exist_email: "الحساب موجود بالفعل لهذا البريد الإلكتروني.",
    install_surveyheart_app: "قم بتثبيت تطبيق SurveyHeart",
    easy_login_via_app: "تسجيل الدخول بسهولة عبر التطبيق",
    get_notification_instantly: "احصل على إشعار على الفور",
    create_form_quiz_on_the_go: "إنشاء نموذج/اختبار أثناء التنقل",
    check_your_inbox_to_activate_your_account: "يرجى التحقق من صندوق الوارد الخاص بك لتفعيل حسابك",
    select_questions: "حدد الأسئلة",
    percentage: "نسبة مئوية",
    free_App_to_create_forms: "احصل على التطبيق المجاني لإنشاء النماذج",
    we_cant_find_the_page_that_you_are_looking_for: "لا يمكننا العثور على الصفحة التي تبحث عنها",
    answered: "أجاب",
    completing_this_quiz: "شكرا لاستكمال هذا الاختبار",
    connected_successfully: 'تم الاتصال بنجاح',
    seconds: "ثواني",
    enter_your_details: "أدخل التفاصيل الخاصة بك",
    enter_all_required_information: "الرجاء إدخال جميع المعلومات المطلوبة",
    answer_data_not_found: "لم يتم العثور على بيانات الإجابة",
    results_published_soon: "سيتم نشر النتائج قريبا...",
    save_link_for_future_reference: "احفظ هذا الرابط للرجوع إليه في المستقبل",
    choice_questions_only: "أسئلة الاختيار فقط",
    score_is_not_final: "ملاحظة: هذه النتيجة ليست نهائية",
    tabular: "مجدول",
    text: "نص",
    answer_response_alert: "هذا السؤال يحتاج إلى إجابة",
    invalid_email_address: "عنوان البريد الإلكتروني غير صالح",
    answer_not_available: "الإجابة غير متوفرة",
    validated_automatically: "* التحقق من صحتها تلقائيا",
    answer_link: "رابط الإجابة",
    embed_code: 'تضمين التعليمات البرمجية',
    copy_code: 'رمز النسخ',
    code_copied: 'تم نسخ الكود',
    share_via: "شارك عبر",
    print: "مطبعة",
    column: "عمود",
    columns: "أعمدة",
    rows: "الصفوف",
    columns_all: "الأعمدة (الكل)",
    submitted_time: "الوقت المقدم",
    unable_to_submit: 'غير قادر على الإرسال',
    unable_to_submit_alert_line1: 'نظرًا لأن هذا الاختبار قد تلقى إجابات، فلديك حق وصول محدود لإجراء التغييرات.',
    unable_to_submit_alert_line2: 'من أجل الحصول على حق الوصول الكامل لتحرير هذا الاختبار، يجب عليك حذف كافة الإجابات الموجودة.',
    action_blocked: 'تم حظر الإجراء',
    action_blocked_alert: 'تم حظر الإجراء بسبب الإجابات الموجودة لهذا الاختبار. السماح بهذا قد يؤثر على الإجابات السابقة.',
    quiz_edit_submit_alert: 'قد يؤثر تحرير الاختبار على العلامات والنسبة المئوية ويتطلب إعادة تقييم أسئلة معينة.',
    continue: 'يكمل',
    submitted: 'مُقَدَّم',
    submitting: 'تقديم...',
    filter: "منقي",
    exit_screen: "شاشة الخروج",
    full_screen: "تكبير الشاشة",
    tabular_view: "عرض جدولي",
    clear_all: "امسح الكل",
    you_are_all_caught_up: "لقد تم القبض عليك جميعًا!",
    check_back_later_for_new_notifications: "تحقق مرة أخرى لاحقًا للحصول على إشعارات جديدة.",
    requires_answer: "يتطلب الإجابة",
    enter_member_id: "أدخل معرف العضو",
    viewers_can_read_form_responses: "يمكن للمشاهدين قراءة النموذج وعرض الردود",
    editors_edit_form_view_responses: "يمكن للمحررين تحرير النموذج وعرض الردود",
    admin_edit_form_add_collaborator: "يمكن للمسؤول تعديل النموذج وإضافة متعاون",
    invalid_member_id: "معرف العضو غير صالح",
    already_member_added: "هذا المستخدم لديه حق الوصول بالفعل",
    add_collaborator: "إضافة متعاون",
    admin: "مسؤل",
    editor: "محرر",
    viewer: "مشاهد",
    searched_form_not_found: 'لم يتم العثور على النموذج الذي تم البحث عنه!',
    searched_quiz_not_found: 'لم يتم العثور على مسابقة بحثت!',
    no_shared_data_available: 'لا توجد بيانات مشتركة متاحة!',
    forms_server_is_not_available: 'خادم النماذج غير متوفر',
    quiz_server_is_not_available: 'خادم الاختبار غير متوفر',
    leave: 'يترك',
    password: 'كلمة المرور',
    new_password: 'كلمة المرور الجديدة',
    retype_password: 'أعد إدخال كلمة السر',
    create_password: 'إنشاء كلمة المرور',
    view_results: 'عرض النتائج',
    control: 'يتحكم',
    q_control: 'التحكم في الاختبار',
    q_result: 'نتيجة الاختبار',
    no_matches_found: "لم يتم العثور على تطابق",
    dropdown: "اسقاط",
    checkboxes: "خانات الاختيار",
    checkboxes_grid: "شبكة مربعات الاختيار",
    check_box: 'خانة الاختيار',
    star_rating: 'تصنيف النجوم',
    linear_scale: 'مقياس خطي',
    info_1: "معلومات 1",
    info_2: "معلومات 2",
    info_3: "معلومات 3",
    new: 'جديد',
    download_all: 'تحميل الكل',
    download: 'تحميل',
    sorting: 'فرز',
    sort_by: 'ترتيب حسب',
    refresh: 'ينعش',
    add: 'يضيف',
    enter_users_email_address: "أدخل عنوان البريد الإلكتروني للمستخدم",
    do_you_want_to_delete_these: 'هل تريد حذف هذه',
    do_you_want_to_delete_this_form: 'هل تريد حذف هذا النموذج',
    all_responses_will_also_be_deleted: 'سيتم حذف كافة الردود أيضا',
    this_action_cannot_be_undone: 'لا يمكن التراجع عن هذا الإجراء.',
    do_you_want_to_delete_this_quiz: 'هل تريد حذف هذا الاختبار',
    all_answer_sheets_will_also_be_unavailable: 'جميع أوراق الإجابة لن تكون متاحة أيضًا.',
    change: 'يتغير',
    roles_rights: 'الأدوار والحقوق',
    learn_more: 'يتعلم أكثر',
    viewers_can_read_quiz_and_view_answers: 'يمكن للمشاهدين قراءة الاختبار وعرض الإجابات',
    editors_can_edit_quiz_view_answers: 'يمكن للمحررين تحرير الاختبار وعرض الإجابات',
    admin_can_edit_quiz_add_collaborator: 'يمكن للمسؤول تحرير الاختبار وإضافة متعاون',
    change_roles: 'تغيير الأدوار',
    remove: 'يزيل',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: "لم تقم بإرسال التغييرات الخاصة بك. هل من المقبول المغادرة دون التقديم؟",
    select_language: 'اختار اللغة',
    are_you_sure_you_want_delete_this_collaborator: 'هل أنت متأكد أنك تريد حذف هذا المتعاون؟',
    role_has_been_changed_successfully: 'تم تغيير الدور بنجاح',
    do_you_want_to_leave_collaboration: 'هل تريد ترك التعاون؟',
    form_builder_access_denied: 'تم رفض الوصول إلى منشئ النموذج',
    quiz_builder_access_denied: 'تم رفض الوصول إلى منشئ الاختبار',
    you_only_have_viewer_rights_for_quiz: 'لديك فقط حقوق المشاهدة لهذا الاختبار.',
    you_only_have_viewer_rights_for_form: 'لديك فقط حقوق المشاهدة لهذا النموذج.',
    kindly_contact_the_form_owner_for_more_info: 'يرجى الاتصال بمالك النموذج لمزيد من المعلومات.',
    kindly_contact_the_quiz_owner_for_more_info: 'يرجى الاتصال بمالك الاختبار لمزيد من المعلومات.',
    incomplete_questions: 'أسئلة غير مكتملة:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'لديك تغييرات غير محفوظة - هل أنت متأكد من رغبتك في مغادرة هذه الصفحة؟',
    languages: 'اللغات',
    copy: "ينسخ",
    duplicate: "ينسخ",
    check_box_grid: 'شبكة مربع الاختيار',
    start_scale_from_zero: 'يبدأ المقياس من 0',
    scale_down: 'خفض',
    scale_up: 'زيادة',
    center_label_optional: 'تسمية المركز (اختياري)',
    left_label_optional: 'التسمية اليسرى (اختياري)',
    right_label_optional: 'التسمية اليمنى (اختياري)',
    not_good: 'ليس جيدا',
    neutral: 'حيادي',
    good: 'جيد',
    very_good: 'جيد جدًا',
    edit_rights_removed: 'تحرير تمت إزالة الحقوق',
    your_rights_to_edit_this_form_have_been_removed: "تمت إزالة حقوقك في تعديل هذا النموذج.",
    your_rights_to_edit_this_quiz_have_been_removed: "تمت إزالة حقوقك في تعديل هذا الاختبار.",
    please_contact_form_owner_for_more_info: " يرجى الاتصال بمالك النموذج لمزيد من المعلومات.",
    please_contact_quiz_owner_for_more_info: " يرجى الاتصال بمالك الاختبار لمزيد من المعلومات.",
    last_edited_on: 'تم التعديل الأخير بتاريخ',
    last_edited: 'آخر تحرير',
    by: "بواسطة",
    you: "أنت",
    estimated_time: "الوقت المقدر",
    do_you_want_to_show_estimated_time_taking_quiz: "هل تريد إظهار الوقت المقدر لإجراء الاختبار؟",
    do_you_want_to_show_estimated_time_taking_form: "هل تريد إظهار الوقت المقدر لأخذ النموذج؟",
    show_question_count: "إظهار عدد الأسئلة",
    do_you_want_to_show_total_number_questions_quiz: "هل تريد إظهار العدد الإجمالي للأسئلة في الاختبار؟",
    do_you_want_to_show_total_number_questions_form: "هل تريد إظهار العدد الإجمالي للأسئلة في النموذج؟",
    show_question_marks: "إظهار علامات الاستفهام",
    show_question_marks_quiz: "هل تريد إظهار علامات الاستفهام في الاختبار؟",
    you_dont_have_add_collaborator_permission: "You don't have add collaborator permission",
    modified_date: "ليس لديك إذن إضافة متعاونتاريخ التعديل",
    created_date: "تاريخ الإنشاء",
    question_cant_be_added: "لا يمكن إضافة السؤال",
    another_file_upload_question_cant_added: "لا يمكن إضافة سؤال آخر حول تحميل الملف",
    you_can_have_only: "يمكنك الحصول على فقط",
    file_upload_questions_in_form: "أسئلة تحميل الملف في نموذج",
    file_upload_questions_in_quiz: "أسئلة تحميل الملف في الاختبار",
    storage_capacity_exceeded: "تم تجاوز سعة التخزين",
    kindly_inform_form_builder_that: "يرجى إبلاغ منشئ النموذج بذلك",
    kindly_inform_quiz_builder_that: "يرجى إبلاغ منشئ الاختبار بذلك",
    their_storage_capacity_file_uploads: "سعة التخزين الخاصة بهم لتحميل الملفات",
    has_been_exceeded: "تم تجاوزه",
    view_details: "عرض التفاصيل",
    paste_your_video_link_here: "الصق رابط الفيديو الخاص بك هنا",
    paste_your_website_link_here: "الصق رابط موقع الويب الخاص بك هنا",
    view_answer_sheet: "عرض ورقة الإجابة",
    response_count: "عدد الاستجابة",
    count: "عدد",
    password_least_8_characters: "يجب أن تكون كلمة المرور من 8 إلى 50 حرفًا",
    password_contain_least_1_number: "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
    password_contain_least_1_capital_letter: "يجب أن تحتوي كلمة المرور على حرف واحد كبير على الأقل",
    password_contain_least_1_special_character: "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
    please_fill_all_required_fields: "يرجى تعبئة جميع الحقول المطلوبة",
    passwords_dont_match: "كلمات المرور غير متطابقة",
    incorrect_password: "كلمة سر خاطئة",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "هل تريد حذف هذه الصورة؟ إذا تابعت، فلن تكون هذه الصورة متاحة لأي نماذج أو اختبارات تستخدمها. لا يمكن التراجع عن هذا الإجراء.",
    url_does_not_seem_to_valid: "يبدو أن عنوان URL غير صالح.",
    you_have_reached_image_uploaded_limit: "لقد وصلت إلى الحد الأقصى لتحميل الصورة.",
    select: "يختار",
    feature_spotlight: 'ميزة الأضواء',
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "ملاحظة: لرؤية هذه المعلومات مرة أخرى، انقر فوق \"المزيد\" في القائمة الموجودة على الجانب الأيسر",
    import_questions: "أسئلة الاستيراد",
    do_search: "قم بالبحث",
    clear: "Clear",
    back: "خلف",
    select_all: "اختر الكل",
    select_all_2: "اختر الكل",
    limit_reached_alert_message: "لقد وصلت إلى الحد المجاني، يرجى ترقية خطتك للوصول إلى جميع الردود.",
    limit_reached_alert_message_quiz: "لقد وصلت إلى الحد المجاني، يرجى ترقية خطتك للوصول إلى جميع الإجابات.",
    limit_reached_UI_message: "لن تكون الردود متاحة لخطتك الحالية، يرجى ترقية خطتك لرؤية جميع الردود.",
    limit_reached_UI_message_quiz: "لن تكون الإجابات متاحة لخطتك الحالية، يرجى ترقية خطتك لرؤية جميع الإجابات.",
    undo: "تراجع",
    response_deleted: "تم حذف الرد",
    question_deleted: "تم حذف السؤال",
    answer_deleted: "تم حذف الإجابة",
    responses_deleted: "تم حذف الردود",
    questions_deleted: "تم حذف الأسئلة",
    answers_deleted: "تم حذف الإجابات",
    collect_email_header: "جمع البريد الإلكتروني",
    collect_email_alert_message: "سيؤدي تمكين هذه الميزة إلى إجبار المجيب على تسجيل الدخول باستخدام عنوان بريده الإلكتروني لملء النموذج",
    collect_email_alert_note: "ملاحظة: يتم دعم حساب Google فقط",
    allow_response_edit: "السماح بتحرير الرد",
    allow_response_edit_description: "هل تريد السماح للمجيبين بتحرير الردود بعد الإرسال؟",
    enter_desc_here: "أدخل الوصف هنا",
    passcode_protection: "حماية رمز المرور",
    passcode_protection_description: "هل تريد أن يكون نموذجك محميًا برمز مرور؟",
    passcode_protection_description_quiz: "هل تريد أن يكون اختبارك محميًا برمز مرور؟",
    crop_image: "اقتصاص الصورة",
    crop: "اقتصاص",
    original: "أصلي",
    collect_email: "جمع عنوان البريد الإلكتروني",
    collect_email_description: "هل تريد جمع عناوين البريد الإلكتروني للمستجيبين للرجوع إليها أو استخدامها في المستقبل؟",
    collect_email_description_quiz: "سيؤدي تمكين هذه الميزة إلى إجبار المستجيبين على تسجيل الدخول باستخدام عنوان gmail الخاص بهم لملء الاختبار.",
    collect_email_description_form: "سيؤدي تمكين هذه الميزة إلى إجبار المستجيبين على تسجيل الدخول باستخدام عنوان gmail الخاص بهم لملء النموذج.",
    opening_date: 'تاريخ الافتتاح',
    closing_date: 'تاريخ الإغلاق',
    schedule_form_status: 'جدولة حالة النموذج',
    schedule_quiz_status: 'جدولة حالة الاختبار',
    schedule_opening_date_and_time: "جدولة تاريخ ووقت الافتتاح",
    schedule_closing_date_and_time: "جدولة تاريخ ووقت الإغلاق",
    schedule_opening_date_and_time_description: "تفعيل النموذج في التاريخ والوقت المذكورين",
    schedule_opening_date_and_time_description_quiz: "تفعيل الاختبار في التاريخ والوقت المذكورين",
    schedule_closing_date_and_time_description: "إلغاء تنشيط النموذج في التاريخ والوقت المذكورين",
    schedule_closing_date_and_time_description_quiz: "إلغاء تنشيط الاختبار في التاريخ والوقت المذكورين",
    upgrade: "ترقية",
    gold_plan: "الخطة الذهبية",
    view_plans: "عرض الخطط",
    premium_plans: "الخطط المميزة",
    plan_duration: "/ شهر",
    plan_logic_jump: "قفزة منطقية",
    plan_remove_ads: "إزالة الإعلانات",
    cancel_subscription: "إلغاء الاشتراك",
    quiz_has_answer_edit_message: "تم إرسال هذا الاختبار بالفعل وتم جمع الإجابات. قد يؤثر تعديل الاختبار على الإجابات السابقة",
    quiz_published_alert_edit_message: "تم نشر هذا الاختبار، وإذا قمت بتعديله، فسيتم إلغاء نشره",
    quiz_submit_after_edit_message: "قد يؤثر تعديل الاختبار على العلامات والنسبة المئوية ويتطلب إعادة تقييم بعض الأسئلة",
    title_activity_privacy_policy: "نشاط سياسة الخصوصية",
    action_blocked_message: "تم حظر الإجراء بسبب الإجابات الموجودة لهذا الاختبار. قد يؤثر السماح بذلك على الإجابات السابقة",
    email_addresses: "عناوين البريد الإلكتروني",
    confirmation_message: "رسالة التأكيد",
    confirmation_message_description: "هل تريد عرض رسالة تأكيد بعد إرسال النموذج؟",
    confirmation_message_description_quiz: "هل تريد عرض رسالة تأكيد بعد الإجابة على الاختبار؟",
    default_custom_thank_message: "شكرًا لإكمال هذا النموذج",
    default_custom_thank_message_quiz: "شكرًا لإكمال هذا الاختبار",
    upgrade_your_plan_1: "قم بترقية خطتك!",
    upgrade_your_plan: "قم بترقية خطتك",
    upgrade_your_plan_content: "يرجى ترقية الإصدار المميز للوصول إلى هذه الميزة وزيادة حجم التخزين، إلخ",
    alert_icon: "أيقونة التنبيه",
    usage_details: "تفاصيل الاستخدام",
    current_plan: "الخطة الحالية",
    total_submission: "إجمالي الإرسال",
    image_attachments: "مرفقات الصور",
    custom_themes: "الموضوعات المخصصة",
    free_limit_reached: "لقد وصلت إلى الحد المجاني -",
    payment_done_but_server_error: "تم خصم الدفع بنجاح. يُرجى فتح التطبيق بعد مرور بعض الوقت للسماح لنا بالتحقق من الدفع.",
    response_quota_reached: "لقد وصلت إلى الحد الأقصى للخطة، يُرجى ترقية خطتك للوصول إلى جميع الردود.",
    answers_quota_reached: "لقد وصلت إلى الحد الأقصى للخطة، يُرجى ترقية خطتك للوصول إلى جميع الإجابات.",
    logic_jump_enabled: "تم تمكين القفز المنطقي.",
    logic_jump_disabled: "تم تعطيل القفز المنطقي.",
    cancel_subscription_message: "هل أنت متأكد من أنك تريد إلغاء اشتراكك؟ سيؤدي إلغاء الاشتراك إلى فقدان الوصول إلى جميع الميزات المميزة.",
    plan_expired: "انتهت صلاحية خطتك. يُرجى ترقية خطتك لاستخدام هذه المسودة.",
    plan_expired_edit_form: "انتهت صلاحية خطتك. يرجى ترقية خطتك لتحرير هذا النموذج",
    plan_expired_edit_quiz: "انتهت صلاحية خطتك. يرجى ترقية خطتك لتحرير هذا الاختبار",
    plan_expired_duplicate_form: "انتهت صلاحية خطتك. يرجى ترقية خطتك لتكرار هذا النموذج",
    plan_expired_duplicate_quiz: "انتهت صلاحية خطتك. يرجى ترقية خطتك لتكرار هذا الاختبار",
    premium_feature: "ميزة مميزة",
    plan_file_upload: "صور سمات مخصصة غير محدودة",
    plan_custom_theme: "صور سمات مخصصة غير محدودة",
    plan_call_support: "دعم عالي الأولوية عند الاتصال",
    plan_email_support: "دعم بريد إلكتروني عالي الأولوية",
    plan_custom_domain: "نطاق مخصص",
    subscribe: "اشترك",
    plan_remove_branding: "إزالة العلامة التجارية",
    plan_on_call_support: "دعم عند الاتصال",
    plan_image_attachment: "مرفقات صور غير محدودة",
    plan_custom_thank_you: "صفحات شكر قابلة للتخصيص",
    plan_allowed_responses: "عدد الردود المسموح به 50000",
    trusted_by_number_of_user: "موثوق به من قبل أكثر من 5 ملايين مستخدم",
    email_support: "دعم البريد الإلكتروني",
    call_support: "اتصل بالدعم",
    keyboard_shortcuts: "اختصارات لوحة المفاتيح",
    premium_limit_reached: "لقد وصلت إلى حد الخطة. يُرجى ترقية خطتك للوصول إلى جميع الميزات المميزة",
    premium_limit_reached_mobile: "لقد وصلت إلى حد الخطة",
    unlimited: "غير محدود",
    expired_on_next_renewal: "ستنتهي صلاحيتها في تاريخ التجديد التالي",
    upgrade_plan: "ترقية الخطة",
    downgrade_plan: "خفض الخطة",
    file_upload_questions: "أسئلة تحميل الملف",
    plan_response_edit: "تعديل الرد",
    cant_process: "لا يمكن المعالجة",
    you_have_subscribed_through_android: "لقد اشتركت من خلال تطبيق Android الخاص بنا. يرجى استخدام تطبيق SurveyHeart للحصول على أي تحديثات متعلقة بالدفع.",
    pay_now: "ادفع الآن",
    update_payment_details: "تحديث تفاصيل الدفع",
    owner_is_not_premium_user: "المالك ليس مستخدمًا مميزًا",
    cancel_subscription_description: "هل أنت متأكد أنك تريد إلغاء اشتراكك؟ سيؤدي الإلغاء إلى فقدان الوصول إلى جميع الميزات المميزة.",
    bronze_storage_description: "احصل على مساحة تخزين 25 جيجابايت لإدارة البيانات دون عناء",
    silver_storage_description: "احصل على مساحة تخزين 50 جيجابايت لإدارة البيانات دون عناء",
    gold_storage_description: "احصل على مساحة تخزين 150 جيجابايت لإدارة البيانات دون عناء",
    bronze_responses_description: "استخدم 35000 رد للحصول على رؤى استقصائية قوية",
    silver_responses_description: "استخدم 75000 رد للحصول على رؤى استقصائية قوية",
    gold_responses_description: "استخدم 150000 رد للحصول على رؤى استقصائية قوية",
    bronze_image_description: "عزز استبياناتك بما يصل إلى 200 صورة لجذب الانتباه البصري",
    silver_image_description: "عزز استبياناتك بما يصل إلى 500 صورة لجذب الانتباه البصري",
    gold_image_description: "عزز استبياناتك بصور غير محدودة لجذب الانتباه البصري جاذبية",
    bronze_theme_description: "أضف 200 صورة مخصصة لتخصيص استطلاعاتك لعلامتك التجارية.",
    silver_theme_description: "أضف 500 صورة مخصصة لتخصيص استطلاعاتك لعلامتك التجارية.",
    gold_theme_description: "أضف صورًا مخصصة غير محدودة لتخصيص استطلاعاتك لعلامتك التجارية.",
    custom_theme_image: "صورة موضوع مخصصة",
    file_upload_description: "دمج أسئلة تحميل الملفات غير المحدودة بسلاسة.",
    logic_jump_description: "استخدم قفزات منطقية لتخصيص تدفق الاستطلاع لتجربة كل مستجيب",
    thank_you_message_description: "خصص صفحات الشكر لإبهار المستجيبين.",
    response_edit_description: "قم بتحسين الاستجابات بسهولة باستخدام ميزة تحرير الاستجابات.",
    remove_branding_description: "ارفع من مستوى حضور علامتك التجارية عن طريق إزالة علامة SurveyHeart التجارية.",
    bronze_remove_ads_description: "استمتع بالاستغناء عن الإعلانات مع خطتنا البرونزية وركز على استطلاعات الرأي الخاصة بك.",
    silver_remove_ads_description: "استفد من خدمة الإعلانات المجانية مع خطتنا الفضية وركز على استطلاعات الرأي الخاصة بك.",
    gold_remove_ads_description: "استفد من خدمة الإعلانات المجانية مع خطتنا الذهبية وركز على استطلاعات الرأي الخاصة بك.",
    bronze_email_support_description: "احصل على دعم البريد الإلكتروني للأولويات المنخفضة، مع ضمان المساعدة في الوقت المناسب.",
    silver_email_support_description: "احصل على دعم البريد الإلكتروني للأولويات المتوسطة، مع ضمان المساعدة في الوقت المناسب.",
    gold_email_support_description: "احصل على دعم البريد الإلكتروني للأولويات العالية، مع ضمان المساعدة في الوقت المناسب.",
    bronze_email_support_title: "دعم البريد الإلكتروني للأولويات المنخفضة",
    silver_email_support_title: "دعم البريد الإلكتروني للأولويات المتوسطة",
    gold_email_support_title: "دعم البريد الإلكتروني للأولويات العالية",
    update_your_payment_details_mobile: "يرجى تحديث تفاصيل الدفع الخاصة بك.",
    update_your_payment_details_pc: "نواجه بعض المشكلات في تجديد هذا الحساب. يُرجى تحديث تفاصيل الدفع الخاصة بك.",
    card_details_updated_successfully: "تم تحديث تفاصيل البطاقة بنجاح",
    card_details_updated_successfully_description: "تم تحديث تفاصيل بطاقتك بنجاح. يمكنك الآن الاستمرار في استخدام معلومات الدفع المحدثة الخاصة بك.",
    payment_successful: "تم الدفع بنجاح",
    payment_successful_description: "تم الدفع بنجاح. أنت الآن مشترك في خطة SurveyHeart المميزة. استمتع بكل الميزات الرائعة القادمة إليك.",
    payment_failed: "فشل الدفع!",
    payment_failed_description: "للأسف. لم نتمكن من معالجة اشتراكك في خطة SurveyHeart المميزة. يُرجى المحاولة مرة أخرى.",
    note_for_five_rupees: "ملاحظة: هناك رسوم بقيمة 500 روبية. سيتم فرض رسوم قدرها 5 دولارات لتحديث تفاصيل بطاقتك، وسيتم استردادها خلال 3 إلى 4 أيام عمل إلى نفس الحساب.",
    logic: "المنطق",
    re_generate_code: "إعادة إنشاء الكود",
    plan_change_restrictions: "قيود تغيير الخطة",
    plan_change_restriction_desc_1: "لا يمكنك تغيير خطتك باستخدام UPI",
    plan_change_restriction_desc_2: "التبديل إلى بطاقة للترقيات والتخفيضات، أو إلغاء الاشتراك في خطتك المرغوبة وإعادة الاشتراك فيها",
    update_card_details: "تحديث تفاصيل البطاقة",
    features_and_plans: "الميزات والخطط",
    cancel_plan: "إلغاء الخطة",
    cancel_plan_desc: "لقد ألغيت الخطة. ستظل لديك إمكانية الوصول إلى الميزات المدفوعة حتى تاريخ تجديدك",
    explore_text: "استكشف وافتح ميزات استثنائية في إحدى خطط الاشتراك المميزة لدينا اليوم",
    cant_edit_this_form: "لا يمكن تعديل هذا النموذج",
    cant_edit_this_quiz: "لا يمكن تعديل هذا الاختبار",
    cant_duplicate_this_form: "لا يمكن تكرار هذا النموذج",
    cant_duplicate_this_quiz: "لا يمكن تكرار هذا الاختبار",
    kindly_upgrade_the_premium: "يرجى ترقية الإصدار المتميز للوصول إلى هذه الميزة",
    continue_to_next_question: "الاستمرار إلى السؤال التالي",
    add_logic: "إضافة منطق",
    submit_form: "إرسال النموذج",
    save_logic: "حفظ المنطق",
    remove_logic: "إزالة المنطق",
    responses_add_ons: "إضافة الردود",
    additional_submissions: "عمليات الإرسال الإضافية",
    validity: "الصلاحية",
    up_to_your_current_plan: "حتى خطتك الحالية",
    responses_add_ons_description: "سيتم إضافة عدد الردود هذه إلى خطتك الحالية.",
    redirect_url_title: "إعادة التوجيه إلى الرابط المطلوب",
    redirect_url_description: "هل تريد إعادة توجيه المستخدمين إلى صفحة ويب خارجية؟",
    enter_weblink: "أدخل رابط الويب",
    get: "يحصل",
    jump_to: "الانتقال إلى",
    action_cannot_be_performed: "لا يمكن تنفيذ الإجراء",
    upgrade_plan_desc1: "هل تريد ترقية خطتك إلى",
    downgrade_plan_desc1: "هل تريد تخفيض مستوى خطتك إلى",
    downgrade_plan_desc2: "سيدخل هذا التغيير حيز التنفيذ في تاريخ التجديد التالي.",
    upgraded_plan: "خطة تمت ترقيتها",
    downgraded_plan: "خطة تم تخفيض مستوى خطتك",
    upgraded_plan_desc: "لقد قمت بترقية خطتك. سيدخل هذا التغيير حيز التنفيذ في تاريخ التجديد التالي.",
    downgraded_plan_desc: "لقد قمت بتخفيض مستوى خطتك. سيدخل هذا التغيير حيز التنفيذ في تاريخ التجديد التالي.",
    buy_responses: "شراء الردود",
    passcode: "رمز المرور",
    share_link_with_passcode: "مشاركة الرابط باستخدام رمز المرور"
}