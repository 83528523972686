import React, {useEffect, useState} from "react"
import back from "../../../../public/images/back_white.svg";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import MaterialButton from "../../../shared/components/MaterialButton";
import {createOrderForAddOn, verifyPaymentForAddOn} from "../../../shared/utils/sash-v2-api";
import usePremiumMgr from "../../hooks/usePremiumMgr";
import Loader from "../../../shared/components/Loader";
import PaymentSuccessPopUp from "./PaymentSuccessPopUp";

const MobileResponsesAddOns = () => {
    const {t} = useTranslation()
    const [showLoading, setShowLoading] = useState(false)
    const [showPaymentFailedPopup, setShowPaymentFailedPopup] = useState()
    const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState()
    const {displayRazorpayForAddOns} = usePremiumMgr()
    const dispatch = useDispatch()

    useEffect(() => {
        if (location.hash && location.hash?.split('?')[1]?.split("&")[0] && location.hash?.split('?')[1]?.split("&")[1]) {
            const plan = location.hash?.split('?')[1]?.split("&")[0]
            const token = location.hash?.split('?')[1]?.split("&")[1]
            switch (plan) {
                case ("small"):
                    handleGetAddOnClick("small", token)
                    break
                case ("medium"):
                    handleGetAddOnClick("medium", token)
                    break
                case ("large"):
                    handleGetAddOnClick("large", token)
                    break
                default:
                    handleGetAddOnClick("medium", token)
            }
        }
    }, [])

    const handleBackArrowClick = () => {
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
    }

    const verifyRazorpayPayment = (result, token) => {
        setShowLoading(true)
        setShowPaymentFailedPopup(false)
        const successFunction = (res) => {
            if (res?.result) {
                setShowLoading(false)
                setShowPaymentFailedPopup(false)
                setShowPaymentSuccessPopup(true)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
            setShowPaymentFailedPopup(true)
        }
        if (result?.razorpay_order_id && result?.razorpay_signature && result?.razorpay_payment_id && token) {
            verifyPaymentForAddOn(result.razorpay_order_id, result.razorpay_payment_id, result.razorpay_signature, successFunction, failureFunction, token)
        } else {
            if (result?.razorpay_order_id && result?.razorpay_signature && result?.razorpay_payment_id) {
                verifyPaymentForAddOn(result.razorpay_order_id, result.razorpay_payment_id, result.razorpay_signature, successFunction, failureFunction)
            }
        }
    }

    const handleGetAddOnClick = (plan, token) => {
        const successFunction = (res) => {
            if (res.order_id) {
                displayRazorpayForAddOns(res.order_id, (result) => verifyRazorpayPayment(result, token), failureFunction)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (token) {
            createOrderForAddOn(plan, successFunction, failureFunction, token)
        } else {
            createOrderForAddOn(plan, successFunction, failureFunction)
        }
    }

    return (
        <div>
            <div id={'mobile-header-tab-row'} className='mobile-header-tab-row'>
                <div style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    paddingTop: '10px'
                }}>
                    <img src={back} alt={'back icon'}
                         onClick={handleBackArrowClick}
                         style={{width: '15px', height: '15px', margin: '5px 10px 0px 10px'}}/>
                    <div style={{flexGrow: "1"}}
                         className={'header-button center'}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: 'white',
                                            paddingBottom: '7px',
                                            fontWeight: '400',
                                            textAlign: "start",
                                            width: '-webkit-fill-available'
                                        }}>
                                        {t(translationKeys.responses_add_ons)}
                                    </span>
                    </div>
                </div>
            </div>
            <div style={{margin: "20px"}}>
                <p style={{display: "block", color: "#4D4D4D", textAlign: "center", fontSize: "20px"}}>
                    This responses counts will be added to your existing plan.
                </p>
                <div style={{
                    boxShadow: "0 3px 9px 0 #00000040",
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: "10px",
                    background: "#FFFFFF"
                }}>
                    <p style={{color: "#0045A0", fontSize: "24px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                        2500/Life time
                    </p>
                    <span style={{fontSize: "16px"}}>Additional Submission</span>
                    <p style={{fontSize: "26px", fontWeight: "bold", margin: "10px auto"}}>Rs. 1000</p>
                    <MaterialButton data={{
                        title: "GET",
                        bg_color: '#1976D2',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        customButtonContainerStyle: {
                            borderRadius: '24px',
                            padding: '15px 35px',
                            width: '100px',
                            height: '40px'
                        },
                        customButtonTextStyle: {
                            fontWeight: "bold"
                        }
                    }} handleClick={() => handleGetAddOnClick("small")}/>
                </div>
                <div style={{
                    boxShadow: "0 3px 9px 0 #00000040",
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "20px 0",
                    background: "#FFFFFF"
                }}>
                    <p style={{color: "#0045A0", fontSize: "24px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                        5000/Life time
                    </p>
                    <span style={{fontSize: "16px"}}>Additional Submission</span>
                    <p style={{fontSize: "26px", fontWeight: "bold", margin: "10px auto"}}>Rs. 2000</p>
                    <MaterialButton data={{
                        title: "GET",
                        bg_color: '#1976D2',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        customButtonContainerStyle: {
                            borderRadius: '24px',
                            padding: '15px 35px',
                            width: '100px',
                            height: '40px'
                        },
                        customButtonTextStyle: {
                            fontWeight: "bold"
                        }
                    }} handleClick={() => handleGetAddOnClick("medium")}/>
                </div>
                <div style={{
                    boxShadow: "0 3px 9px 0 #00000040",
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: "10px",
                    background: "#FFFFFF"
                }}>
                    <p style={{color: "#0045A0", fontSize: "24px", fontWeight: "bold", margin: "10px 0 0 0"}}>
                        10000/Life time
                    </p>
                    <span style={{fontSize: "16px"}}>Additional Submission</span>
                    <p style={{fontSize: "26px", fontWeight: "bold", margin: "10px auto"}}>Rs. 3500</p>
                    <MaterialButton data={{
                        title: "GET",
                        bg_color: '#1976D2',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        customButtonContainerStyle: {
                            borderRadius: '24px',
                            padding: '15px 35px',
                            width: '100px',
                            height: '40px'
                        },
                        customButtonTextStyle: {
                            fontWeight: "bold"
                        }
                    }} handleClick={() => handleGetAddOnClick("large")}/>
                </div>
                <p style={{color: "#424242", textAlign: "center", fontSize: "20px"}}>
                    <span style={{fontWeight: "bold"}}>Validity: </span>Up to your current plan
                </p>
            </div>
            {showLoading ? <Loader width={'100%'} height={'100%'}/> : null}
            {showPaymentSuccessPopup ? <PaymentSuccessPopUp success={true}
                                                            title={t(translationKeys.payment_successful)}
                                                            description={t(translationKeys.payment_successful_description)}
                                                            handlePopUpClick={() => {
                                                                if (location.hash?.split('?')[1]?.split("&")[0]) {
                                                                    window.location = "https://surveyheart.com"
                                                                } else {
                                                                    setShowPaymentSuccessPopup(false)
                                                                    window.location.reload()
                                                                }
                                                            }}/> : null}
            {showPaymentFailedPopup ? <PaymentSuccessPopUp success={false}
                                                           title={t(translationKeys.payment_failed)}
                                                           description={t(translationKeys.payment_failed_description)}
                                                           handlePopUpClick={() => {
                                                               if (location.hash?.split('?')[1]?.split("&")[0]) {
                                                                   window.location = "https://surveyheart.com"
                                                               } else {
                                                                   setShowPaymentFailedPopup(false)
                                                               }
                                                           }}/> : null}
        </div>
    )
}

export default MobileResponsesAddOns
