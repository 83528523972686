export const translationsPersian = {
    all: 'همه',
    ago: "پیش",
    now: "اکنون",
    start: "شروع کنید",
    account_blocked: "حساب مسدود شد.",
    account_blocked_alert: "این حساب به دلیل نقض شرایط و ضوابط مسدود شد.",
    sign_out: "خروج",
    short_answer: "پاسخ کوتاه",
    long_answer: "پاسخ طولانی",
    email: "ایمیل",
    number: "شماره",
    file: "فایل",
    files: "فایل ها",
    faq_videos: "سوالات متداول ویدئوها",
    file_upload: "آپلود فایل",
    question_error: "سوال اضافه نمی شود",
    storage: "ذخیره سازی",
    form: "فرم",
    quiz: "آزمون",
    fup_desc: "اکنون می توانید به پاسخ دهندگان فرم ها و آزمون های خود اجازه دهید فایل ها را آپلود کنند!\n\nبرای شروع، نوع سوال آپلود فایل را انتخاب کنید.",
    new_fup_feature_title: "ویژگی آپلود فایل جدید!",
    multiple_choice: "چند گزینه ای",
    date_text: "تاریخ",
    time_text: "زمان",
    uploading: "در حال بارگذاری…",
    add_question: "افزودن سوال",
    add_question_alert: "لطفاً حداقل یک سوال با تمام موارد لازم بیافزایید.",
    add_question_alert_quiz: "لطفاً حداقل یک سوال را قبل از ارسال مسابقه اضافه کنید",
    ok: "بسیار خب!",
    title: "عنوان",
    title_required: "نیازمند عنوان است.",
    form_name_alert: "لطفاً پیش از نشر، فرم را نامگذاری کنید.",
    quiz_name_alert: "لطفا پیش از نشر، آزمون را نامگذاری کنید.",
    no_connection: "قطع ارتباط",
    check_connection: "لطفاً اتصال شبکه خود را بررسی نمائید.",
    finish_incomplete_question: "لطفاً سوالات تکمیل نشده را در فرم کامل کنید.",
    draft: "پیش نویس",
    save_to_draft_alert: "آیا مایلید برای اجتناب از حذف تغییرات، فرم را به عنوان پیش نویس ذخیره نمائید؟",
    save_to_draft_quiz_alert: "آیا مایلید برای اجتناب از حذف تغییرات، آزمون را به عنوان پیش نویس ذخیره نمائید؟",
    yes: "بله",
    no: "خیر",
    cancel: "لغو",
    live_preview: "پیش نمایش زنده",
    themes: "پس زمینه",
    tap_to_set_theme: "برای انتخاب زمینه فرم، ضربه بزنید.",
    select_image: "انتخاب تصویر",
    created: "ایجاد شد.",
    form_created_successfully: "فرم با موفقیت ایجاد شد.",
    warning: "هشدار",
    form_edit_alert: "این فرم قبلاً ثبت شده و پاسخ ها جمع آوری گردیده است. با ویرایش فرم، احتمال تغییر در پاسخ های قبلی وجود دارد.",
    quiz_edit_alert: "این مسابقه قبلا ارسال شده و پاسخ ها جمع آوری شده است. ویرایش مسابقه ممکن است بر پاسخ های قبلی تأثیر بگذارد",
    edit: "ویرایش",
    download_as: "بارگیری تحت عنوان…",
    delete_form: "حذف فرم",
    delete_form_alert: "آیا مایل به حذف فرم ها هستید؟ این عمل غیر قابل بازگشت است.",
    delete_quizzes_alert: "آیا مایل به حذف آزمون ها هستید؟ این عمل غیر قابل بازگشت است.",
    delete_forms_alert: "آیا مایل به حذف فرم ها هستید؟ این عمل غیر قابل بازگشت است.",
    delete_quiz_alert: "آیا مایل به حذف آزمون هستید؟ این عمل غیر قابل بازگشت است.",
    delete_answers_alert: "آیا می خواهید این پاسخ ها را حذف کنید؟ این عمل قابل بازگشت نیست.",
    delete_answer_alert: "آیا می خواهید این پاسخ را حذف کنید؟ این عمل قابل بازگشت نیست.",
    delete: "حذف",
    delete_all: "حذف همه",
    section: "بخش",
    worst: "بدتربن",
    server_error_alert: "سرور یافت نشد. لطفاً پس از مدتی مجدداً تلاش کنید.",
    signed_in_as: "ورود با نام…",
    signing_out: "در حال خروج…",
    create_form: "ایجاد فرم",
    create_blank_form: "ایجاد فرم خالی",
    create_blank_quiz: "ایجاد امتحان خالی",
    close: "بستن",
    response_collection_exit_alert: "آیا می خواهید از جمع آوری پاسخ خارج شوید؟",
    form_builder: "سازنده فرم",
    form_builder_promo: "پیش فرض ها، قالب ها و زمینه های فوری برای فرم و آزمون",
    results: "نتایج",
    result_feature_promo: "نتایج بررسی را تحلیل و استخراج نمائید.",
    notification: "اعلان های/اطلاعیه فوری",
    delete_option_alert: "آیا مایل به حذف هستید؟ (توجه: این عمل غیر قابل بازگشت است.)",
    question_type: "نوع سوال",
    change_question_type_alert: "جهت تغییر نوع سوال ضربه بزنید.",
    preview_not_available: "پیش نمایش در دسترس نیست.",
    delete_question: "حذف سوال",
    delete_response_collected_question_alert: "حذف سوال بر پاسخ های گردآوری شده تأثیر می گذارد. آیا از حذف سوال اطمینان دارید؟",
    delete_question_alert: "آیا از حذف سوال اطمینان دارید؟",
    options: "گزینه ها",
    option: 'گزینه',
    no_answer: "بدون پاسخ",
    no_response: "بدون پاسخ",
    responses: "پاسخ ها",
    response: "پاسخ",
    serial_number: "اس.نه",
    submit_time: "ثبت زمان",
    general_form_type_description: "ایجاد فرم/نظرسنجی جدید",
    quiz_type_description: "ایجاد آزمون جدید",
    sheet_disconnected: "Google Sheet قطع شد",
    sheet_not_connected: 'ورق متصل نیست',
    sheet_not_connected_desc: 'لطفاً برای اتصال صفحه Google با مالک تماس بگیرید.',
    export_success: "استخراج با موفقیت انجام شد.",
    answer: "جواب",
    answer_with_colan: "جواب:",
    others: "سایر",
    delete_response: "حذف پاسخ",
    deleted: "حذف شده",
    my_themes: "پس زمینه من",
    limit_reached: "حد نصاب",
    limit_reached_description: "شما به حد مجاز آپلود تصویر رسیده اید.",
    modified: "اصلاح شده",
    delete_image: "حذف تصویر",
    delete_image_alert: "آیا از حذف تصویر اطمینان دارید؟",
    delete_theme_image_desc: "آیا می خواهید این تصویر را حذف کنید؟ اگر ادامه دهید، این تصویر برای هیچ فرم یا آزمونی که از آن استفاده می کند در دسترس نخواهد بود. این عمل قابل لغو نیست.",
    section_title: "عنوان بخش",
    section_description: "شرح بخش",
    show_scale: 'نمایش مقیاس',
    show_options: "نمایش گزینه ها",
    options_required: "نیازمند گزینه است.",
    link_copied: "لینک کپی شد.",
    app_not_found: "برنامه ای یافت نشد.",
    default_text: "پیش فرض",
    dark_mode: "حالت تاریک",
    done: "انجام شد",
    upload_image: "بارگذاری تصویر",
    question: "سوال",
    required: "مورد نیاز است.",
    share: "اشتراک گذاری",
    form_link: "لینک فرم",
    copy_link: "کپی لینک",
    view_form: "مشاهده فرم",
    menu: "فهرست",
    view_all: "مشاهده همه موارد",
    settings: "تنظیمات",
    submit: "ثبت",
    change_theme: "تغییر پس زمینه",
    tap_here_to_choose_theme: "برای انتخاب پس زمینه ضربه بزنید.",
    show_logo: "نمایش لوگو",
    show_logo_alert: "آیا مایلید لوگو در فرم نمایش داده شود؟",
    welcome_page: "صفحه اصلی",
    show_welcome_page_alert: "آیا مایلید صفحه اصلی نمایش داده شود؟",
    start_button: "دکمه شروع",
    allow_multiple_responses: "اجازه چند پاسخ",
    allow_more_response_alert: "آیا مایلید کاربر بیش از یک مرتبه قادر به پاسخ باشد؟",
    allow_more_quiz_attempt_alert: "آیا مایلید کاربر بیش از یک مرتبه تلاش کند؟",
    show_summary: "نمایش خلاصه",
    show_results: "نمایش نتایج آزمون",
    allow_responders_view_summary_alert: "آیا می خواهید برای پاسخ دهندگان پس از ارسال، خلاصه پاسخ ها نمایش داده شود؟",
    allow_result_view_quiz_alert: "آیا می خواهید کاربر نتایج را مشاهده کند؟",
    show_question_number: "نمایش شماره سوال",
    show_question_number_in_form: "آیا می خواهید شماره سؤالات را در فرم نشان دهید؟",
    show_question_number_in_quiz: "آیا می خواهید شماره سوالات را در مسابقه نشان دهید؟",
    shuffle_questions: "بر زدن سوالات",
    shuffle_questions_alert: "آیا مایلید سوالات فرم را بر بزنید.",
    shuffle_quiz_questions_alert: "آیا مایلید سوالات آزمون را بر بزنید.",
    description: "شرح",
    builder: "سازنده",
    preview: "پیش نمایش",
    form_status: "وضعیت فرم",
    active: "فعال",
    inactive: "غیر فعال",
    import_questions: 'سوالات واردات',
    search_by_question_title_from_forms: 'جستجو بر اساس عنوان سوال از فرم های موجود',
    search_by_question_title_from_quizzes: 'جستجو بر اساس عنوان سوال از آزمون های موجود',
    view_responses: "نمایش پاسخ ها",
    edit_form: "ویرایش فرم",
    duplicate_form: "تکثیر فرم",
    drafts: "پیش نویس",
    no_form_available: "فرمی در دسترس نمی باشد!",
    notifications: "اعلان ها",
    feedback: "بازخورد",
    privacy_policy: "حریم خصوصی",
    open: 'باز کردن',
    open_individual_responses: "باز کردن پاسخ های شخص",
    bar_chart: "نمودار میله ای",
    pie_chart: "نمودار دایره ای",
    summary: "خلاصه",
    invalidated_form_cannot_be_selected: "فرم نامعتبر قابل انتخاب نیست.",
    invalidated_quiz_cannot_be_selected: "آزمون نامعتبر قابل انتخاب نیست.",
    individual: "شخص",
    search: "جستجوی…",
    image: "تصویر",
    video: "ویدئو",
    website: "وب سایت",
    exit: "خروج",
    response_limit_reached: "پاسخ به حد نصاب رسیده است.",
    response_limit_reached_alert: "پاسخ های فرم بیش از حد مجاز است. از پذیرش پاسخ های بیشتر معذوریم، می توانید آن را تکثیر و لینک را به اشتراک گذارید.",
    unapproved_form: "فرم تأیید نشده",
    unapproved_form_alert: "فرم حاوی عباراتی است که قوانین ما را نقض می کند. چنانچه تصور می کنید فرم به اشتباه مسدود شده است، لطفاً لینک زیر را باز کنید و اطلاعات درخواستی را ارائه نمائید.",
    choose_your_language: "زبان خود را انتخاب کنید.",
    provide_explanation: "ارائه توضیح",
    selected: "انتخاب شده",
    review_button: "مرور",
    not_specified: "نامشخص",
    quiz_control: "کنترل آزمون",
    allow_multiple_attempts: "اجازه چندین تلاش",
    user_info: "اطلاعات کاربر",
    name_s: "نام",
    duration: "مدت زمان",
    set_duration_quiz_alert: "آیا می خواهید برای آزمون مدت زمان تعیین کنید؟",
    user_information_description: "آیا مایل به دریافت اطلاعات شرکت کنندگان هستید؟",
    set_duration: "مدت زمان (ساعت : دقیقه)",
    minutes: "دقیقه ها",
    hours: "ساعت ها",
    minute: "دقیقه",
    hour: "ساعت",
    correct: "درست",
    wrong: "نادرست",
    skipped: "از قلم افتاده",
    score: "امتیاز",
    forms: "فرم ها",
    quizzes: "آزمون ها",
    more: "بیشتر",
    quiz_status: "وضعیت آزمون",
    view_answers: "نمایش پاسخ ها",
    view_quiz: "نمایش آزمون",
    duplicate_quiz: "تکثیر آزمون",
    no_quiz_available: "آزمونی موجود نیست!",
    quiz_link: "لینک آزمون",
    unapproved_quiz: "آزمون تأیید نشده",
    unapproved_quiz_alert: "این آزمون حاوی عباراتی است که سیاست های ما را نقض می کند. چنانچه تصور می کنید آزمون به اشتباه مسدود شده است، لطفاً لینک زیر را باز کنید و اطلاعات درخواستی را ارائه نمائید.",
    do_you_want_to_show_all_correct_answers_to_users: "آیا مایلید پاسخ صحیح به کاربران نمایش داده شود؟",
    disconnect: 'قطع',
    results_publish_date: "تاریخ انتشار نتایج",
    do_you_want_to_set_date_to_publish_results: "آیا مایلید برای انتشار نتایج، زمان تعیین کنید؟",
    quiz_result: "نتیجه آزمون",
    enter_answer: "پاسخ",
    manage: "مدیریت کنید",
    manage_storage: 'مدیریت ذخیره سازی',
    select_all: 'انتخاب همه',
    shuffle_options: 'گزینه های مختلط',
    shuffle_options_enabled: 'گزینه های ترکیبی فعال شد',
    shuffle_options_disabled: 'گزینه های ترکیبی غیرفعال است',
    add_options_to_enable_this_feature: 'گزینه هایی را برای فعال کردن این ویژگی اضافه کنید',
    attach: 'ضمیمه کنید',
    shuffle: 'بر زدن',
    marks: "نمرات",
    mark: "نمره",
    correction_status: "وضعیت:",
    status_validated: "تأیید شده",
    pending: "در انتظار",
    results_publish: "انتشار",
    publish: "منتشر شده",
    publish_description: "آیا مایلید نتایج آزمون را نشر دهید؟",
    publish_description_note: "(.امتیاز نهایی در پاسخ نامه پاسخ دهندگان نمایش داده می شود)",
    published_successfully: "انتشار با موفقیت انجام شد!",
    choice_type_validation_note: "چند گزینه ای بطور خودکار تأیید شد.",
    short_answer_type_validation_note: "پاسخ کوتاه بطور خودکار تأیید شد.",
    long_answer_type_validation_note: "پاسخ طولانی بطور خودکار رد شد.",
    fileupload_answer_type_validation_note: "* آپلود فایل به صورت خودکار تایید نمی شود",
    dropdown_answer_type_validation_note: "*انتخاب کشویی به صورت خودکار تایید می شود",
    save: "ذخیره",
    questions: "سوالات",
    show_correct_answer: "نمایش پاسخ صحیح",
    show_answer: "نمایش پاسخ",
    hide_answer: "پنهان سازی پاسخ",
    no_marks: "بدون نمره",
    question_is_required: "نیازمند درج عنوان سوال است.",
    marks_is_required: "نیازمند درج نمره است.",
    options_are_required: "نیازمند درج گزینه است.",
    options_limit_reached: 'به محدودیت گزینه ها رسیده است',
    options_limit_reached_description: 'شما به حد مجاز تعداد گزینه های مجاز برای یک سوال رسیده اید.',
    correct_answer_required: "نیازمند درج جواب صحیح است.",
    enter_valid_mark: "نمره معتبر وارد کنید.",
    evaluated: "ارزیابی شده",
    saved: "ذخیره شده",
    pending_evaluation: "در انتظار ارزیابی",
    alphabet: "الفبا",
    not_evaluated: "ارزیابی نشده است.",
    percentage_ascending: "درصدها بصورت صعودی",
    percentage_descending: "درصدها بصورت نزولی",
    pending_evaluation_alert_message: "پیش از انتشار می بایست تمام جواب ها را ارزیابی کنید.",
    show_unevaluated: "نمایش موارد ارزیابی نشده",
    publish_success_alert_message: "پاسخ دهندگان می توانند نتایج خود را در پاسخ نامه های مربوطه مشاهده کنند. آزمون بطور خودکار پایان یافت.",
    publishing: "در حال نشر",
    quiz_published: "آزمون نشر یافت.",
    quiz_published_alert_before_edit_message: "آزمون نشر یافت، اگر می خواهید تغییراتی اعمال کنید، آزمون باید تکثیر و مجدداً به اشتراک گذاشته شود.",
    quiz_has_answers: "آزمون دارای جواب است.",
    quiz_has_answer_alert_message: "جهت ویرایش آزمون، باید تمام جواب های موجود حذف گردد.",
    unavailable_features_description: 'این فرم دارای ویژگی هایی است که هنوز در برنامه وب پشتیبانی نمی شوند',
    unavailable_quiz_features_description: 'این مسابقه دارای ویژگی هایی است که هنوز در برنامه وب پشتیبانی نمی شوند',
    unavailable: 'غیر قابل دسترسی',
    unpublished: "منتشر نشده",
    published: "منتشر شده",
    setting_updated: "تنظیمات به روز رسانی شد.",
    account: "حساب",
    on: "روشن",
    off: "خاموش",
    scan_qr_code: "کد QR را اسکن کنید",
    answers: "پاسخ می دهد",
    answer_s: "پاسخ",
    question_limit_reached: "حد س Questionال رسیده است",
    reached_limit_for_number_of_questions: "شما به تعداد سالات مجاز در فرم رسیده اید. بنابراین ، هیچ سوال جدیدی نمی تواند اضافه شود",
    life_time: "طول عمر",
    last_year: "سال گذشته",
    last_30_days: "30 روز گذشته",
    last_7_days: "7 روز گذشته",
    last_24_hours: "24 ساعت گذشته",
    custom_date: "تاریخ سفارشی",
    filter_by_date: "فیلتر بر اساس تاریخ",
    filter_by_question: "فیلتر بر اساس سال",
    filter_text: "متن را فیلتر کنید",
    add_filter: "افزودن فیلتر",
    apply: "درخواست دادن",
    from_date: "از تاریخ",
    today: 'امروز',
    to_date: "به روز",
    contains: "دارای",
    equal_to: "مساوی با",
    not_equal_to: "برابر نیست با",
    from_date_required: "از تاریخ مورد نیاز",
    to_date_required: "به تاریخ مورد نیاز است",
    webapp_promo_text: "به فرم ها ، نظرسنجی ها ، نظرسنجی ها و آزمون های آنلاین خود دسترسی پیدا کنید!",
    question_title: "سوال",
    invalid_date: "تاریخ نامعتبر است",
    invalid_url: "URL نامعتبر است",
    invalid_url_description: "به نظر می رسد URL معتبر نیست.",
    delete_response_alert: "آیا مایل به حذف پاسخ هستید؟ این عمل غیر قابل بازگشت است.",
    delete_responses_alert: "آیا می خواهید این پاسخ ها را حذف کنید؟ این عملکرد قابل واگرد نیست.",
    overview: "بررسی اجمالی",
    no_results_found: "نتیجه ای پیدا نشد",
    date_format: "dd-mm-yyyy",
    title_dashboard: "داشبورد",
    title_notifications: "اطلاعیه",
    select_one_response: "لطفاً حداقل یک پاسخ را برای حذف انتخاب کنید",

//Untranslated strings

    your_account: "حساب شما",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    favourites: "موارد دلخواه",
    shared: "اشتراک گذاشت",
    hide: "پنهان شدن",
    use_templates: "از قالب ها استفاده کنید",
    quick_settings: "تنظیمات سریع",
    leave_collaboration: "همکاری را ترک کنید",
    templates: "قالب ها",
    collapse: "سقوط - فروپاشی",
    start_scale_from_zero: 'مقیاس شروع از 0',
    center_label_optional: 'برچسب مرکز (اختیاری)',
    left_label_optional: 'برچسب چ( اختیاری )',
    right_label_optional: 'برچسب راست (اختیاری)',
    duplicate: "تکراری",
    row: "ردیف",
    column: "ستون",
    rows: "ردیف",
    add_column: 'افزودن ستون',
    add_row: 'افزودن سطر',
    user_info_for_file_upload: "اطلاعات کاربر برای آپلود فایل",
    user_info_for_file_upload_desc1: "از آنجایی که شما یک سوال آپلود فایل اضافه کرده اید، گزینه جمع آوری اطلاعات کاربر فعال شده است. این کار شناسایی فایل های دانلود شده را آسان تر می کند. پاسخ دهندگان یک گفتگو برای وارد کردن اطلاعات خود می بینند.",
    user_info_for_file_upload_desc2: "اگر نمی‌خواهید گفتگو نمایش داده شود، می‌توانید این گزینه را در تنظیمات تغییر دهید.",
    show_rows_and_columns: " نمایش سطرها & ستون",
    scale_down: 'مقیاس پایین',
    scale_up: 'مقیاس بالا',
    dislike: 'دوست نداشتن',
    like: 'مانند',
    best: 'بهترین',
    remove_favourite: "حذف مورد علاقه",
    add_favourite: "موارد دلخواه را اضافه کنید",
    collaborator: "همکار",
    collaborators: "همکاران",
    show_more: "بیشتر نشان بده، اطلاعات بیشتر",
    create_quiz: "امتحان ایجاد کنید",
    collaborate: "همکاری",
    share_QR_code: "کد QR را به اشتراک بگذارید",
    add_image: "تصویر اضافه کن",
    invalid_file_type: "نوع فایل نامعتبر",
    drag_drop: "کشیدن و رها کردن",
    here: "اینجا",
    choose: "انتخاب کنید",
    add_account: 'اضافه کردن حساب',
    choose_account: 'انتخاب حساب کاربری',
    maximum_allowed_file_size: "حداکثر اندازه مجاز فایل است ",
    or: "یا",
    next: "بعد",
    change_image: "تغییر تصویر",
    upload: "بارگذاری",
    view: "چشم انداز",
    collaboration: "همکاری",
    share_collaboration_form: "آیا می خواهید این فرم را با دیگران به اشتراک بگذارید؟?",
    share_collaboration_quiz: "آیا می خواهید این مسابقه را با دیگران به اشتراک بگذارید؟",
    multiple_responses_summary: "پاسخ های متعدد, خلاصه",
    question_number_shuffle: "شماره سوال, علامت سوال, بر زدن",
    general: "عمومی",
    browse: "مرور کردن",
    owner: "مالک",
    something_went_wrong: "مشکلی پیش آمد!",
    forget_your_password: "رمزتان را فراموش کرده اید?",
    sign_in: "ورود",
    sign_up: "ثبت نام",
    using_email: "با استفاده از ایمیل",
    dont_have_account: "حساب کاربری ندارید?",
    on_signing_up_agree_to_surveyhearts: "با ثبت نام, yشما موافق هستید SurveyHeart's",
    and: "و",
    terms_of_use: "شرایط استفاده",
    already_have_an_account: "قبلاً یک حساب کاربری دارید?",
    signed_in_previously_as_a_google_user: "شما قبلاً به عنوان یک کاربر Google وارد سیستم شده اید.", //
    sign_in_google_to_continue: "لطفاً برای ادامه از طریق Google وارد شوید.",
    no_account_found_email_address: "هیچ حسابی با این آدرس ایمیل پیدا نشد",
    account_already_exist_email: "حساب از قبل برای این ایمیل وجود دارد.",
    install_surveyheart_app: "نصب  SurveyHeart برنامه",
    easy_login_via_app: "ورود آسان از طریق برنامه",
    get_notification_instantly: "دریافت اعلان فورا",
    create_form_quiz_on_the_go: "ایجاد فرم / مسابقه در حال حرکت",
    check_your_inbox_to_activate_your_account: "لطفا صندوق ورودی خود را بررسی کنید تا حساب خود را فعال کنید",
    select_questions: "سوالات را انتخاب کنید",
    percentage: "درصد",
    free_App_to_create_forms: "برنامه رایگان برای ایجاد فرم ها را دریافت کنید",
    we_cant_find_the_page_that_you_are_looking_for: "ما نمی توانیم صفحه مورد نظر شما را پیدا کنیم",
    answered: "جواب داد",
    completing_this_quiz: "با تشکر برای تکمیل این مسابقه",
    connected_successfully: 'با موفقیت متصل شد',
    seconds: "ثانیه ها",
    enter_your_details: "مشخصات خود را وارد کنید",
    enter_all_required_information: "لطفا تمام اطلاعات مورد نیاز را وارد کنید",
    answer_data_not_found: "پاسخ داده ها یافت نشد",
    results_published_soon: "نتایج به زودی منتشر خواهد شد",
    previous: 'قبلی',
    save_link_for_future_reference: "این لینک را برای مراجعات بعدی ذخیره کنید",
    choice_questions_only: "فقط سوالات انتخاب",
    score_is_not_final: "توجه: این امتیاز نهایی نیست",
    tabular: "جدولی",
    text: "متن",
    answer_response_alert: "این سوال یک جواب می خواهد",
    invalid_email_address: "آدرس ایمیل نامعتبر است",
    answer_not_available: "پاسخ در دسترس نیست",
    validated_automatically: "* به صورت خودکار تایید می شود",
    answer_link: "لینک پاسخ",
    embed_code: 'کد را جاسازی کنید',
    copy_code: 'کد را کپی کنید',
    code_copied: 'کد کپی شد',
    share_via: "اشتراک گذاری از طریق",
    print: "چاپ",
    columns: 'ستون ها',
    columns_all: "ستون ها (همه)",
    submitted_time: "زمان ارسال شده",
    unable_to_submit: 'قادر به ارسال نیست',
    unable_to_submit_alert_line1: 'از آنجایی که این آزمون پاسخ هایی دریافت کرده است، دسترسی محدودی برای ایجاد تغییرات دارید.',
    unable_to_submit_alert_line2: 'برای دسترسی کامل به ویرایش این مسابقه، باید تمام پاسخ های موجود را حذف کنید.',
    action_blocked: 'اقدام مسدود شده است',
    action_blocked_alert: 'به دلیل پاسخ‌های موجود برای این مسابقه، اقدام مسدود شد. اجازه دادن به این ممکن است بر پاسخ های قبلی تأثیر بگذارد.',
    quiz_edit_submit_alert: 'ویرایش آزمون ممکن است بر نمرات، درصد تأثیر بگذارد و نیاز به ارزیابی مجدد سؤالات خاص داشته باشد.',
    continue: 'ادامه هید',
    submitted: "ارسال شده",
    submitting: "در حال ارائه",
    filter: "فیلتر",
    exit_screen: "خروج از صفحه نمایش",
    full_screen: "تمام صفحه",
    tabular_view: "نمای جدولی",
    clear_all: "همه را پاک کن",
    you_are_all_caught_up: "همه شما گرفتار شده اید!",
    check_back_later_for_new_notifications: "بعداً برای اعلان‌های جدید دوباره بررسی کنید",
    requires_answer: "نیاز به پاسخ دارد",
    enter_member_id: "شناسه عضو را وارد کنید",
    viewers_can_read_form_responses: "بینندگان می توانند فرم را بخوانند و پاسخ ها را مشاهده کنند",
    editors_edit_form_view_responses: "ویراستاران می توانند فرم را ویرایش کرده و پاسخ ها را مشاهده کنند",
    admin_edit_form_add_collaborator: "مدیر می تواند فرم را ویرایش کند و همکار اضافه کند",
    invalid_member_id: "شناسه عضو نامعتبر", //
    already_member_added: "قبلاً این عضو اضافه شده است",
    add_collaborator: "افزودن همکار",
    admin: "مدیر",
    editor: "ویرایشگر",
    viewer: "بیننده",
    searched_form_not_found: "فرم جستجو شده یافت نشد!",
    searched_quiz_not_found: "امتحان جستجو یافت نشد!",
    no_shared_data_available: "هیچ داده مشترکی در دسترس نیست!",
    forms_server_is_not_available: "سرور فرم ها در دسترس نیست",
    quiz_server_is_not_available: "سرور آزمون در دسترس نیست",
    leave: 'ترک کردن',
    password: 'کلمه عبور',
    new_password: 'رمز عبور جدید',
    retype_password: 'رمز عبور را دوباره تایپ کنید',
    create_password: 'رمز عبور ایجاد کنید',
    view_results: 'مشاهده نتایج',
    control: 'کنترل',
    q_control: 'Q. کنترل',
    q_result: 'Q. نتیجه',
    no_matches_found: "جستجو حاصلی دربرنداشت",
    dropdown: "کشویی",
    checkboxes: "چک باکس ها",
    star_rating: 'رتبه بندی ستاره ای',
    linear_scale: 'مقیاس خطی',
    multiple_choice_grid: 'شبکه چند گزینه ای',
    checkboxes_grid: 'توری جعبهی چک',
    smile: 'لبخند',
    info_1: "اطلاعات 1",
    info_2: "اطلاعات 2",
    info_3: "اطلاعات 3",
    new: 'جدید',
    download_all: 'دانلود همه',
    download: 'دانلود',
    sort_by: 'مرتب سازی بر اساس',
    sorting: 'مرتب سازی',
    refresh: 'تازه کردن',
    add: 'اضافه کردن',
    refresh_preview_description: "برای به‌روزرسانی پیش‌نمایش، روی دکمه «Refresh» کلیک کنید",
    enter_users_email_address: 'آدرس ایمیل کاربر را وارد کنید',
    do_you_want_to_delete_these: 'میخوای اینا رو پاک کنی',
    do_you_want_to_delete_this_form: 'آیا می خواهید این فرم را حذف کنید؟',
    all_responses_will_also_be_deleted: 'تمامی پاسخ ها نیز حذف خواهند شد',
    this_action_cannot_be_undone: 'این عمل قابل لغو نیست',
    do_you_want_to_delete_this_quiz: 'آیا می خواهید این آزمون را حذف کنید',
    all_answer_sheets_will_also_be_unavailable: 'تمام پاسخنامه ها نیز در دسترس نخواهد بود.',
    change: 'تغییر دادن',
    roles_rights: 'نقش ها و حقوق',
    learn_more: 'بیشتر بدانید',
    viewers_can_read_quiz_and_view_answers: 'بینندگان می توانند مسابقه را بخوانند و پاسخ ها را مشاهده کنند',
    editors_can_edit_quiz_view_answers: 'ویراستاران می توانند مسابقه را ویرایش کنند و پاسخ ها را مشاهده کنند',
    admin_can_edit_quiz_add_collaborator: ' مدیر می تواند مسابقه را ویرایش کند و همکار اضافه کند',
    change_roles: 'تغییر نقش',
    remove: 'برداشتن',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'شما تغییرات خود را ارسال نکرده اید. آیا خروج بدون ارسال اشکالی ندارد؟',
    select_language: 'زبان را انتخاب کنید',
    are_you_sure_you_want_delete_this_collaborator: 'آیا مطمئن هستید که می خواهید این همکار را حذف کنید؟',
    are_you_sure_you_want_to_delete_selected_files: 'آیا مطمئن هستید که می خواهید فایل انتخاب شده را برای همیشه حذف کنید؟',
    please_download_before_deleting: 'لطفا قبل از حذف دانلود کنید فایل هایی که پس از حذف شدن، قابل بازیابی نیستند!',
    role_has_been_changed_successfully: 'نقش با موفقیت تغییر کرده است',
    do_you_want_to_leave_collaboration: 'آیا می خواهید همکاری را ترک کنید؟',
    form_builder_access_denied: 'دسترسی فرم ساز رد شد',
    quiz_builder_access_denied: 'دسترسی به آزمون ساز ممنوع است',
    you_only_have_viewer_rights_for_quiz: 'شما فقط برای این مسابقه حقوق بیننده دارید',
    you_only_have_viewer_rights_for_form: 'شما فقط برای این فرم حقوق بیننده دارید',
    kindly_contact_the_form_owner_for_more_info: 'لطفاً برای اطلاعات بیشتر با صاحب فرم تماس بگیرید.',
    kindly_contact_the_quiz_owner_for_more_info: 'لطفا برای اطلاعات بیشتر با صاحب آزمون تماس بگیرید.',
    incomplete_questions: 'سوالات ناقص:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'شما تغییرات ذخیره نشده ای دارید - آیا مطمئن هستید که می خواهید این صفحه را ترک کنید؟',
    languages: 'زبان',
    copy: "کپی",
    not_good: 'خوب نیست',
    not_allowed: 'مجاز نیست',
    not_allowed_description: 'نوع سوال انتخابی پس از جمع آوری پاسخ ها قابل تغییر نیست.',
    neutral: 'خنثی',
    good: 'خوب',
    very_good: 'خیلی خوب',
    edit_rights_removed: 'حقوق ویرایش حذف شد',
    your_rights_to_edit_this_form_have_been_removed: "حقوق شما برای ویرایش این فرم حذف شده است.",
    your_rights_to_edit_this_quiz_have_been_removed: "حقوق شما برای ویرایش این آزمون حذف شده است.",
    please_contact_quiz_owner_for_more_info: "لطفاً برای اطلاعات بیشتر با صاحب فرم تماس بگیرید.",
    please_contact_form_owner_for_more_info: "لطفا برای اطلاعات بیشتر با صاحب آزمون تماس بگیرید.",
    last_edited_on: 'آخرین ویرایش در',
    last_edited: 'آخرین ویرایش',
    by: 'توسط',
    you: "شما",
    add_option: 'افزودن گزینه',
    add_other_option: "گزینه \"سایر\" را اضافه کنید",
    estimated_time: " زمان تخمین زده شده",
    do_you_want_to_show_estimated_time_taking_quiz: "آیا می خواهید زمان تخمینی برای شرکت در مسابقه را نشان دهید؟",
    do_you_want_to_show_estimated_time_taking_form: "آیا می خواهید زمان تخمینی برای گرفتن فرم را نشان دهید؟",
    show_question_count: "نمایش تعداد سوالات",
    do_you_want_to_show_total_number_questions_quiz: "آیا می خواهید تعداد کل سوالات را در مسابقه نشان دهید؟",
    do_you_want_to_show_total_number_questions_form: "آیا می خواهید تعداد کل سوالات را در فرم نشان دهید؟",
    show_question_marks: "نشان دادن علامت سوال",
    show_question_marks_quiz: "نشان دادن علامت سوال در مسابقه",
    you_dont_have_add_collaborator_permission: "شما اجازه اضافه کردن شریک را ندارید",
    modified_date: "تاریخ اصلاح",
    created_date: "تاریخ ایجاد",
    question_cant_be_added: "سوال را نمی توان اضافه کرد",
    another_file_upload_question_cant_added: "یک سوال آپلود فایل دیگر نمی تواند اضافه شود",
    you_can_have_only: "شما فقط می توانید داشته باشید",
    file_upload_questions_in_form: "سوالات آپلود فایل در یک فرم",
    file_upload_questions_in_quiz: "سوالات آپلود فایل در یک آزمون",
    storage_capacity_exceeded: "ظرفیت ذخیره سازی بیش از حد است",
    kindly_inform_form_builder_that: "لطفاً به فرم ساز اطلاع دهید",
    kindly_inform_quiz_builder_that: "لطفا به سازنده مسابقه اطلاع دهید",
    their_storage_capacity_file_uploads: "ظرفیت ذخیره سازی آنها برای آپلود فایل",
    has_been_exceeded: "فراتر رفته است",
    view_details: "دیدن جزئیات",
    paste_your_video_link_here: "لینک ویدیوی خود را اینجا بچسبانید",
    paste_your_website_link_here: "لینک وب سایت خود را اینجا قرار دهیدe",
    view_answer_sheet: "مشاهده پاسخنامه",
    response_count: "تعداد پاسخ",
    count: "شمردن",
    password_least_8_characters: "رمز عبور باید حداقل 8 - 50 کاراکتر باشد",
    password_contain_least_1_number: "رمز عبور باید حداقل دارای 1 عدد باشد",
    password_contain_least_1_capital_letter: "رمز عبور باید حداقل دارای 1 حرف بزرگ باشد",
    password_contain_least_1_special_character: "رمز عبور باید حداقل دارای 1 کاراکتر خاص باشد",
    please_fill_all_required_fields: "لطفا تمام فیلدهای الزامی را پر کنید",
    passwords_dont_match: "گذرواژه ها مطابقت ندارند",
    incorrect_password: "رمز عبور نادرست",
    click_or_change_current_theme: "برای انتخاب تم دیگری روی طرح زمینه فعلی یا «تغییر» در زیر کلیک کنید",
    select: "انتخاب کنید",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "آیا می خواهید این تصویر را حذف کنید؟ اگر ادامه دهید، این تصویر برای هیچ فرم یا آزمونی که از آن استفاده می کند در دسترس نخواهد بود. این عمل قابل لغو نیست.",
    url_does_not_seem_to_valid: "به نظر می رسد URL معتبر نیست.",
    you_have_reached_image_uploaded_limit: "شما به حد مجاز آپلود تصویر رسیده اید.",
    feature_spotlight: "ویژگی نورافکن",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "توجه: برای مشاهده مجدد این اطلاعات، روی «بیشتر» در منوی سمت چپ کلیک کنید",
    undo: "لغو کردن",
    response_deleted: "پاسخ حذف شد",
    question_deleted: "سوال حذف شد",
    answer_deleted: "پاسخ حذف شد",
    responses_deleted: "پاسخ ها حذف شد",
    questions_deleted: "سوالات حذف شد",
    answers_deleted: "پاسخ ها حذف شد",
    collect_email_header: "جمع آوری ایمیل",
    collect_email_alert_message: "فعال کردن این ویژگی پاسخگو را مجبور می کند تا با آدرس جیمیل خود وارد فرم شود.",
    collect_email_alert_note: "نکته: فقط حساب Google پشتیبانی می شود",
    allow_response_edit: "اجازه ویرایش پاسخ",
    allow_response_edit_description: "آیا می خواهید به پاسخ دهندگان اجازه دهید پس از ارسال پاسخ ها را ویرایش کنند؟",
    enter_desc_here: "توضیحات را در اینجا وارد کنید",
    passcode_protection: "حفاظت از رمز عبور",
    passcode_protection_description_quiz: "آیا می خواهید مسابقه شما با رمز عبور محافظت شود؟",
    passcode_protection_description: "آیا می خواهید فرم شما با رمز عبور محافظت شود؟",
    crop_image: "برش تصویر",
    crop: "برش",
    original: "اصل",
    collect_email: "آدرس ایمیل را جمع آوری کنید",
    collect_email_description: "آیا می‌خواهید آدرس‌های ایمیل پاسخ‌دهندگان را برای ارجاع یا استفاده احتمالی در آینده جمع‌آوری کنید؟",
    collect_email_description_quiz: "فعال کردن این ویژگی پاسخ دهندگان را مجبور می کند تا با آدرس جیمیل خود وارد شوند تا امتحان را تکمیل کنند.",
    collect_email_description_form: "فعال کردن این ویژگی، پاسخ دهندگان را مجبور می کند تا با آدرس gmail خود وارد فرم شوند.",
    opening_date: 'تاریخ افتتاحیه',
    closing_date: 'تاریخ بسته شدن',
    schedule_form_status: 'وضعیت فرم را برنامه ریزی کنید',
    schedule_quiz_status: 'زمانبندی وضعیت آزمون',
    schedule_opening_date_and_time: "زمان و تاریخ افتتاح را برنامه ریزی کنید",
    schedule_closing_date_and_time: "زمان و تاریخ بسته شدن را برنامه ریزی کنید",
    schedule_opening_date_and_time_description: "فرم را در تاریخ و ساعت ذکر شده فعال کنید",
    schedule_opening_date_and_time_description_quiz: "آزمون را در تاریخ و ساعت ذکر شده فعال کنید",
    schedule_closing_date_and_time_description: "فرم را در تاریخ و ساعت ذکر شده غیرفعال کنید",
    schedule_closing_date_and_time_description_quiz: "آزمون را در تاریخ و ساعت ذکر شده غیرفعال کنید",
    upgrade: "ارتقا",
    gold_plan: "طرح طلا",
    view_plans: "مشاهده طرح ها",
    premium_plans: "طرح های برتر",
    plan_duration: "/ ماه",
    plan_logic_jump: "پرش منطقی",
    plan_remove_ads: "حذف تبلیغات",
    cancel_subscription: "لغو عضویت",
    quiz_has_answer_edit_message: "این مسابقه قبلا ارسال شده و پاسخ ها جمع آوری شده است. ویرایش مسابقه ممکن است پاسخ های قبلی را تحت تاثیر قرار دهد.",
    quiz_published_alert_edit_message: "این مسابقه منتشر شده است، اگر ویرایش کنید این مسابقه منتشر نخواهد شد.",
    quiz_submit_after_edit_message: "ویرایش امتحان ممکن است بر نمرات، درصد تاثیر بگذارد و نیاز به ارزیابی مجدد سوالات خاص داشته باشد.",
    title_activity_privacy_policy: "Privacy Policy Activity",
    action_blocked_message: "به دلیل پاسخ‌های موجود برای این مسابقه، اقدام مسدود شد. اجازه دادن به این امر ممکن است بر پاسخ‌های قبلی تأثیر بگذارد.",
    email_addresses: "آدرس ایمیل",
    confirmation_message: "پیام تایید",
    confirmation_message_description: "آیا می خواهید پس از ارسال فرم پیام تایید را نشان دهید؟",
    confirmation_message_description_quiz: "آیا می خواهید پس از پاسخ دادن به مسابقه پیام تایید را نشان دهید؟",
    default_custom_thank_message: "با تشکر از تکمیل این فرم.",
    default_custom_thank_message_quiz: "با تشکر از تکمیل این مسابقه.",
    upgrade_your_plan: "برنامه خود را ارتقا دهید!",
    upgrade_your_plan_1: "طرح خود را ارتقا دهید",
    upgrade_your_plan_content: "لطفاً نسخه پریمیوم را برای دسترسی به این ویژگی و افزایش حجم فضای ذخیره سازی و غیره ارتقا دهید.",
    alert_icon: "آیکون هشدار",
    usage_details: "جزئیات استفاده",
    current_plan: "نقشه جاری",
    total_submission: "کل ارسالی",
    image_attachments: "پیوست های تصویر",
    custom_themes: "موضوعات سفارشی",
    free_limit_reached: "شما به محدودیت رایگان رسیده اید -",
    payment_done_but_server_error: "پرداخت شما با موفقیت کسر شد. لطفاً پس از مدتی برنامه را باز کنید تا به ما اجازه دهید پرداخت شما را تأیید کنیم.",
    response_quota_reached: "شما به محدودیت رایگان رسیده اید، لطفاً طرح خود را برای دسترسی به همه پاسخ ها ارتقا دهید.",
    answers_quota_reached: "شما به محدودیت رایگان رسیده اید، لطفا طرح خود را برای دسترسی به همه پاسخ ها ارتقا دهید.",
    logic_jump_enabled: "پرش منطقی فعال شد.",
    logic_jump_disabled: "پرش منطقی غیرفعال شد.",
    cancel_subscription_message: "آیا مطمئن هستید که می خواهید اشتراک خود را لغو کنید؟ لغو اشتراک منجر به از دست دادن دسترسی به همه ویژگی های برتر می شود.",
    plan_expired: "طرح شما منقضی شده است. لطفاً طرح خود را برای استفاده از این پیش نویس ارتقا دهید.",
    plan_expired_edit_form: "طرح شما منقضی شده است. لطفا طرح خود را برای ویرایش این فرم ارتقا دهید",
    plan_expired_edit_quiz: "طرح شما منقضی شده است. لطفا طرح خود را برای ویرایش این مسابقه ارتقا دهید",
    plan_expired_duplicate_form: "طرح شما منقضی شده است. لطفا طرح خود را برای تکرار این فرم ارتقا دهید",
    plan_expired_duplicate_quiz: "طرح شما منقضی شده است. لطفا طرح خود را ارتقا دهید تا این مسابقه تکراری شود",
    premium_features: "ویژگی برتر",
    plan_file_upload: "تصاویر با تم سفارشی نامحدود",
    plan_custom_theme: "تصاویر با تم سفارشی نامحدود",
    plan_call_support: "اولویت بالا در تماس - پشتیبانی تماس",
    plan_email_support: "پشتیبانی ایمیل با اولویت بالا",
    plan_custom_domain: "دامنه سفارشی",
    subscribe: "اشتراک در",
    plan_remove_branding: "حذف نام تجاری",
    plan_on_call_support: "پشتیبانی در تماس",
    plan_image_attachment: "ضمیمه تصاویر نامحدود",
    plan_custom_thank_you: "صفحات تشکر قابل تنظیم",
    plan_allowed_responses: "تعداد پاسخ های مجاز 50000",
    trusted_by_number_of_user: "مورد اعتماد بیش از 5 میلیون کاربر",
    email_support: "پشتیبانی ایمیل",
    call_support: "تماس با پشتیبانی",
    keyboard_shortcuts: "میانبرهای صفحه کلید",
    premium_limit_reached: "شما به حد مجاز طرح رسیده اید. لطفاً طرح خود را برای دسترسی به همه ویژگی‌های برتر ارتقا دهید.",
    premium_limit_reached_mobile: "شما به حد مجاز طرح رسیده اید.",
    unlimited: "نامحدود",
    expired_on_next_renewal: "در تاریخ تمدید بعدی شما منقضی می شود",
    upgrade_plan: "طرح ارتقا",
    downgrade_plan: "طرح تنزل رتبه",
    file_upload_questions: "سوالات آپلود فایل",
    plan_response_edit: "ویرایش پاسخ",
    cant_process: "نمی توان پردازش کرد",
    you_have_subscribed_through_android: "شما از طریق برنامه اندروید ما مشترک شده اید. لطفاً از برنامه SurveyHeart برای به‌روزرسانی‌های مربوط به پرداخت استفاده کنید.",
    pay_now: "الان پرداخت کن",
    update_payment_details: "به روز رسانی جزئیات پرداخت",
    limit_reached_alert_message: "شما به حد مجاز رسیده‌اید، لطفاً طرح خود را ارتقا دهید تا به همه پاسخ‌ها دسترسی داشته باشید.",
    limit_reached_alert_message_quiz: "شما به حد مجاز رسیده اید، لطفا طرح خود را ارتقا دهید تا به همه پاسخ ها دسترسی داشته باشید.",
    limit_reached_UI_message: "پاسخ‌ها برای طرح فعلی شما در دسترس نخواهد بود، لطفاً طرح خود را ارتقا دهید تا همه پاسخ‌ها را ببینید.",
    limit_reached_UI_message_quiz: "پاسخ‌ها برای طرح فعلی شما در دسترس نخواهد بود، لطفاً طرح خود را ارتقا دهید تا همه پاسخ‌ها را ببینید.",
    owner_is_not_premium_user: "مالک یک کاربر ممتاز نیست",
    cancel_subscription_description: "آیا مطمئن هستید که می خواهید اشتراک خود را لغو کنید؟ لغو باعث از دست رفتن دسترسی به همه ویژگی‌های برتر می‌شود.",
    starter_storage_description: "5 گیگابایت فضای ذخیره سازی برای مدیریت بی دردسر داده دریافت کنید.",
    bronze_storage_description: "25 گیگابایت فضای ذخیره سازی برای مدیریت بی دردسر داده دریافت کنید.",
    silver_storage_description: "برای مدیریت بی دردسر داده ها 50 گیگابایت فضای ذخیره سازی دریافت کنید.",
    gold_storage_description: "برای مدیریت بی دردسر داده ها، 150 گیگابایت فضای ذخیره سازی دریافت کنید.",
    starter_responses_description: "از 10000 پاسخ برای به دست آوردن بینش نظرسنجی قدرتمند استفاده کنید.",
    bronze_responses_description: "از 35000 پاسخ برای به دست آوردن بینش نظرسنجی قدرتمند استفاده کنید.",
    silver_responses_description: "از 75000 پاسخ برای به دست آوردن بینش نظرسنجی قدرتمند استفاده کنید.",
    gold_responses_description: "از 150000 پاسخ برای به دست آوردن بینش نظرسنجی قدرتمند استفاده کنید.",
    starter_image_description: "نظرسنجی های خود را با حداکثر 100 تصویر برای جذابیت بصری تقویت کنید.",
    bronze_image_description: "نظرسنجی های خود را با حداکثر 200 تصویر برای جذابیت بصری تقویت کنید.",
    silver_image_description: "نظرسنجی های خود را با حداکثر 500 تصویر برای جذابیت بصری تقویت کنید.",
    gold_image_description: "نظرسنجی های خود را با تصاویر نامحدود برای جذابیت بصری تقویت کنید.",
    starter_theme_description: "100 تصویر سفارشی اضافه کنید تا نظرسنجی های خود را برای برند خود شخصی سازی کنید.",
    bronze_theme_description: "200 تصویر سفارشی اضافه کنید تا نظرسنجی های خود را برای برند خود شخصی سازی کنید.",
    silver_theme_description: "500 تصویر سفارشی اضافه کنید تا نظرسنجی های خود را برای برند خود شخصی سازی کنید.",
    gold_theme_description: "تصاویر سفارشی نامحدود اضافه کنید تا نظرسنجی های خود را برای برند خود شخصی سازی کنید.",
    custom_theme_image: "تصویر تم سفارشی",
    starter_upload_description: "یکپارچه 4 سوال آپلود فایل.",
    file_upload_description: "یکپارچه سوالات آپلود فایل نامحدود.",
    logic_jump_description: "از جهش های منطقی برای سفارشی کردن جریان نظرسنجی برای تجربه هر پاسخ دهنده استفاده کنید",
    thank_you_message_description: "صفحات تشکر را شخصی کنید تا پاسخ دهندگان را تحت تاثیر قرار دهید.",
    answer_edit_description: "با استفاده از ویژگی ویرایش پاسخ به راحتی پاسخ ها را اصلاح کنید.",
    response_edit_description: "با استفاده از ویژگی ویرایش پاسخ به راحتی پاسخ ها را اصلاح کنید.",
    response_addon_description: "برای جمع‌آوری اطلاعات بیشتر، پاسخ‌های بیشتری دریافت کنید.",
    remove_branding_description: "با حذف نام تجاری SurveyHeart حضور برند خود را افزایش دهید.",
    bronze_remove_ads_description: "با طرح برنزی ما بدون تبلیغات بروید و روی نظرسنجی های خود تمرکز کنید.",
    silver_remove_ads_description: "با طرح نقره ای ما بدون آگهی بروید و روی نظرسنجی های خود تمرکز کنید.",
    gold_remove_ads_description: "با طرح طلایی ما بدون آگهی بروید و روی نظرسنجی های خود تمرکز کنید.",
    bronze_email_support_description: "دسترسی به پشتیبانی ایمیل با اولویت پایین، اطمینان از کمک به موقع.",
    silver_email_support_description: "دسترسی به پشتیبانی ایمیل با اولویت متوسط، اطمینان از کمک به موقع.",
    gold_email_support_description: "دسترسی به پشتیبانی ایمیل با اولویت بالا، اطمینان از کمک به موقع.",
    bronze_email_support_title: "پشتیبانی ایمیل با اولویت پایین",
    silver_email_support_title: "پشتیبانی ایمیل با اولویت متوسط",
    gold_email_support_title: "پشتیبانی ایمیل با اولویت بالا",
    update_your_payment_details_mobile: "لطفا جزئیات پرداخت خود را به روز کنید.",
    update_your_payment_details_pc: "ما برای تمدید این حساب با مشکلاتی مواجه هستیم. لطفا جزئیات پرداخت خود را به روز کنید.",
    card_details_updated_successfully: "جزئیات کارت با موفقیت به روز شد",
    card_details_updated_successfully_description: "جزئیات کارت شما با موفقیت به روز شد. اکنون می توانید به استفاده از اطلاعات پرداخت به روز خود ادامه دهید.",
    payment_successful: "پرداخت با موفقیت انجام شد",
    payment_successful_description: "پرداخت شما با موفقیت انجام شد. شما اکنون مشترک طرح برتر SurveyHeart هستید. راحت باشید و از همه ویژگی های شگفت انگیزی که در راه است لذت ببرید.",
    payment_failed: "پرداخت انجام نشد!",
    payment_failed_description: "متأسفانه. ما نتوانستیم اشتراک شما را در طرح پریمیوم SurveyHeart پردازش کنیم. لطفاً دوباره امتحان کنید.",
    note_for_five_rupees: "توجه: برای به روز رسانی جزئیات کارت شما 5 روپیه هزینه دریافت می شود که ظرف 3 تا 4 روز کاری به همان حساب بازپرداخت می شود.",
    logic: "منطق",
    re_generate_code: "تولید مجدد کد",
    plan_change_restrictions: "Plan Change Restrictions",
    plan_change_restriction_desc_1: "شما نمی توانید طرح خود را با استفاده از UPI تغییر دهید.",
    plan_change_restriction_desc_2: "برای ارتقا و تنزل به کارت تغییر دهید، یا طرح مورد نظر خود را لغو کرده و مجددا اشتراک کنید.",
    update_card_details: "به روز رسانی جزئیات کارت",
    features_and_plans: "ویژگی ها و طرح ها",
    cancel_plan: "لغو طرح",
    cancel_plan_desc: "شما طرح را لغو کرده اید. همچنان تا تاریخ تمدید خود به ویژگی های پولی دسترسی خواهید داشت.",
    explore_text: "کاوش و باز کردن ویژگی های استثنایی در یکی از طرح های اشتراک ممتاز امروز ما",
    cant_edit_this_form: "نمی توان این فرم را ویرایش کرد",
    cant_edit_this_quiz: "نمی توان این آزمون را ویرایش کرد",
    cant_duplicate_this_form: "نمی توان این فرم را کپی کرد",
    cant_duplicate_this_quiz: "نمی توان این آزمون را تکرار کرد",
    kindly_upgrade_the_premium: "لطفاً نسخه پریمیوم را برای دسترسی به این ویژگی ارتقا دهید.",
    check_box: "چک باکس",
    continue_to_next_question: "ادامه به سوال بعدی",
    add_logic: "منطق را اضافه کنید",
    submit_form: "ارسال فرم",
    save_logic: "ذخیره منطق",
    remove_logic: "حذف منطق",
    responses_add_ons: "افزودنی پاسخ ها",
    additional_submissions: " ارسالی های اضافی",
    responses_add_available: "پاسخ افزودنی موجود است",
    validity: " اعتبار",
    up_to_your_current_plan: " تا برنامه فعلی شما",
    responses_add_ons_description: " این تعداد پاسخ به طرح موجود شما اضافه خواهد شد.",
    redirect_url_title: " به لینک مورد نظر تغییر مسیر دهید",
    redirect_url_description: " آیا می خواهید کاربران خود را به یک صفحه وب خارجی هدایت کنید؟",
    enter_weblink: " لینک وب را وارد کنید",
    get: "دریافت کنید",
    jump_to: "پرش به",
    action_cannot_be_performed: "عمل نمی تواند انجام شود",
    upgrade_plan_desc1: "آیا می خواهید طرح خود را ارتقا دهید",
    downgrade_plan_desc1: "آیا می خواهید طرح خود را کاهش دهید",
    downgrade_plan_desc2: "این تغییر در تاریخ تمدید بعدی شما اعمال خواهد شد.",
    upgraded_plan: "طرح ارتقا یافته",
    downgraded_plan: "طرح تنزل یافته",
    upgraded_plan_desc: "شما طرح خود را ارتقا داده اید. این تغییر در تاریخ تمدید بعدی شما اعمال خواهد شد.",
    downgraded_plan_desc: "شما طرح خود را تنزل داده اید. این تغییر در تاریخ تمدید بعدی شما اعمال خواهد شد.",
    buy_responses: "خرید پاسخ",
    passcode: "رمز عبور",
    share_link_with_passcode: "اشتراک گذاری لینک با رمز عبور",
    contact_us: "با ما تماس بگیرید",
    premium_limit_reached_for_gold: "شما به حد مجاز طرح رسیده اید. لطفاً برای دسترسی به تمام ویژگی های برتر با ما تماس بگیرید.",
    follow_us: "ما را دنبال کنید",
    billing_history: "تاریخچه صورتحساب",
    your_current_plan: "طرح فعلی شما",
    next_billing_date: "تاریخ صورتحساب بعدی",
    transaction_id: "شناسه تراکنش",
    plan: "برنامه ریزی کنید",
    price: "قیمت",
    status: "وضعیت",
    follow_us_desc: "به روز باشید، در ارتباط باشید و با ما تعامل داشته باشید",
    ranking_choice: 'انتخاب رتبه بندی',
    ranking: 'رتبه بندی',
    picture_choice: 'انتخاب تصویر',
    agreement: 'توافق نامه',
    signature: "امضا",
    slider: 'لغزنده',
    min: 'حداقل',
    max: 'حداکثر',
    step_value: "مقدار مرحله",
    scale_range_error: "محدوده مقیاس باید مقداری بین 10000- تا 10000 باشد.",
    scale_range_error2: "مقدار حداکثر مقیاس کمتر از مقدار قابل قبول است.",
    max_min_error: "مقدار حداقل مقیاس باید کمتر از مقیاس حداکثر باشد.",
    step_error: "اندازه Step باید مقداری بین باشد",
    add_picture: 'اضافه کردن تصویر',
    terms_and_conditions: "شرایط و ضوابط",
    terms_and_conditions_required: "شرایط و ضوابط مورد نیاز است",
    i_agree: "من موافقم",
    i_disagree: "من مخالفم",
    yearly: "سالانه",
    monthly: "ماهانه",
    save_upto_20: "با برنامه های سالانه تا 20٪ صرفه جویی کنید",
    paid: "پرداخت شده است",
    cancelled: "لغو شد",
}